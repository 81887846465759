/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing area landing-1    CSS
 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.pricing-area-l1-1 {
    margin-bottom: -45px;

    @include mobile-xs {
        padding-top: 45px;
        padding-bottom: 60px;
    }

    @include tablet {
        padding-top: 65px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 130px;
    }


    @include large-desktops {
        padding-top: 160px;
    }

    .price-card {
        transition: all 0.4s ease-in-out;
        padding: 75px 30px;
        margin-bottom: 45px;
        border: 1px solid #eaeaea;

        h4 {
            color: $haiti;
            font-size: 34px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -1.12px;
            line-height: normal;
        }

        p {
            color: #81838c;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: normal;
        }

        h2 {
            color: $haiti;
            font-size: 48px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -1.58px;
            line-height: normal;
            padding-top: 35px;
        }

        .btn--price {
            min-width: 190px;
            height: 55px;
            color: #182748;
            border-radius: 6px;
            letter-spacing: -0.53px;
            border: 1px solid #e5e5e5;
            margin-top: 25px;
        }

        @include mobile-xs {
            box-shadow: 3px 67px 99px rgba(111, 118, 138, 0.16);
            border-radius: 14px;

            .btn--price {
                background: $primary;
                color: $white;
            }
        }

        @include desktops {
            box-shadow: none;

            .btn--price {
                background: transparent;
                border: 1px solid #e5e5e5;
                color: #182748;
            }

            &:hover {
                background: $white;
                box-shadow: 3px 67px 99px rgba(111, 118, 138, 0.16);
                border-radius: 14px;

                .btn--price {
                    background: $primary;
                    color: $white;
                }
            }

        }


    }

    .toggle-btn {
        padding: 35px 0px 85px 0px;

        .btn-toggle {
            margin: 0px 15px;
        }

        h4 {
            color: #182748;
            font-size: 16px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: normal;
        }

        h3 {
            color: $white;
            font-size: 12px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: 0.3px;
            line-height: normal;
            text-align: center;
            text-transform: uppercase;
            border-radius: 15px;
            background-color: #2bd67b;
            padding: 3px 12px;
            margin-left: 15px;
        }
    }

}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing switch l-1   CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.pricing-area-l1-1 {
    [data-pricing-dynamic][data-value-active="yearly"] {
        .dynamic-value {
            &:before {
                display: inline-block;
                content: attr(data-yearly);
            }
        }

        [data-pricing-trigger] {
            background: $primary;

            span {
                left: calc(100% - 24px);
            }
        }



    }

    [data-pricing-dynamic][data-value-active="monthly"] {
        .dynamic-value {
            &:before {
                display: inline-block;
                content: attr(data-monthly);
            }
        }

    }

    .dynamic-value {
        &:before {
            display: inline-block;
            content: attr(data-active);
        }
    }

    .static-value {
        &:before {
            display: inline-block;
            content: attr(data-active);
        }
    }

    .btn-toggle {
        width: 48px;
        height: 24px;
        border-radius: 17px;
        background-color: #eeeeee !important;
        position: relative;

        span {
            width: 16px;
            height: 16px;
            background-color: $primary;
            position: absolute;
            left: 5px;
            top: 4px;
            transition: 0.4s;
            border-radius: 0px;
            pointer-events: none;
        }

        &-2 {
            width: 70px;
            height: 30px;
            border-radius: 15px;
            background-color: #eeeeee;
            position: relative;
            z-index: 0;

            .round {
                height: 30px;
                width: 30px;
                left: 0px;
                top: 0;
                background: $primary !important;
                border-radius: 50%;
                display: block;
                z-index: -1;

            }

            span {
                display: none;
            }

        }

    }

    [data-pricing-dynamic][data-value-active="yearly"] {
        .btn-toggle {
            background: $primary !important;
        }

        .btn-toggle-2 .round {
            background: #eeeeee !important;
        }
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing L-3     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.pricing-area-l3-1 {
    padding-top: 60px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .section__heading {
        margin-bottom: 60px;

        p {

            @include mobile-lg {
                padding: 0px 10px;
            }

            @include tablet {
                padding: 0px 70px;
            }

            @include desktops {
                padding: 0px 100px;
            }

            @include large-desktops {
                padding: 0px 70px;
            }
        }



        i {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            color: $white;
            font-size: 20px;
            border-radius: 20px;
            background-color: $primary;
            margin-bottom: 25px;
        }

    }
}

.pricing-l3-1-items {
    margin-bottom: 0px;

    .col-lg-8 {
        margin-bottom: 25px;
    }

    .col-lg-4 {
        margin-bottom: 25px;
    }
}

.card--pricing-l3-1 {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 0px;
    background-color: $rts-white;
    border: 1px solid $rts-white;
    padding: 32px 35px 38px 35px;
    transition: all 0.4s ease-in-out;

    &:hover {
        box-shadow: 0 52px 54px rgba(0, 0, 0, 0.1);
    }

    &__head {
        h6 {
            color: $aluminium;
            font-size: 12px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: 1.2px;
            line-height: normal;
            text-align: left;
            text-transform: uppercase;
        }
    }

    .price-l3-border {
        height: 1px;
        border-radius: 6px;
        background-color: $porcelain;
        margin: 50px 0px 45px 0px;
    }

    &__price {
        h2 {
            color: $haiti;
            font-size: 34px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -1.12px;
            line-height: normal;
            margin-top: 45px;
        }

        span {
            color: $rts-white;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.46px;
            line-height: normal;
        }

        .price-l3-btn {
            .btn {
                width: 182px;
                height: 45px;
                border-radius: 6px;
                background-color: $primary;
                color: $white;
                font-size: 16px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: -0.53px;
                padding: 0px 20px;
                line-height: normal;
                display: inline-flex;
                justify-content: space-between;
                margin-top: 38px;
            }
        }
    }

    &__service {

        ul {
            margin-bottom: -20px;
            display: inline-grid;

            li {
                color: $haiti;
                font-size: 18px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: -0.59px;
                line-height: normal;
                margin-bottom: 20px;


                i {
                    font-size: 10px;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    text-align: center;
                    margin-right: 15px;
                    border-radius: 50%;
                    color: $white;
                    background-color: #2bd67b;
                }
            }
        }
    }
}

.implemented {
    background-color: $rts-red;
    height: 26.875rem;

    span {
        color: $rts-white;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.46px;
        line-height: normal;
    }
}
.testing {
    background-color: $rts-darkgray;
    height: 26.875rem;

    span {
        color: $rts-white;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.46px;
        line-height: normal;
    }
}
.indevelopment {
    background-color: $rts-gray;
    height: 26.875rem;

    span {
        color: $rts-white;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.46px;
        line-height: normal;
    }
}


@media screen and (max-width: 950px) {
    .implemented {
        height: 30rem !important;
    }
    .testing {
        height: 30rem !important;
    }
    .indevelopment {
        height: 30rem !important;
    }
}
@media screen and (max-width: 850px) {
    .implemented {
        height: 30rem !important;
    }
    .testing {
        height: 30rem !important;
    }
    .indevelopment {
        height: 30rem !important;
    }
}
@media screen and (max-width: 770px) {
    .implemented {
        height: 30rem !important;
    }
    .testing {
        height: 30rem !important;
    }
    .indevelopment {
        height: 30rem !important;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing-area l8     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.priceing-area-l8-1 {
    padding-top: 20px;

    @include tablet {
        padding-top: 40px;
    }

    @include desktops {
        padding-top: 115px;
    }

    .section__heading {
        h2 {
            margin-bottom: 30px;
        }
    }

    [data-pricing-dynamic][data-value-active="yearly"] {
        .dynamic-value {
            &:before {
                display: inline-block;
                content: attr(data-yearly);
            }
        }

        [data-pricing-trigger] {
            background: $primary;

            span {
                left: calc(100% - 24px);
            }
        }

        .btn-toggle-2[data-pricing-trigger] {
            background: $primary;

            span {
                left: calc(100% - 52%);
            }

            &.active {
                &:before {
                    color: #000 !important;
                }

                &:after {
                    color: $white !important;
                }
            }
        }

    }

    [data-pricing-dynamic][data-value-active="monthly"] {
        .dynamic-value {
            &:before {
                display: inline-block;
                content: attr(data-monthly);
            }
        }
    }

    .btn-toggle-2[data-pricing-trigger] {
        &.active {
            &:before {
                color: $white !important;
            }

            &:after {
                color: #000 !important;
            }
        }
    }

    .dynamic-value {
        &:before {
            display: inline-block;
            content: attr(data-active);
        }
    }

    .static-value {
        &:before {
            display: inline-block;
            content: attr(data-active);
        }
    }

    .btn-toggle {
        width: 48px;
        height: 24px;
        border-radius: 17px;
        background-color: #f8f8f8 !important;
        position: relative;
        margin: 0 15px;

        span {
            width: 16px;
            height: 16px;
            background-color: $primary;
            position: absolute;
            left: 5px;
            top: 4px;
            transition: 0.4s;
            border-radius: 500px;
            pointer-events: none;
        }

        &-8 {
            width: 265px;
            height: 42px;
            border-radius: 30px;
            background-color: $white !important;
            position: relative;
            margin: 0 15px;
            z-index: 1;

            &:before {
                position: absolute;
                content: "PER MONTHLY" !important;
                width: 50%;
                text-transform: uppercase;
                color: $white;
                height: 34px;
                background: transparent;
                left: 6px;
                top: 4px;
                line-height: 34px;
                font-size: 12px;
                font-weight: 700 !important;
                letter-spacing: 0.5px;

            }

            &:after {
                position: absolute;
                content: "PER YEARLY" !important;
                width: 50%;
                font-size: 13px;
                font-weight: 700 !important;
                text-transform: uppercase;
                color: $haiti;
                height: 34px;
                background: transparent;
                right: 4px;
                top: 4px;
                line-height: 34px;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 0.5px;
            }

            .round {
                width: 50%;
                height: 34px;
                top: 3px;
                left: 4px;
                background: $primary;
                display: block;
                z-index: -1;
            }

            span {
                display: none;
            }

        }

        &[data-value-active="yearly"] {
            background-color: $primary !important;

            span {
                left: calc(100% - 25px) !important;
            }
        }

        &-square {
            height: 65px;
            display: inline-flex;
            position: relative;
            z-index: 1;
            padding: 5px;
            align-items: center;

            &.bg-whisper-3 {

                &:hover,
                &:focus {
                    background: $white !important;
                }
            }

            span {
                min-width: 160px;
                min-height: 50px;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: -0.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $black;

                &:first-child {
                    border-radius: 10px 0 0 10px;
                }

                &:nth-child(2) {
                    border-radius: 0px 10px 10px 0;
                }

                &.active {
                    color: $white;
                    background: $primary;
                }
            }

        }
    }


    .popular-pricing {
        &:before {
            position: absolute;
            content: "";
            left: 0px;
            top: 0px;
            width: 100%;
            height: 4px;
            background: #f46f7c;
            border-radius: 6px;
        }

        &.popular-pricing-2 {
            &:before {
                height: 3px;
                top: -3px;
                background: red;
            }
        }
    }

}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing area landing-8    CSS
 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/



.priceing_area_four .sec_title {
    margin-bottom: 50px
}



.price_info_two .price_head {
    display: table;
    width: 100%
}

.price_info_two .price_head .p_head {
    width: calc(100%/5);
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    padding: 31px 0
}

.price_info_two .price_head .p_head h5 {
    color: $waterloo;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;

    @include mobile-lg {
        font-size: 16px;
    }
}

.price_info_two .price_head .p_head p {
    color: $big-stone;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;

}

.price_info_two .price_head .p_head h2 {
    color: $haiti;
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -1.12px;
    line-height: normal;
    margin-top: 27px;

    @include mobile-lg {
        font-size: 34px;
    }
}

.ex_team_item .hover_content .n_hover_content ul,
.job_listing .listing_tab .list_item .joblisting_text ul,
.price_info_two .price_head .p_head h5,
.priceing_area_four .sec_title h2 {
    margin-bottom: 0
}

.price_info_two {

    @include desktops {
        padding-top: 63px;
    }
}

@include desktops {
    .price_info_two .price_head .p_head:nth-child(9) {
        background: $bunting-aprx;
        border-radius: 9px 9px 0px 0px;
    }

    .price_info_two .price_item:nth-child(6) {
        background: $bunting-aprx;

    }

    .price_info_two .price_item:nth-child(6) a {
        color: $white !important;
    }

    .price_info_two .price_item:nth-child(6) span {
        color: $white !important;
    }

    .price_info_two .price_item:nth-child(6) i {
        color: $white !important;
    }

    .p_head:nth-child(6) h2 {
        color: $white !important;
    }

    .p_head:nth-child(6) p {
        color: $white !important;
    }

}

.price_info_two .price_item {
    width: calc(100%/5);
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    cursor: pointer;
    padding: 15px 0
}

.price_info_two .price_item span {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #222d39
}

.pr_list:last-child .price_item:nth-child(4) {
    border-radius: 0px 0px 9px 9px;
}

.price_info_two .price_item:first-child {
    text-align: left;
}

.price_info_two .price_item span i {
    font-size: 15px;
    color: $big-stone;
}

.pr_list:last-child {
    border: none !important;
}

.p_head:nth-child(9) h5 {
    color: $white !important;
    border-radius: 30px;
    background-color: #2bd67b;
    padding: 0px 11px;

    @include mobile-lg {
        display: inline-block;
        padding: 3px 20px;
    }
}

.price_info_two .price_item .pr_title {
    position: relative;
    display: inline-block;
    color: $big-stone;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
}

.price_btn i {
    margin-left: 13px;
    background-color: $white;
    color: $primary;
    border: 2px solid $primary;
    border-radius: 50%;
    padding: 4px;
    font-size: 12px;
}

.price_item.price-get-started-btn-3 i {

    @include desktops {
        background-color: $bunting-aprx;
        color: $white;
        border: 2px solid$white;
    }

}

.pr_list.pricing-get-strated-btn {

    @include mobile {
        display: inline-flex !important;
    }

    @include tablet {
        display: inherit;

    }
}

.price_info_two .price_item .check {
    color: $big-stone;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;
}

.price_info_two .pr_list {
    display: table;
    width: 100%;
    border-bottom: 1px solid #eaeced;
}

.price_info_two a {
    text-decoration: none;
}

.price_info_two .price_btn {
    color: $primary;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.53px;
    line-height: normal;

    @include mobile-lg {
        font-size: 16px;
    }
}

@media (max-width: 768px) {

    .price_info_two .pr_list,
    .price_info_two .price_head .p_head.time {
        display: block;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .price_info_two .price_item:first-child {
        text-align: center;
        padding: 5px 0;
    }
}

@media (max-width: 1199px) {
    .price_info_two .price_item:first-child {
        padding-left: 10px;
    }
}

@media (max-width: 768px) {
    .price_info_two .price_item {
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;

    }
}

@media (max-width: 768px) {
    .price_info_two .price_item:before {
        content: attr(data-title);
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 500;
        color: $rts-white;
    }

}

@media (max-width: 768px) {
    .price_info_two .price_item:first-child h5 {
        width: initial;
        border: 0;
        float: none;
        padding: 15px 0px;
    }
}

@media (max-width: 768px) {
    .price_info_two .price_item span {
        border-left: 1px dashed #ededed;
        display: block;
        float: right;
        font-size: 1.1rem;
        height: 100%;
        line-height: 3.1rem;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 50%;
    }


    .pr_list.pricing-get-strated-btn {

        @include tablet {
            display: flex;
        }

        @include desktops {
            display: initial;
        }


    }


}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing area Inner 01
 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.pricing-01-area {
    padding-top: 98px;

    @include mobile {
        padding-top: 115px;
    }

    @include tablet {
        padding-top: 135px;
    }

    @include desktops {
        padding-top: 160px;
    }
}

.pricing-02-area {
    padding-top: 98px;
    padding-bottom: 45px;

    @include mobile {
        padding-top: 115px;
    }

    @include tablet {
        padding-top: 135px;
        padding-bottom: 60px;
    }

    @include desktops {
        padding-top: 160px;
        padding-bottom: 120px;
    }
}

.pricing-03-area {
    padding-top: 98px;

    @include mobile {
        padding-top: 115px;
    }

    @include tablet {
        padding-top: 135px;
    }

    @include desktops {
        padding-top: 160px;
    }
}

#pricing_mobile {
    display: none;
}

@media screen and (max-width: 770px) {
    #pricing_mobile {
      display: block;
    }
}



#pricing_desktop {
    display: none;
}

@media screen and (min-width: 770px) {
    #pricing_desktop {
        display: block;
    }
}