/*------------------------------------------------------------------
[Master Stylesheet]
-------------------------------------------------------------------
Theme Name:     Appex - Landing Page Template
Theme URL:      https://09-temp-apex.netlify.app/
Author:         grayic
Version:        1.0
Last change:    23/01/2021
Description:    A default stylesheet for Appex - Landing HTML5 Template.
Primary use:    Business, responsive, bootstrap 5, html5, clean, modern, creative, landing page, app  landing etc.
Support:        https://uxtheme.net/product-support/
-------------------------------------------------------------------*/
/*********** CSS TABLE OF CONTENTS *******************

|--- 01.Component -> { Header }
|--- 02.Component -> { Menu }
|--- 03.Component -> { Button }
|--- 04.Component -> { Form }
|--- 05.Component -> { Cards }
|--- 06.Component -> { Section Heading }
|--- 07.Component -> { Search Form }
|--- 08.Component -> { FAQ }


|--- THEME CUSTOM STYLES

|--- 01.sections -> { Hero Area }
|--- 02.sections -> { Service }
|--- 03.sections -> { Feature }
|--- 04.sections -> { Content }
|--- 05.sections -> { Brand }
|--- 06.sections -> { Pricing }
|--- 07.sections -> { Footer }
|--- 08.sections -> { Testimonial }
|--- 09.sections -> { How It Works }
|--- 10.sections -> { CTA }
|--- 11.sections -> { Blog }
|--- 12.sections -> { Contact }
|--- 13.sections -> { About Us }
|--- 14.sections -> { Career }
|--- 15.sections -> { Case Details }
|--- 16.sections -> { Shop page}
|--- 17.sections -> { Inner pages}
|--- 18.sections -> { Portfolio}

******************************************************/
/*=== Media Query ===*/
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body[data-theme='dark'], .dark-bg, .dark-mode-texts {
  --bg:                 #19191b;
  --bg-2:                 #19191b;
  --color-headings:    $white;
  --color-texts:        rgba(255,255,255, 0.7);
  --color-texts-2:        rgba(255,255,255, 1);
  --color-texts-3:        rgba(255,255,255, 0.7);
  --border-color:       rgba(255,255,255, 0.08);
  --border-color-2:       rgba(255,255,255, 0.7);
}

body[data-theme='light'], .light-bg, .light-mode-texts {
  --bg:                   $white;
  --bg-2:                  #f8f9fc;
  --color-headings:      #25373f;
  --color-texts:        rgba(37, 55, 63, 0.7);
  --color-texts-2:       #3b4656;
  --color-texts-3:      rgba(38, 39, 41, 0.7);
  --border-color:       rgba(128, 138, 142, 0.2);
  --border-color-2:       rgba(37, 55, 63, 0.1);
}

body {
  --bg:                   $white;
  --bg-2:                  #f8f9fc;
  --color-headings:      #25373f;
  --color-texts:        rgba(37, 55, 63, 0.7);
  --color-texts-2:       #3b4656;
  --color-texts-3:      rgba(38, 39, 41, 0.7);
  --border-color:       rgba(128, 138, 142, 0.2);
  --border-color-2:       rgba(37, 55, 63, 0.1);
}

p {
  color: var(--color-texts);
}

.default-logo, .default-shape {
  display: block;
}

.light-version-logo, .light-shape {
  display: none;
}

.dark-version-logo, .dark-shape {
  display: none;
}

.light-version-logo.default-logo {
  display: block;
}

.dark-version-logo.default-logo {
  display: block;
}

body[data-theme='dark'] .dark-version-logo, body[data-theme='dark'] .dark-shape, .dark-mode-texts .dark-version-logo, .dark-mode-texts .dark-shape {
  display: block;
}

body[data-theme='dark'] .light-version-logo, .dark-mode-texts .light-version-logo {
  display: none;
}

body[data-theme='dark'] .light-version-logo.default-logo, .dark-mode-texts .light-version-logo.default-logo {
  display: none;
}

body[data-theme='dark'] .light-shape, .dark-mode-texts .light-shape {
  display: none;
}

body[data-theme='dark'] .light-shape.default-shape, .dark-mode-texts .light-shape.default-shape {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .light-version-logo, body[data-theme='dark'] .light-mode-texts .light-shape, .dark-mode-texts .light-mode-texts .light-version-logo, .dark-mode-texts .light-mode-texts .light-shape {
  display: block;
}

body[data-theme='dark'] .light-mode-texts .dark-version-logo, .dark-mode-texts .light-mode-texts .dark-version-logo {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-version-logo.default-logo, .dark-mode-texts .light-mode-texts .dark-version-logo.default-logo {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-shape, .dark-mode-texts .light-mode-texts .dark-shape {
  display: none;
}

body[data-theme='dark'] .light-mode-texts .dark-shape.default-shape, .dark-mode-texts .light-mode-texts .dark-shape.default-shape {
  display: none;
}

body[data-theme='light'] .light-version-logo, body[data-theme='light'] .light-shape, .light-mode-texts .light-version-logo, .light-mode-texts .light-shape {
  display: block;
}

body[data-theme='light'] .dark-version-logo, .light-mode-texts .dark-version-logo {
  display: none;
}

body[data-theme='light'] .dark-version-logo.default-logo, .light-mode-texts .dark-version-logo.default-logo {
  display: none;
}

body[data-theme='light'] .dark-shape, .light-mode-texts .dark-shape {
  display: none;
}

body[data-theme='light'] .dark-shape.default-shape, .light-mode-texts .dark-shape.default-shape {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .dark-version-logo, body[data-theme='light'] .dark-mode-texts .dark-shape, .light-mode-texts .dark-mode-texts .dark-version-logo, .light-mode-texts .dark-mode-texts .dark-shape {
  display: block;
}

body[data-theme='light'] .dark-mode-texts .light-version-logo, .light-mode-texts .dark-mode-texts .light-version-logo {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .light-version-logo.default-logo, .light-mode-texts .dark-mode-texts .light-version-logo.default-logo {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .light-shape, .light-mode-texts .dark-mode-texts .light-shape {
  display: none;
}

body[data-theme='light'] .dark-mode-texts .light-shape.default-shape, .light-mode-texts .dark-mode-texts .light-shape.default-shape {
  display: none;
}

.bg-default {
  background: var(--bg);
}

.bg-default-1 {
  background: var(--bg);
}

.bg-default-2 {
  background: var(--bg-2);
}

.bg-default-3 {
  background: var(--bg-3);
}

.bg-default-4 {
  background: var(--bg-4);
}

.bg-default-5 {
  background: var(--bg-5);
}

.bg-default-6 {
  background: var(--bg-6);
}

.text-default-color {
  color: var(--color-texts);
}

.text-default-color-2 {
  color: var(--color-texts-2);
}

.heading-default-color {
  color: var(--color-headings);
}

.border-default-color {
  border-color: var(--border-color);
}

.border-default-color-2 {
  border-color: var(--border-color-2) !important;
}

/*Preloader css*/
div#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

div#loading img {
  z-index: 9999;
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff !important;
}

.preloader img {
  width: 200px;
}

/*Header Css StaRT*/
.site-navbar {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

@media (min-width: 576px) {
  .site-navbar {
    padding-top: 25px;
  }
}

@media (min-width: 992px) {
  .site-navbar {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
}

.site-navbar .menu-block-wrapper {
  flex-grow: 1;
  align-items: center;
}

@media (min-width: 992px) {
  .site-navbar .menu-block-wrapper {
    display: flex !important;
    flex-basis: auto;
  }
}

.site-header--absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.site-header--sticky:not(.mobile-sticky-enable) {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}

@media (min-width: 992px) {
  .site-header--sticky:not(.mobile-sticky-enable) {
    position: fixed !important;
    transition: .4s;
  }
  .site-header--sticky:not(.mobile-sticky-enable).scrolling {
    transform: translateY(-100%);
    transition: .4s;
  }
  .site-header--sticky:not(.mobile-sticky-enable).reveal-header {
    transform: translateY(0%);
    box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
    z-index: 1000;
  }
}

.site-header--sticky.mobile-sticky-enable {
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  transition: .4s;
}

.site-header--sticky.mobile-sticky-enable.scrolling {
  transform: translateY(-100%);
  transition: .4s;
}

.site-header--sticky.mobile-sticky-enable.reveal-header {
  transform: translateY(0%);
  box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
  z-index: 9999;
}

.site-header--menu-center .container {
  position: relative;
}

.site-header--menu-center .menu-block-wrapper {
  position: static;
}

@media (min-width: 992px) {
  .site-header--menu-center .menu-block {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .sub-menu.megamenu {
    left: 0%;
    transform: translateX(0%) translateY(10px);
  }
}

@media (min-width: 992px) {
  .site-header--menu-left .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    transform: translateX(0%) translateY(-10px);
    left: 0%;
    margin-left: 20px;
  }
}

.site-header--menu-left .menu-block .site-menu-main {
  justify-content: flex-start;
  padding-left: 15px;
}

@media (min-width: 1200px) {
  .site-header--menu-left .menu-block {
    width: 100%;
  }
}

.site-header--menu-right .menu-block-wrapper {
  position: static;
}

.site-header--menu-right .menu-block {
  margin-left: auto;
}

@media (min-width: 992px) {
  .site-header--menu-right > .container-fluid .sub-menu.megamenu {
    left: 100%;
    transform: translateX(-100%) translateY(10px);
  }
}

@media (min-width: 992px) {
  .site-header--menu-right > .container-fluid .nav-item.has-megamenu:hover > .sub-menu {
    transform: translateX(-100%) translateY(-10px);
    left: 100%;
    margin-left: -20px;
  }
}

@media (min-width: 768px) {
  .container-fluid .site-navbar {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 992px) {
  .container-fluid .site-navbar {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 480px) {
  .header-btns {
    margin-right: 15px;
  }
}

@media (min-width: 992px) {
  .header-btns {
    margin-right: 0;
  }
}

.brand-logo {
  margin-top: 7px;
}

@media (min-width: 992px) {
  .row-lg {
    display: flex;
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.site-menu-main {
  margin-bottom: 0;
  padding-left: 0;
}

@media (min-width: 992px) {
  .site-menu-main {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
  }
}

.site-menu-main li {
  list-style: none;
}

.site-menu-main ul {
  list-style: none;
  margin-left: 0;
}

.site-menu-main a {
  text-decoration: none;
}

.site-menu-main .nav-item {
  display: inherit;
}

.site-menu-main .nav-link-item {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.6px;
  position: relative;
}

@media (min-width: 992px) {
  .site-menu-main .nav-link-item {
    padding-top: 35px !important;
    padding-bottom: 33px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.site-menu-main .sub-menu {
  position: absolute;
  z-index: 500;
  background-color: #0d0d0d;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding: 20px 0px;
  transition: opacity 0.5s ease, top 0.5s ease, margin-top 0.5s ease, background-color 0.5s ease;
  margin-top: 25px;
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu {
    top: 100%;
    min-width: 227px;
    max-width: 227px;
  }
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu .sub-menu {
    top: 0 !important;
    left: 100%;
  }
}

.site-menu-main .sub-menu--item {
  color: #fefffe;
  font-size: 16px;
  font-weight: 700;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 992px) {
  .site-menu-main .sub-menu--item {
    padding-top: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu--item {
    color: #fefffe;
  }
}

.site-menu-main .sub-menu--item > a {
  color: inherit;
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu--item > a {
    transition: .4s;
  }
}

.site-menu-main .sub-menu--item:hover > a {
  color: #b2282d;
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu {
    background-color: #fff;
  }
}

.site-menu-main .sub-menu.megamenu .dropdown-image-block {
  max-height: 336px;
}

@media (min-width: 320px) and (max-width: 992px) {
  .site-menu-main .sub-menu.megamenu {
    border: 0;
  }
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu {
    padding: 30px;
    min-width: 925px;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    will-change: transform;
    top: 100%;
    box-shadow: 0 42px 54px rgba(0, 0, 0, 0.09);
    border: 1px solid #e7e9ed;
    border-radius: 8px;
    visibility: hidden;
    z-index: -99;
    opacity: 0;
    pointer-events: none;
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-center {
    transform: translateX(-50%) translateY(-10px);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-right {
    right: 0%;
    transform: translateX(0%) translateY(10px);
  }
  .site-menu-main .sub-menu.megamenu.megadropdown-left {
    left: 0%;
    transform: translateX(0%) translateY(10px);
  }
}

@media (min-width: 1200px) {
  .site-menu-main .sub-menu.megamenu {
    min-width: 1100px;
  }
}

@media (min-width: 1366px) {
  .site-menu-main .sub-menu.megamenu {
    min-width: 1300px;
  }
}

.site-menu-main .sub-menu.megamenu .single-dropdown-block .mega-drop-menu-item {
  padding-top: 14px;
  padding-bottom: 14px;
  display: block;
}

.site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-size: 16px;
  font-weight: 700;
  transition: .4s;
  color: #000;
}

@media (min-width: 320px) and (max-width: 992px) {
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
    color: var(--color-headings);
    border-top: 1px solid var(--border-color-3);
    padding-left: 20px;
    padding-bottom: 13px;
    padding-top: 13px;
  }
}

@media (min-width: 992px) {
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item {
    padding-left: 0px;
    padding-right: 10px;
  }
  .site-menu-main .sub-menu.megamenu .mega-drop-menu-item:hover {
    color: #416ff4 !important;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .site-menu-main .sub-menu.megamenu [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
  .site-menu-main .sub-menu.megamenu [class*="row-"] {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  li.nav-item-has-children:not(.has-megamenu) {
    position: relative;
  }
}

@media (min-width: 992px) {
  li.nav-item-has-children:hover > .sub-menu {
    top: 100%;
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    z-index: 99;
    pointer-events: visible;
    border-radius: 0px 0px 15px 15px;
    border-top: 3px solid #b2282d;
  }
}

.site-menu-main > li .sub-menu > ul > li > a {
  display: inline-block;
  padding: 10px 0;
  font-size: 15px;
  color: #555;
  transition: color 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
}

.site-menu-main > li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}

.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

/*responsive*/
@media (max-width: 991px) {
  .site-header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .site-header .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: #0d0d0d;
    width: 24px;
    position: relative;
  }
  .site-header .mobile-menu-trigger span:before,
  .site-header .mobile-menu-trigger span:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0d0d0d;
  }
  .dark-mode-texts .mobile-menu-trigger span {
    background-color: #fefffe;
  }
  .dark-mode-texts .mobile-menu-trigger span:before,
  .dark-mode-texts .mobile-menu-trigger span:after {
    background-color: #fefffe;
  }
  .site-header .mobile-menu-trigger span:before {
    top: -6px;
  }
  .site-header .mobile-menu-trigger span:after {
    top: 6px;
  }
  .site-header .item-right {
    align-items: center;
  }
  .site-header .menu-block {
    position: fixed;
    width: 320px;
    background-color: #0d0d0d;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(-100%);
    transition: all 0.5s ease;
    z-index: 1099;
  }
  .site-header .menu-block.active {
    transform: translate(0%);
  }
  .site-menu-main > li {
    line-height: 1;
    margin: 0;
    display: block;
  }
  .site-menu-main > li > a {
    line-height: 50px;
    height: 50px;
    padding: 0 50px 0 15px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .site-menu-main > li > a i {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 50px;
    transform: rotate(-90deg);
  }
  .site-header .menu-block .mobile-menu-head {
    display: flex;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    background-color: #0d0d0d;
    top: 0;
  }
  .site-header .menu-block .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #fefffe;
    font-size: 16px;
    display: none;
  }
  .site-header .menu-block .mobile-menu-head.active .go-back {
    display: block;
  }
  .site-header .menu-block .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #fefffe;
    visibility: hidden;
  }
  .site-header .menu-block .mobile-menu-head.active .current-menu-title {
    visibility: visible;
  }
  .site-header .menu-block .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #fefffe;
    font-size: 25px;
  }
  .site-header .menu-block .site-menu-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .site-menu-main > li .sub-menu.mega-menu,
  .site-menu-main > li .sub-menu {
    visibility: visible;
    opacity: 1;
    position: absolute;
    box-shadow: none;
    margin: 0;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .site-menu-main > li .sub-menu.active {
    display: block;
  }
  .site-menu-main > li .sub-menu > ul > li > a,
  .site-menu-main > li .sub-menu.mega-menu > .list-item > ul > li > a {
    display: block;
  }
  .site-menu-main > li .sub-menu.mega-menu > .list-item > ul {
    margin-bottom: 15px;
  }
  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }
  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}

/*Custom Css for menu*/
@media (min-width: 992px) {
  .has-megamenu ul {
    margin-left: auto;
  }
  .site-menu-main a {
    display: flex;
    align-items: center;
  }
  .site-menu-main i {
    margin-left: 15px;
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .site-header--menu-center .menu-block-wrapper {
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
  }
}

.nav-item-has-children a {
  display: flex;
  justify-content: space-between;
}

.reveal-header {
  background: #0D152E !important;
}

@media (min-width: 992px) {
  .reveal-header .site-navbar {
    padding-top: 8px;
  }
}

@media (min-width: 1200px) {
  .reveal-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.reveal-header .nav-link-item {
  color: #fff !important;
}

.reveal-header .btn {
  color: #fff !important;
}

.reveal-header .btn:hover {
  color: #fff !important;
}

.reveal-header .dark-version-logo {
  display: block !important;
}

.reveal-header .light-version-logo {
  display: none !important;
}

@media (min-width: 992px) {
  .reveal-header li.nav-item-has-children:hover > .sub-menu {
    top: 100%;
  }
  .reveal-header li.nav-item-has-children:hover > .megamenu {
    top: 112%;
  }
}

.menu-block.active .nav-link-item {
  color: #fefffe;
}

@media (min-width: 480px) {
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

.landing-2-menu .nav-link-item {
  color: #0D152E;
}

@media (min-width: 992px) {
  .landing-2-menu .site-navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .landing-2-menu.reveal-header {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .landing-3-menu.reveal-header {
    padding-top: 0px;
  }
}

@media (min-width: 1200px) {
  .landing-3-menu.reveal-header {
    padding-top: 15px;
  }
}

.landing-4-menu .nav-link-item {
  color: #0D152E;
}

@media (min-width: 992px) {
  .landing-4-menu .site-navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .landing-4-menu.reveal-header {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .landing-5-menu.reveal-header {
    padding-top: 0px;
  }
}

@media (min-width: 1200px) {
  .landing-5-menu.reveal-header {
    padding-top: 15px;
  }
}

.landing-6-menu .nav-link-item {
  color: #fff;
}

@media (min-width: 992px) {
  .landing-6-menu .site-navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .landing-6-menu.reveal-header {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.landing-8-menu .nav-link-item {
  color: #0D152E;
}

@media (min-width: 992px) {
  .landing-8-menu .site-navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .landing-8-menu.reveal-header {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .landing-9-menu .site-navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .landing-9-menu.reveal-header {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.landing-inner-menu .nav-link-item {
  color: #0D152E;
}

@media (min-width: 992px) {
  .landing-inner-menu.reveal-header {
    padding-top: 0px;
  }
}

@media (min-width: 1200px) {
  .landing-inner-menu.reveal-header {
    padding-top: 15px;
  }
}

.inner-menu-bg {
  background: #fff;
}

@media (min-width: 320px) {
  .inner-menu-bg .site-navbar {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .inner-menu-bg .site-navbar {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .inner-menu-bg .site-navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .inner-menu-bg .site-navbar {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .inner-menu-bg.reveal-header {
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .inner-menu-bg.reveal-header {
    padding-top: 15px;
  }
}

.inner-menu-bg.reveal-header .site-navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.sign-in-menu .site-navbar {
  padding-top: 0 !important;
}

.sign-in-menu .menu-block-wrapper {
  display: none !important;
}

.sign-in-menu .mobile-menu-trigger {
  display: none !important;
}

@media (min-width: 992px) {
  .sign-in-menu .container-fluid .site-navbar {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (min-width: 1600px) {
  .sign-in-menu .container-fluid .site-navbar {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}

@media (min-width: 992px) {
  .site-header--menu-right .sub-menu .sub-menu {
    top: 0 !important;
    left: inherit;
    right: 100%;
  }
}

ul.site-menu-main .nav-item i {
  transition: all 0.4s ease-in-out;
}

ul.site-menu-main .nav-item:hover i {
  transform: rotate(-180deg);
}

li.sub-menu--item.nav-item-has-children:hover i {
  transform: rotate(-180deg) !important;
}

li.sub-menu--item.nav-item-has-children i {
  transform: rotate(-90deg) !important;
  transition: all 0.4s ease-in-out;
}

.single-dropdown-block h3 {
  opacity: 0.5;
  color: #0D152E;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.1px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 25px;
  display: none;
}

@media (min-width: 992px) {
  .single-dropdown-block h3 {
    display: block;
  }
}

.btn {
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  height: 40px;
  min-width: 130px;
  line-height: 28px;
  letter-spacing: -0.53px;
  padding: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  color: #fff !important;
}

.btn:focus {
  box-shadow: 0;
}

.btn.focus {
  box-shadow: 0;
}

.btn.btn-medium {
  min-width: 160px;
  height: 48px;
  line-height: 36px;
}

.btn.btn--lg {
  min-width: 181px;
  height: 61px;
}

.btn.btn--lg2 {
  min-width: 181px;
  max-width: 181px;
  height: 61px;
}

.btn.btn-xl {
  min-width: 180px;
  height: 60px;
  border-radius: 5px;
}

.btn.btn--xxl {
  min-width: 194px;
  height: 60px;
  border-radius: 5px;
}

.btn.with-icon i {
  margin-left: -5px;
  margin-right: 3px;
  font-size: 16px;
  margin-top: 2px;
}

.btn--reset {
  background: transparent;
  border: 0;
}

.btn.access-btn {
  color: #0d0d0d;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
  background-color: #fefffe;
  border-radius: 0px;
}

.btn.access-btn:hover {
  color: #fefffe !important;
  border: 1px solid #fefffe !important;
  background-color: #0d0d0d !important;
}

.btn--link {
  color: #a6a6a6;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 88px;
  letter-spacing: 1.63px;
  line-height: normal;
  height: 22px;
  padding: 0;
  border-radius: 0;
  border-bottom: 2px solid #767676;
  padding: 0px 0px 15px 0px !important;
  margin-top: 40px;
}

.btn--link:hover {
  color: #a6a6a6 !important;
}

.landing-1-menu .log-in-btn {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  height: 46px;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
}

.landing-1-menu .sign-in-btn {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
  border-radius: 6px;
  border: 1px solid #fff;
}

.landing-1-menu .sign-in-btn:hover {
  color: #fff;
}

.landing-1-menu .access-btn {
  color: #0d0d0d;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
  background-color: #fefffe;
}

.landing-1-menu .access-btn:hover {
  color: #fefffe !important;
  border: 1px solid #fefffe !important;
  background-color: #0d0d0d !important;
}

.landing-2-menu .sign-in-btn {
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
  border-radius: 6px;
}

.landing-2-menu .sign-in-btn:hover {
  color: #fff;
}

.landing-3-menu .log-in-btn {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  border: 1px solid #fff;
  height: 46px;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-right: 30px;
}

.landing-3-menu .sign-in-btn {
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
  border-radius: 6px;
}

.landing-3-menu .sign-in-btn:hover {
  color: #fff;
}

.landing-4-menu .send-request-btn {
  min-width: 166px;
  height: 50px;
  border-radius: 33px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-left: 15px;
}

.landing-5-menu .sign-in-btn {
  border: 1px solid #ffffff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
  border-radius: 23px;
  min-width: 110px;
}

.landing-5-menu .sign-in-btn:hover {
  color: #fff;
}

.landing-6-menu .log-in-btn {
  min-width: 120px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  border: 1px solid #fff;
  height: 46px;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-right: 30px;
}

.landing-6-menu .sign-in-btn {
  min-width: 120px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
}

.landing-6-menu .sign-in-btn:hover {
  color: #fff;
}

.landing-inner-menu .log-in-btn {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  min-width: 111px;
  height: 46px;
  letter-spacing: -0.53px;
  line-height: normal;
  text-align: center;
}

.landing-inner-menu .log-in-btn:hover {
  color: #0D152E !important;
}

.landing-inner-menu.reveal-header .log-in-btn:hover {
  color: #fff !important;
}

.landing-inner-menu .sign-in-btn {
  color: #fff;
  background-color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  text-align: center;
  height: 46px;
  border-radius: 6px;
}

.landing-inner-menu .sign-in-btn:hover {
  color: #fff;
}

.check-input-control {
  margin-bottom: 20px;
}

.check-input-control input:checked ~ .checkbox {
  background: #416ff4 !important;
  border-color: #416ff4;
}

.check-input-control input:checked ~ .checkbox::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  color: #fff;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  font-weight: 700;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%);
}

.check-input-control .checkbox {
  position: relative;
  line-height: 1;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 50%;
  background: #9ea1ab;
  display: inline-block;
  cursor: pointer;
}

.check-input-control .checkbox-2 {
  border-radius: 5px;
  border: 1px solid #b5b5b5;
  background: #fff;
}

/*Checkout page radio button*/
.gr-radio-input input:checked ~ label .round-indicator {
  border: 1px solid #473bf0;
  background: #fff;
}

.gr-radio-input input:checked ~ label .round-indicator:after {
  opacity: 1;
}

.gr-radio-input input:checked ~ label .round-indicator {
  border: 1px solid #416ff4;
  background: #fff;
}

.gr-radio-input input:checked ~ label .round-indicator:after {
  opacity: 1;
}

.round-indicator {
  position: relative;
  display: inline-block;
  border: 1px solid #E5E5E5;
  background-color: #E5E5E5;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 500px;
}

.round-indicator:after {
  content: "";
  background-color: #473bf0;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
  border-radius: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}

/*Checkout page radio button*/
.form-control::placeholder {
  color: #687497;
  opacity: 1;
}

.form-control:focus {
  box-shadow: none !important;
}

/*Section heading style-1*/
.section__heading h2 {
  color: #0D152E;
  font-size: 36px !important;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: normal;
  margin-bottom: 20px;
}

@media (min-width: 480px) {
  .section__heading h2 {
    font-size: 38px !important;
  }
}

@media (min-width: 768px) {
  .section__heading h2 {
    font-size: 48px !important;
  }
}

.section__heading p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-bottom: 0;
}

/*Section heading style-2*/
.section__heading-2 h2 {
  color: #0D152E;
  font-size: 36px !important;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.39px;
  line-height: 48px;
  margin-bottom: 20px;
}

@media (min-width: 480px) {
  .section__heading-2 h2 {
    font-size: 38px !important;
    line-height: 50px;
  }
}

@media (min-width: 992px) {
  .section__heading-2 h2 {
    font-size: 42px !important;
    line-height: 55px;
  }
}

.section__heading-2 p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-bottom: 0;
}

/*Home 8 search form*/
.domain-area {
  padding-top: 50px;
  padding-bottom: 57px;
}

@media (min-width: 768px) {
  .domain-area {
    padding-top: 70px;
    padding-bottom: 77px;
  }
}

@media (min-width: 992px) {
  .domain-area {
    padding-top: 110px;
    padding-bottom: 120px;
  }
}

.domain-area__heading h2 {
  color: #0D152E;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 50px;
}

@media (min-width: 576px) {
  .domain-area__heading h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

@media (min-width: 1600px) {
  .domain-search-area {
    padding: 0px 80px;
  }
}

.domain-search-area__search-form {
  width: 100%;
  box-shadow: 0 31px 32px rgba(170, 170, 170, 0.16);
  border-radius: 15px;
  background-color: #fff;
  padding: 20px 15px 20px 15px;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .domain-search-area__search-form {
    padding: 10px 10px 10px 30px;
    border-radius: 45px;
    margin-top: 45px;
  }
}

.domain-search-area__input {
  position: relative;
}

@media (min-width: 576px) {
  .domain-search-area__input {
    margin-right: 30px;
  }
}

.domain-search-area__input .form-control {
  width: 100%;
  border: 0;
  color: #9EA1AB;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  padding-left: 0px;
  letter-spacing: -0.59px;
  line-height: normal;
}

@media (min-width: 576px) {
  .domain-search-area__input .form-control {
    width: 350px;
  }
}

@media (min-width: 768px) {
  .domain-search-area__input .form-control {
    width: 325px;
  }
}

@media (min-width: 992px) {
  .domain-search-area__input .form-control {
    padding-left: 10px;
    width: 480px;
  }
}

@media (min-width: 1200px) {
  .domain-search-area__input .form-control {
    width: 665px;
  }
}

@media (min-width: 1366px) {
  .domain-search-area__input .form-control {
    width: 685px;
  }
}

@media (min-width: 768px) {
  .domain-search-area__input:after {
    position: absolute;
    content: "";
    background: #EAECED;
    height: 90px;
    width: 1px;
    right: 0;
    top: -27px;
  }
}

.domain-search-area__dropdown {
  display: inline-block;
  width: 100%;
}

@media (min-width: 768px) {
  .domain-search-area__dropdown {
    width: initial;
  }
}

.domain-search-area__dropdown .nice-select {
  padding-left: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .domain-search-area__dropdown .nice-select {
    width: 100px;
  }
}

@media (min-width: 992px) {
  .domain-search-area__dropdown .nice-select {
    width: 150px;
  }
}

.domain-search-area__dropdown .nice-select .list {
  width: 100%;
}

.domain-search-area__dropdown span {
  color: #9EA1AB;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
}

.domain-search-area__dropdown ul li {
  color: #9EA1AB;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
}

.domain-search-area__search-btn {
  position: relative;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .domain-search-area__search-btn {
    margin-top: 00px;
  }
}

.domain-search-area__search-btn .btn {
  min-width: 100%;
  height: 50px;
  box-shadow: 21px 15px 23px rgba(65, 111, 244, 0.11);
  border-radius: 45px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

@media (min-width: 768px) {
  .domain-search-area__search-btn .btn {
    min-width: 198px;
    height: 70px;
  }
}

.domain-search-area__search-btn i {
  position: absolute;
  font-size: 16px;
  color: #fff;
  top: 50%;
  left: 38px;
  transform: translate(-50%, -50%);
}

.domain-price-area {
  margin-top: 30px;
}

@media (min-width: 576px) {
  .domain-price-area {
    margin-top: 40px;
  }
}

.domain-price-area__single {
  margin-bottom: -10px;
}

@media (min-width: 576px) {
  .domain-price-area__single {
    margin-bottom: 0px;
  }
}

.domain-price-area__single span {
  color: #81838C;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin: 10px 15px;
}

@media (min-width: 480px) {
  .domain-price-area__single span {
    font-size: 20px;
  }
}

@media (min-width: 576px) {
  .domain-price-area__single span {
    margin: 0px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    FAQ landing-9
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.faq-area-l9-1 {
  padding-top: 40px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .faq-area-l9-1 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .faq-area-l9-1 {
    padding-bottom: 115px;
  }
}

.faq-area-l9-1 .section__heading-2 h2 {
  color: #fff;
}

.faq-area-l9-1 .section__heading-2 p {
  color: #687497;
}

@media (min-width: 992px) {
  .faq-l9-1 {
    padding-top: 58px;
  }
}

.faq-l9-1 .accordion li {
  position: relative;
  list-style: none;
  border-bottom: 1px solid #1c2b57;
  padding: 30px 0px 32px 0px;
}

.faq-l9-1 .accordion li p {
  display: none;
  padding: 20px 15px 27px 0px;
  margin-bottom: 6px;
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
}

.faq-l9-1 .accordion a {
  width: 100%;
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  position: relative;
  padding-right: 20px;
}

.faq-l9-1 .accordion a:hover {
  color: #fff;
}

.faq-l9-1 .accordion li .active {
  color: #fff !important;
}

.faq-l9-1 .accordion .active:after {
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
}

.faq-l9-1 .accordion a:after {
  width: 8px;
  height: 8px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  right: 0px;
  content: " ";
  top: 8px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.faq-l9-1 .accordion a.active:after {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    FAQ main Page
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.faq-main-banner {
  padding: 98px 0px 40px 0px;
}

@media (min-width: 480px) {
  .faq-main-banner {
    padding: 115px 0px 40px 0px;
  }
}

@media (min-width: 768px) {
  .faq-main-banner {
    padding: 135px 0px 60px 0px;
  }
}

@media (min-width: 992px) {
  .faq-main-banner {
    padding: 163px 0px 80px 0px;
  }
}

.faq-main-banner h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
}

@media (min-width: 576px) {
  .faq-main-banner h2 {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .faq-main-banner h2 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .faq-main-banner h2 {
    font-size: 60px;
  }
}

.faq-main-area {
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .faq-main-area {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .faq-main-area {
    padding-bottom: 130px;
  }
}

.faq-main-tab-area ul li a {
  text-decoration: none;
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  display: inline-block;
  margin-bottom: 23px;
}

.faq-main-tab-area ul li.active a {
  color: #416ff4;
  font-weight: 700;
}

.faq-single-all-items {
  margin-bottom: -60px;
  padding-top: 10px;
}

@media (min-width: 768px) {
  .faq-single-all-items {
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  .faq-single-all-items {
    padding-top: 0;
  }
}

.faq-main-area-single {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .faq-main-area-single {
    margin-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .faq-main-area-single {
    margin-bottom: 60px;
  }
}

.faq-main-area-single h2 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-bottom: 30px;
}

.faq-main-area-single .accordion li {
  position: relative;
  list-style: none;
  border-radius: 5px;
  border: 1px solid #EAECED;
  padding: 15px 20px 15px 15px;
  margin-bottom: 10px;
}

.faq-main-area-single .accordion li.active {
  background: #f8fafc;
}

.faq-main-area-single .accordion li p {
  display: none;
  padding: 20px 15px 0px 0px;
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 0;
}

.faq-main-area-single .accordion a {
  width: 100%;
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  position: relative;
  padding-right: 20px;
}

.faq-main-area-single .accordion li .active {
  color: #0D152E !important;
}

.faq-main-area-single .accordion .active:after {
  border-right: 2px solid #416ff4 !important;
  border-bottom: 2px solid #416ff4 !important;
}

.faq-main-area-single .accordion a:after {
  width: 8px;
  height: 8px;
  border-right: 2px solid #416ff4;
  border-bottom: 2px solid #416ff4;
  position: absolute;
  right: 0px;
  content: " ";
  top: 8px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.faq-main-area-single .accordion a.active:after {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Hero Area 
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.bg-position {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.hero--area-curve__2 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media (min-width: 992px) {
  .hero--area-curve__2:after {
    position: absolute;
    content: "";
    background: #F7F9FB;
    width: 105%;
    height: 420px;
    left: -1%;
    bottom: -23%;
    z-index: -1;
    transform: rotate(-9deg);
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .hero--area-curve__2:after {
    transform: rotate(-10deg);
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Landing 1 Hero Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area {
  background-position: 100% 100% !important;
  padding: 100px 0px 0px 0px;
}

@media (min-width: 480px) {
  .hero-area {
    padding: 120px 0px 0px 0px;
  }
}

@media (min-width: 576px) {
  .hero-area {
    padding: 125px 0px 0px 0px;
  }
}

@media (min-width: 768px) {
  .hero-area {
    padding: 145px 0px 0px 0px;
  }
}

@media (min-width: 992px) {
  .hero-area {
    padding: 155px 0px 0px 0px;
  }
}

.hero-area .hero-area__title h2 {
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.81px;
}

@media (min-width: 320px) {
  .hero-area .hero-area__title h2 {
    font-size: 36px;
    line-height: 41px;
  }
}

@media (min-width: 576px) {
  .hero-area .hero-area__title h2 {
    font-size: 45px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  .hero-area .hero-area__title h2 {
    font-size: 55px;
    line-height: 60px;
  }
}

.hero-area .badge h6 {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
  padding: 5px;
  border-radius: 30px;
}

.hero-area .badge-offer {
  padding: 0px 15px !important;
  border-radius: 30px;
}

.hero-area .badge span {
  padding: 0px 12px 0px 15px;
}

@media (min-width: 320px) {
  .hero-area .hero-area__btn {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .hero-area .hero-area__btn {
    margin-top: 25px;
  }
}

.hero-area .hero-area__btn .btn {
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  text-align: center;
  border-radius: 0px;
  padding: 18px 28px;
}

.hero-area .hero-area__btn .access-btn {
  color: #0d0d0d;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
  background-color: #fefffe;
}

.hero-area .hero-area__btn .access-btn:hover {
  color: #fefffe !important;
  border: 1px solid #fefffe !important;
  background-color: #0d0d0d !important;
}

.hero-area .hero-area__btn .datasheet-btn {
  color: #fefffe;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
  border: 1px solid #fefffe;
}

.hero-area .hero-area__btn .datasheet-btn:hover {
  color: #0d0d0d !important;
  border: 1px solid #fefffe !important;
  background-color: #fefffe !important;
}

@media (min-width: 320px) {
  .hero-area .hero-area__image {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .hero-area .hero-area__image {
    margin-top: 110px;
  }
}

.hero-area .hero-area__image img {
  width: 100%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Landing 2 Hero Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
@media (min-width: 320px) {
  .hero-area-2 {
    padding-top: 110px;
    padding-bottom: 30px;
  }
}

@media (min-width: 480px) {
  .hero-area-2 {
    padding-top: 160px;
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .hero-area-2 {
    padding-top: 180px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .hero-area-2 {
    padding-top: 250px;
    padding-bottom: 175px;
  }
}

.hero-area-2 .hero-area-content {
  padding-top: 35px;
}

@media (min-width: 768px) {
  .hero-area-2 .hero-area-content {
    margin-right: 40px;
  }
}

@media (min-width: 1200px) {
  .hero-area-2 .hero-area-content {
    margin-right: 85px;
    padding-top: 0px;
  }
}

@media (min-width: 1366px) {
  .hero-area-2 .hero-area-content {
    margin-right: 35px;
  }
}

@media (min-width: 1600px) {
  .hero-area-2 .hero-area-content {
    margin-right: 160px;
  }
}

.hero-area-2 .hero-area-content h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2.11px;
  line-height: 47px;
}

@media (min-width: 576px) {
  .hero-area-2 .hero-area-content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

@media (min-width: 768px) {
  .hero-area-2 .hero-area-content h2 {
    font-size: 64px;
    line-height: 70px;
  }
}

@media (min-width: 992px) {
  .hero-area-2 .hero-area-content h2 {
    font-size: 62px;
  }
}

@media (min-width: 1200px) {
  .hero-area-2 .hero-area-content h2 {
    font-size: 64px;
  }
}

.hero-area-2 .hero-area-content p {
  color: #81838c;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 30px;
}

.hero-area-2 .hero-area-content .hero-area-btn-group {
  margin: 48px -8px -20px -8px;
}

.hero-area-2 .hero-area-content .hero-area-btn-group a {
  margin: 0px 8px 20px 8px;
  display: inline-block;
}

.hero-area-2 .hero-area-image-group {
  position: relative;
}

.hero-area-2 .hero-area-image-group .image-1 img {
  box-shadow: 31px 40px 78px rgba(111, 118, 138, 0.16);
  border-radius: 30px;
}

.hero-area-2 .hero-area-image-group .image-2 {
  position: absolute;
  top: -5%;
  left: 0%;
  z-index: -1;
}

@media (min-width: 480px) {
  .hero-area-2 .hero-area-image-group .image-2 {
    left: 10%;
  }
}

@media (min-width: 576px) {
  .hero-area-2 .hero-area-image-group .image-2 {
    left: 12%;
  }
}

@media (min-width: 768px) {
  .hero-area-2 .hero-area-image-group .image-2 {
    left: 15%;
  }
}

@media (min-width: 992px) {
  .hero-area-2 .hero-area-image-group .image-2 {
    left: 11%;
  }
}

@media (min-width: 1200px) {
  .hero-area-2 .hero-area-image-group .image-2 {
    left: 17%;
  }
}

@media (min-width: 1600px) {
  .hero-area-2 .hero-area-image-group .image-2 {
    left: 22%;
  }
}

@media (min-width: 320px) {
  .hero-area-2 .hero-area-image-group .image-2 img {
    display: none;
  }
}

@media (min-width: 480px) {
  .hero-area-2 .hero-area-image-group .image-2 img {
    display: block;
  }
}

.hero-area-2 .hero-area-image-group .image-3 {
  position: absolute;
}

@media (min-width: 320px) {
  .hero-area-2 .hero-area-image-group .image-3 {
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 480px) {
  .hero-area-2 .hero-area-image-group .image-3 {
    top: 18%;
    right: 0;
    left: inherit;
    transform: inherit;
  }
}

.hero-area-2 .hero-area-image-group .image-3 img {
  box-shadow: 24px 26px 57px rgba(111, 118, 138, 0.16);
  border-radius: 30px;
}

.hero-area-2 .hero-area-image-group .image-4 {
  position: absolute;
  top: 53%;
  left: 2%;
}

@media (min-width: 576px) {
  .hero-area-2 .hero-area-image-group .image-4 {
    left: -7%;
  }
}

@media (min-width: 768px) {
  .hero-area-2 .hero-area-image-group .image-4 {
    left: 2%;
  }
}

@media (min-width: 992px) {
  .hero-area-2 .hero-area-image-group .image-4 {
    left: 0%;
  }
}

@media (min-width: 1200px) {
  .hero-area-2 .hero-area-image-group .image-4 {
    left: 7%;
  }
}

@media (min-width: 1366px) {
  .hero-area-2 .hero-area-image-group .image-4 {
    left: -4%;
  }
}

@media (min-width: 1600px) {
  .hero-area-2 .hero-area-image-group .image-4 {
    left: 9%;
  }
}

.hero-area-2 .hero-area-image-group .image-4 img {
  box-shadow: 8px 39px 36px rgba(111, 118, 138, 0.16);
  border-radius: 30px;
}

@media (min-width: 320px) {
  .hero-area-2 .hero-area-image-group .image-4 img {
    display: none;
  }
}

@media (min-width: 480px) {
  .hero-area-2 .hero-area-image-group .image-4 img {
    display: block;
    transform: scale(0.8);
  }
}

@media (min-width: 576px) {
  .hero-area-2 .hero-area-image-group .image-4 img {
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .hero-area-2 .hero-area-image-group .image-4 img {
    transform: scale(0.8);
  }
}

@media (min-width: 1200px) {
  .hero-area-2 .hero-area-image-group .image-4 img {
    transform: scale(1);
  }
}

.hero-area-2 .hero-area-image-group .image-5 {
  position: absolute;
  top: 52%;
  right: 0%;
}

@media (min-width: 1200px) {
  .hero-area-2 .hero-area-image-group .image-5 {
    right: 0%;
  }
}

@media (min-width: 1366px) {
  .hero-area-2 .hero-area-image-group .image-5 {
    right: 9%;
  }
}

.hero-area-2 .hero-area-image-group .image-5 img {
  box-shadow: -28px 39px 60px rgba(111, 118, 138, 0.16);
  border-radius: 30px;
}

@media (min-width: 320px) {
  .hero-area-2 .hero-area-image-group .image-5 img {
    display: none;
  }
}

@media (min-width: 480px) {
  .hero-area-2 .hero-area-image-group .image-5 img {
    display: block;
    transform: scale(0.8);
  }
}

@media (min-width: 576px) {
  .hero-area-2 .hero-area-image-group .image-5 img {
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .hero-area-2 .hero-area-image-group .image-5 img {
    transform: scale(0.8);
  }
}

@media (min-width: 1200px) {
  .hero-area-2 .hero-area-image-group .image-5 img {
    transform: scale(1);
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Landing 3 Hero Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l3 {
  position: relative;
  z-index: 1;
  padding: 87px 0px 60px 0px;
}

@media (min-width: 480px) {
  .hero-area-l3 {
    padding: 107px 0px 60px 0px;
  }
}

@media (min-width: 576px) {
  .hero-area-l3 {
    padding: 92px 0px 60px 0px;
  }
}

@media (min-width: 768px) {
  .hero-area-l3 {
    padding: 140px 0px 80px 0px;
  }
}

@media (min-width: 992px) {
  .hero-area-l3 {
    padding: 200px 0px 130px 0px;
  }
}

.hero-area-l3:after {
  position: absolute;
  content: '';
  background: #0E1833;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: .8;
  z-index: -1;
}

.hero-area-l3__content {
  margin-top: 45px;
}

@media (min-width: 768px) {
  .hero-area-l3__content {
    padding-right: 80px;
  }
}

@media (min-width: 992px) {
  .hero-area-l3__content {
    margin-top: 0;
    padding-right: 0px;
  }
}

@media (min-width: 1200px) {
  .hero-area-l3__content {
    padding-right: 45px;
  }
}

@media (min-width: 1600px) {
  .hero-area-l3__content {
    padding-right: 134px;
  }
}

.hero-area-l3__content h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 50px;
}

@media (min-width: 480px) {
  .hero-area-l3__content h2 {
    font-size: 40px;
  }
}

@media (min-width: 576px) {
  .hero-area-l3__content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.hero-area-l3__content p {
  color: #687497;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 30px;
}

.hero-area-l3__content .btn {
  width: 192px;
  height: 60px;
  border-radius: 7px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 32px;
}

.hero-area-l3-image-group {
  position: relative;
}

@media (min-width: 576px) {
  .hero-area-l3-image-group .image-1 img {
    transform: scale(0.9);
  }
}

@media (min-width: 768px) {
  .hero-area-l3-image-group .image-1 img {
    transform: scale(1);
  }
}

.hero-area-l3-image-group .image-2 {
  position: absolute;
  left: -53px;
  top: -65px;
}

@media (min-width: 480px) {
  .hero-area-l3-image-group .image-2 {
    left: -14px;
    top: -22px;
  }
}

@media (min-width: 576px) {
  .hero-area-l3-image-group .image-2 {
    left: 27px;
    top: 32px;
  }
}

.hero-area-l3-image-group .image-2 img {
  transform: scale(0.5);
}

@media (min-width: 480px) {
  .hero-area-l3-image-group .image-2 img {
    transform: scale(0.7);
  }
}

@media (min-width: 576px) {
  .hero-area-l3-image-group .image-2 img {
    transform: scale(0.8);
  }
}

@media (min-width: 768px) {
  .hero-area-l3-image-group .image-2 img {
    transform: scale(1);
  }
}

.hero-area-l3-image-group .image-3 {
  position: absolute;
  right: -38px;
  bottom: -45px;
}

@media (min-width: 480px) {
  .hero-area-l3-image-group .image-3 {
    right: -4px;
    bottom: 45px;
  }
}

@media (min-width: 576px) {
  .hero-area-l3-image-group .image-3 {
    right: 20px;
    bottom: 30px;
  }
}

@media (min-width: 992px) {
  .hero-area-l3-image-group .image-3 {
    right: -20px;
    bottom: -50px;
  }
}

@media (min-width: 1200px) {
  .hero-area-l3-image-group .image-3 {
    bottom: -15px;
    right: -15px;
  }
}

@media (min-width: 1600px) {
  .hero-area-l3-image-group .image-3 {
    bottom: 50px;
    right: 15px;
  }
}

.hero-area-l3-image-group .image-3 img {
  transform: scale(0.5);
}

@media (min-width: 480px) {
  .hero-area-l3-image-group .image-3 img {
    transform: scale(0.7);
  }
}

@media (min-width: 576px) {
  .hero-area-l3-image-group .image-3 img {
    transform: scale(0.8);
  }
}

@media (min-width: 768px) {
  .hero-area-l3-image-group .image-3 img {
    transform: scale(1);
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Landing 4 Hero Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l4 {
  background-position: left !important;
  background-size: 100% 30% !important;
  padding-top: 110px;
  padding-bottom: 50px;
}

@media (min-width: 480px) {
  .hero-area-l4 {
    padding-top: 135px;
  }
}

@media (min-width: 768px) {
  .hero-area-l4 {
    background-size: 100% 45% !important;
    padding-top: 155px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .hero-area-l4 {
    background-size: 68% 72% !important;
    padding-top: 255px;
    padding-bottom: 200px;
  }
}

.hero-l3-heading {
  padding-top: 100px;
}

@media (min-width: 768px) {
  .hero-l3-heading {
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  .hero-l3-heading {
    padding-right: 0px;
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .hero-l3-heading {
    padding-right: 10px;
  }
}

@media (min-width: 1600px) {
  .hero-l3-heading {
    padding-right: 85px;
  }
}

.hero-l3-heading h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 48px;
}

@media (min-width: 480px) {
  .hero-l3-heading h2 {
    font-size: 40px;
    line-height: 52px;
  }
}

@media (min-width: 576px) {
  .hero-l3-heading h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.hero-l3-heading p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 25px;
}

.hero-l3-heading .btn {
  width: 180px;
  height: 60px;
  box-shadow: 15px 15px 30px rgba(65, 111, 244, 0.3);
  border-radius: 33px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 20px;
}

.hero-l3-image-group {
  position: relative;
}

.hero-l3-image-group .image-1 img {
  width: 100%;
  border-radius: 16px 16px 0 0;
  box-shadow: 23px 53px 71px rgba(114, 114, 114, 0.27);
}

.hero-l3-image-group .image-2 {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .hero-l3-image-group .image-2 {
    top: 50%;
    right: -13%;
  }
}

@media (min-width: 992px) {
  .hero-l3-image-group .image-2 {
    right: 0px;
  }
}

@media (min-width: 1200px) {
  .hero-l3-image-group .image-2 {
    right: -13px;
  }
}

@media (min-width: 1366px) {
  .hero-l3-image-group .image-2 {
    right: -13%;
  }
}

.hero-l3-image-group .image-2 img {
  box-shadow: 4px 28px 62px rgba(152, 152, 152, 0.16);
  border-radius: 10px;
  width: 140px;
}

@media (min-width: 480px) {
  .hero-l3-image-group .image-2 img {
    width: initial;
  }
}

.hero-l3-image-group .image-3 {
  position: absolute;
  bottom: -50px;
  left: 0px;
}

@media (min-width: 768px) {
  .hero-l3-image-group .image-3 {
    bottom: -50px;
    left: -24px;
  }
}

.hero-l3-image-group .image-3 img {
  box-shadow: 9px 12px 30px rgba(123, 123, 123, 0.16);
  border-radius: 10px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Landing 5 Hero Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l5 {
  padding-top: 95px;
  padding-bottom: 60px;
}

@media (min-width: 480px) {
  .hero-area-l5 {
    padding-top: 117px;
  }
}

@media (min-width: 768px) {
  .hero-area-l5 {
    padding-top: 137px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .hero-area-l5 {
    padding-top: 180px;
    padding-bottom: 130px;
  }
}

.hero-area-content-l5__content h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 48px;
}

@media (min-width: 480px) {
  .hero-area-content-l5__content h2 {
    font-size: 40px;
    line-height: 52px;
  }
}

@media (min-width: 576px) {
  .hero-area-content-l5__content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.hero-area-content-l5__content p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 18px;
}

@media (min-width: 768px) {
  .hero-area-content-l5__content p {
    padding: 0px 50px;
  }
}

.hero-area-content-l5__btn {
  margin: 0px -8px -20px -8px;
  margin-top: 45px;
}

.hero-area-content-l5__btn .btn {
  margin: 0px 8px 20px 8px;
}

.hero-area-content-l5__btn .btn--get-start {
  width: 180px;
  height: 60px;
  border-radius: 30px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.hero-area-content-l5__btn .btn--all-feature {
  width: 180px;
  height: 60px;
  border-radius: 30px;
  border: 1px solid #416ff4;
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.header-btn-l5 {
  width: 110px;
  height: 45px;
  border-radius: 23px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.hero-area-l5-image {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .hero-area-l5-image {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .hero-area-l5-image {
    margin-top: 90px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Landing 6 Hero Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-6 .dot-image {
  position: absolute;
  top: 14%;
  left: 0;
}

.hero-area-6 .hero-area-content {
  padding-top: 0px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .hero-area-6 .hero-area-content {
    padding-top: 20px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .hero-area-6 .hero-area-content {
    padding-top: 323px;
    padding-bottom: 380px;
  }
}

.hero-area-6 .hero-area-content h2 {
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  font-size: 37px;
  line-height: 47px;
}

@media (min-width: 480px) {
  .hero-area-6 .hero-area-content h2 {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (min-width: 576px) {
  .hero-area-6 .hero-area-content h2 {
    font-size: 60px;
    line-height: 72px;
  }
}

@media (min-width: 992px) {
  .hero-area-6 .hero-area-content h2 {
    font-size: 55px;
    line-height: 68px;
  }
}

@media (min-width: 1200px) {
  .hero-area-6 .hero-area-content h2 {
    font-size: 60px;
    line-height: 72px;
  }
}

.hero-area-6 .hero-area-content p {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  padding-right: 0px;
  margin-top: 35px;
}

@media (min-width: 768px) {
  .hero-area-6 .hero-area-content p {
    padding-right: 75px;
  }
}

@media (min-width: 1200px) {
  .hero-area-6 .hero-area-content p {
    padding-right: 45px;
  }
}

@media (min-width: 1366px) {
  .hero-area-6 .hero-area-content p {
    padding-right: 135px;
  }
}

.hero-area-6 .hero-area-content .hero-area__btn .btn {
  min-width: 100%;
  height: 60px;
  border-radius: 5px;
  margin-top: 25px;
}

@media (min-width: 576px) {
  .hero-area-6 .hero-area-content .hero-area__btn .btn {
    min-width: 180px;
  }
}

.hero-area-6 .hero-area-image-group {
  position: relative;
  padding-top: 75px;
}

@media (min-width: 480px) {
  .hero-area-6 .hero-area-image-group {
    padding-top: 75px;
  }
}

@media (min-width: 768px) {
  .hero-area-6 .hero-area-image-group {
    padding-top: 85px;
  }
}

@media (min-width: 992px) {
  .hero-area-6 .hero-area-image-group {
    padding-top: 0;
  }
}

.hero-area-6 .hero-area-image-group .image-1 {
  transform: rotate(15deg);
}

@media (min-width: 992px) {
  .hero-area-6 .hero-area-image-group .image-1 {
    transform: rotate(0deg);
  }
}

@media (min-width: 1200px) {
  .hero-area-6 .hero-area-image-group .image-1 {
    position: absolute;
    top: 45px;
    left: 35%;
  }
}

@media (min-width: 1366px) {
  .hero-area-6 .hero-area-image-group .image-1 {
    left: 50%;
  }
}

.hero-area-6 .hero-area-image-group .image-1 img {
  width: 100%;
}

@media (min-width: 992px) {
  .hero-area-6 .hero-area-image-group .image-1 img {
    width: initial;
  }
}

.hero-area-6 .hero-area-image-group .image-2 {
  display: none;
}

@media (min-width: 768px) {
  .hero-area-6 .hero-area-image-group .image-2 {
    display: block;
    position: absolute;
    top: 40%;
    left: -12%;
    transform: rotate(15deg);
  }
}

@media (min-width: 992px) {
  .hero-area-6 .hero-area-image-group .image-2 {
    left: 15%;
    top: 120px;
    transform: rotate(0deg);
  }
}

@media (min-width: 1200px) {
  .hero-area-6 .hero-area-image-group .image-2 {
    top: 315px;
    left: 6%;
  }
}

@media (min-width: 1366px) {
  .hero-area-6 .hero-area-image-group .image-2 {
    top: 300px;
    left: 23%;
  }
}

.hero-area-6 .hero-area-image-group .image-3 {
  display: none;
}

@media (min-width: 768px) {
  .hero-area-6 .hero-area-image-group .image-3 {
    display: block;
    position: absolute;
    top: 30%;
    right: -20%;
    left: inherit;
    transform: rotate(15deg);
  }
}

@media (min-width: 992px) {
  .hero-area-6 .hero-area-image-group .image-3 {
    top: 500px;
    transform: rotate(0deg);
    right: 36%;
  }
}

@media (min-width: 1200px) {
  .hero-area-6 .hero-area-image-group .image-3 {
    top: 600px;
    left: -8%;
  }
}

@media (min-width: 1366px) {
  .hero-area-6 .hero-area-image-group .image-3 {
    top: 588px;
    left: 7%;
  }
}

@media (min-width: 1600px) {
  .hero-area-6 .hero-area-image-group .image-3 {
    top: 594px;
    left: 11%;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Landing 7 Hero Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-7 {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .hero-area-7 {
    padding-top: 122px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .hero-area-7 {
    padding-top: 150px;
    padding-bottom: 120px;
  }
}

.hero-area-content-l7 h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2px;
  line-height: 46px;
}

@media (min-width: 576px) {
  .hero-area-content-l7 h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

@media (min-width: 768px) {
  .hero-area-content-l7 h2 {
    font-size: 64px;
    line-height: 70px;
  }
}

.hero-area-content-l7 p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 25px;
}

.hero-area-group-btn {
  margin: 0px -6px -15px -6px;
  margin-top: 45px;
}

.hero-area-group-btn a {
  margin: 0px 6px 15px 6px;
  display: inline-block;
}

.hero-area-image-group-l7 {
  margin-top: 70px;
}

@media (min-width: 768px) {
  .hero-area-image-group-l7 {
    margin-top: 90px;
  }
}

@media (min-width: 992px) {
  .hero-area-image-group-l7 {
    margin-top: 110px;
  }
}

@media (min-width: 1600px) {
  .hero-area-image-group-l7 {
    padding: 0px 50px;
  }
}

.hero-area-image-group-l7 .image-1 {
  position: relative;
}

.hero-area-image-group-l7 .image-1 .shape {
  position: absolute;
  top: -8%;
  left: 25%;
  z-index: -1;
}

.hero-area-image-group-l7 .image-2 {
  margin-top: 55px;
}

.hero-area-image-group-l7 .image-3 {
  margin-top: 110px;
  position: relative;
}

.hero-area-image-group-l7 .image-3 .shape {
  position: absolute;
  bottom: 25%;
  right: -23%;
  z-index: -1;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Landing 8 Hero Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l8 {
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .hero-area-l8 {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .hero-area-l8 {
    padding-bottom: 0;
  }
}

.hero-area-l8__image {
  padding-top: 105px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .hero-area-l8__image {
    padding-top: 125px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .hero-area-l8__image {
    padding-top: 210px;
    padding-bottom: 150px;
  }
}

.hero-area-l8__image img {
  width: 100%;
}

@media (min-width: 768px) {
  .hero-content--8 {
    padding-right: 100px;
  }
}

@media (min-width: 992px) {
  .hero-content--8 {
    padding-right: 0px;
  }
}

@media (min-width: 1366px) {
  .hero-content--8 {
    padding-right: 65px;
  }
}

@media (min-width: 1600px) {
  .hero-content--8 {
    padding-right: 155px;
  }
}

.hero-content--8 h2 {
  color: #0D152E;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 50px;
}

@media (min-width: 480px) {
  .hero-content--8 h2 {
    font-size: 44px;
    line-height: 55px;
  }
}

@media (min-width: 576px) {
  .hero-content--8 h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.hero-content--8 p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 40px;
}

.hero-content--8__btn .btn {
  min-width: 180px;
  height: 60px;
  box-shadow: 21px 15px 23px rgba(65, 111, 244, 0.11);
  border-radius: 33px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Landing 9 Hero Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.hero-area-l9 {
  padding-top: 108px;
}

@media (min-width: 576px) {
  .hero-area-l9 {
    padding-top: 113px;
  }
}

@media (min-width: 768px) {
  .hero-area-l9 {
    padding-top: 125px;
  }
}

@media (min-width: 992px) {
  .hero-area-l9 {
    padding-top: 0px;
  }
}

.hero-image {
  position: relative;
}

@media (min-width: 992px) {
  .hero-image:after {
    position: absolute;
    content: "";
    background: #0D152E;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 0;
  }
}

@media (min-width: 992px) {
  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    width: 45%;
    height: 100%;
    background-position: center;
  }
  .hero-image img {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hero-image {
    width: 42%;
  }
}

@media (min-width: 1600px) {
  .hero-image {
    width: 38%;
    background-position: 100%;
  }
}

.hero-image .video-icon {
  height: 96px;
  width: 96px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

@media (min-width: 992px) {
  .hero-image .video-icon {
    left: initial;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
  }
}

.hero-image .video-icon i {
  width: 66px;
  height: 66px;
  color: #416ff4;
  box-shadow: 0 14px 25px rgba(132, 132, 132, 0.16);
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 66px;
  position: relative;
}

.hero-image .video-icon:after {
  position: absolute;
  content: "";
  background: #416ff4;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: .3;
  border-radius: 50%;
  z-index: -1;
}

.content--l9-1 {
  position: relative;
  padding: 30px 0px 60px 0px;
}

@media (min-width: 768px) {
  .content--l9-1 {
    padding: 50px 0px 80px 0px;
  }
}

@media (min-width: 992px) {
  .content--l9-1 {
    padding: 230px 0px 190px 160px;
  }
}

@media (min-width: 1200px) {
  .content--l9-1 {
    padding: 230px 160px 190px 160px;
  }
}

@media (min-width: 1366px) {
  .content--l9-1 {
    padding: 230px 160px 190px 160px;
  }
}

@media (min-width: 1600px) {
  .content--l9-1 {
    padding: 230px 225px 190px 190px;
  }
}

.content--l9-1__text-box .badge {
  white-space: inherit;
  width: 100%;
}

@media (min-width: 480px) {
  .content--l9-1__text-box .badge {
    white-space: nowrap;
    width: 400px;
  }
}

.content--l9-1__text-box .badge h6 {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
  padding: 6px;
  border-radius: 10px;
}

@media (min-width: 480px) {
  .content--l9-1__text-box .badge h6 {
    border-radius: 30px;
  }
}

.content--l9-1__text-box .badge-offer {
  padding: 0px 15px !important;
  border-radius: 30px;
}

.content--l9-1__text-box .badge span {
  padding: 0px 12px 0px 10px;
}

@media (min-width: 480px) {
  .content--l9-1__text-box .badge span {
    padding: 0px 12px 0px 15px;
  }
}

.content--l9-1__text-box h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.88px;
  line-height: 50px;
  margin-top: 13px;
}

@media (min-width: 576px) {
  .content--l9-1__text-box h2 {
    font-size: 54px;
    line-height: 65px;
  }
}

@media (min-width: 768px) {
  .content--l9-1__text-box h2 {
    font-size: 60px;
    line-height: 72px;
  }
}

.content--l9-1__text-box p {
  color: #687497;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 20px;
}

.content--l9-1__form {
  width: 100%;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .content--l9-1__form {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .content--l9-1__form {
    width: 400px;
    margin-top: 32px;
  }
}

.content--l9-1__form h4 {
  margin-bottom: 19px;
}

.content--l9-1__form .form-control {
  color: #81838c;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  height: 61px;
  border-radius: 30px;
  background-color: #fff;
  padding-left: 27px;
}

.content--l9-1__form .btn {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  margin-top: 10px;
}

@media (min-width: 576px) {
  .content--l9-1__form .btn {
    width: 120px;
    height: 41px;
    margin-left: -140px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
@media (min-width: 320px) {
  .feature-area {
    padding-top: 45px;
  }
}

@media (min-width: 768px) {
  .feature-area {
    padding-top: 65px;
  }
}

@media (min-width: 992px) {
  .feature-area {
    padding-top: 115px;
  }
}

.feature-area .feature--card {
  border: 0;
}

@media (min-width: 320px) {
  .feature-area .feature--card {
    padding-top: 40px;
  }
}

@media (min-width: 768px) {
  .feature-area .feature--card {
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .feature-area .feature--card {
    padding-top: 85px;
  }
}

.feature-area .card__icon {
  margin: auto;
  width: 70px;
  height: 70px;
  box-shadow: 0 22px 24px rgba(65, 111, 244, 0.2);
  border-radius: 9px;
  background-color: #416ff4;
}

.feature-area .card__icon__2 {
  box-shadow: 0 22px 24px rgba(43, 214, 123, 0.2);
  background-color: #2bd67b;
}

.feature-area .card__icon__3 {
  box-shadow: 0 22px 24px rgba(247, 227, 109, 0.2);
  background-color: #f7e36d;
}

.feature-area .card__icon i {
  color: #fff;
  line-height: 70px;
  font-size: 25px;
}

.feature-area .card-body .card__title {
  margin-bottom: 18px;
}

.feature-area .card-body h4 {
  margin-top: 20px;
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.feature-area .card-body p {
  color: #81838c;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.feature-l2-try-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #f4f7fa;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.63px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  margin-top: 55px;
}

@media (min-width: 320px) {
  .feature-area-2 {
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .feature-area-2 {
    padding-top: 130px;
  }
}

@media (min-width: 1200px) {
  .feature-area-2 {
    padding-top: 170px;
  }
}

@media (min-width: 1200px) {
  .feature-area-2 .section__heading p {
    margin-right: 0px;
  }
}

@media (min-width: 1366px) {
  .feature-area-2 .section__heading p {
    margin-right: 0px;
  }
}

@media (min-width: 1600px) {
  .feature-area-2 .section__heading p {
    margin-right: 70px;
  }
}

@media (min-width: 320px) {
  .feature-area-2 .feature-area-2-content {
    padding-top: 30px;
  }
}

@media (min-width: 768px) {
  .feature-area-2 .feature-area-2-content {
    padding-top: 40px;
  }
}

@media (min-width: 992px) {
  .feature-area-2 .feature-area-2-content {
    padding-top: 0px;
  }
}

.feature-area-2 .feature-area-2-content .feature--item {
  margin-top: 50px;
  margin-bottom: -30px;
}

.feature-area-2 .feature-area-2-content .feature--item .col-lg-7 {
  margin-bottom: 30px;
}

.feature-area-2 .feature-area-2-content .widget .icon__box {
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  box-shadow: 8px 11px 26px rgba(145, 145, 145, 0.16);
  border-radius: 10px;
}

.feature-area-2 .feature-area-2-content .widget .icon__box svg {
  width: 16px;
}

.feature-area-2 .feature-area-2-content .widget .widget-body {
  margin-left: 27px;
}

.feature-area-2 .feature-area-2-content .widget .widget-body h6 {
  color: #0D152E;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  font-size: 20px;
  line-height: 30px;
}

.feature-area-2-img-group {
  position: relative;
}

@media (min-width: 992px) {
  .feature-area-2-img-group .image__1 {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .feature-area-2-img-group .image__1 {
    margin-left: 70px;
  }
}

.feature-area-2-img-group .image__1 img {
  box-shadow: -59px 3px 99px rgba(129, 129, 129, 0.16);
  border-radius: 10px;
}

@media (min-width: 320px) {
  .feature-area-2-img-group .image__1 img {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .feature-area-2-img-group .image__1 img {
    width: initial;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-3 {
  padding-top: 30px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .feature-area-3 {
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .feature-area-3 {
    padding-bottom: 105px;
  }
}

.feature-area-3 .feature-area-3-image-group {
  position: relative;
}

.feature-area-3 .feature-area-3-image-group .image-1 img {
  box-shadow: 32px 51px 84px rgba(111, 118, 138, 0.16);
  border-radius: 30px;
}

.feature-area-3 .feature-area-3-image-group .image-2 {
  position: absolute;
  top: -5%;
  left: 0%;
  z-index: -1;
}

@media (min-width: 320px) {
  .feature-area-3 .feature-area-3-image-group .image-2 {
    display: none;
  }
}

@media (min-width: 480px) {
  .feature-area-3 .feature-area-3-image-group .image-2 {
    display: block;
    left: 14%;
  }
}

@media (min-width: 576px) {
  .feature-area-3 .feature-area-3-image-group .image-2 {
    left: 18%;
  }
}

@media (min-width: 992px) {
  .feature-area-3 .feature-area-3-image-group .image-2 {
    left: 13%;
  }
}

@media (min-width: 1200px) {
  .feature-area-3 .feature-area-3-image-group .image-2 {
    left: 20%;
  }
}

@media (min-width: 1600px) {
  .feature-area-3 .feature-area-3-image-group .image-2 {
    left: 24%;
  }
}

.feature-area-3 .feature-area-3-image-group .image-3 {
  position: absolute;
  top: 50%;
  left: 0%;
}

@media (min-width: 480px) {
  .feature-area-3 .feature-area-3-image-group .image-3 {
    left: 8%;
  }
}

@media (min-width: 576px) {
  .feature-area-3 .feature-area-3-image-group .image-3 {
    left: 13%;
  }
}

@media (min-width: 1200px) {
  .feature-area-3 .feature-area-3-image-group .image-3 {
    left: 7%;
  }
}

@media (min-width: 1366px) {
  .feature-area-3 .feature-area-3-image-group .image-3 {
    left: 4%;
  }
}

@media (min-width: 1600px) {
  .feature-area-3 .feature-area-3-image-group .image-3 {
    left: 13%;
  }
}

.feature-area-3 .feature-area-3-image-group .image-3 img {
  box-shadow: -15px 24px 78px rgba(111, 118, 138, 0.16);
  border-radius: 5px;
}

@media (min-width: 320px) {
  .feature-area-3 .feature-area-3-image-group .image-3 img {
    display: none;
  }
}

@media (min-width: 480px) {
  .feature-area-3 .feature-area-3-image-group .image-3 img {
    display: block;
    transform: scale(0.8);
  }
}

@media (min-width: 576px) {
  .feature-area-3 .feature-area-3-image-group .image-3 img {
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .feature-area-3 .feature-area-3-image-group .image-3 img {
    transform: scale(0.8);
  }
}

@media (min-width: 1200px) {
  .feature-area-3 .feature-area-3-image-group .image-3 img {
    transform: scale(1);
  }
}

.feature-area-3 .feature-area-3-image-group .image-4 {
  position: absolute;
}

@media (min-width: 320px) {
  .feature-area-3 .feature-area-3-image-group .image-4 {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 480px) {
  .feature-area-3 .feature-area-3-image-group .image-4 {
    top: 50%;
    right: 10%;
    left: inherit;
    transform: inherit;
  }
}

@media (min-width: 992px) {
  .feature-area-3 .feature-area-3-image-group .image-4 {
    right: 4%;
  }
}

@media (min-width: 1200px) {
  .feature-area-3 .feature-area-3-image-group .image-4 {
    right: 18%;
  }
}

@media (min-width: 1366px) {
  .feature-area-3 .feature-area-3-image-group .image-4 {
    right: 22%;
  }
}

.feature-area-3 .feature-area-3-image-group .image-4 img {
  box-shadow: -15px 24px 78px rgba(111, 118, 138, 0.16);
  border-radius: 10px;
}

@media (min-width: 480px) {
  .feature-area-3 .feature-area-3-image-group .image-4 img {
    transform: scale(0.8);
  }
}

@media (min-width: 576px) {
  .feature-area-3 .feature-area-3-image-group .image-4 img {
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .feature-area-3 .feature-area-3-image-group .image-4 img {
    transform: scale(0.8);
  }
}

@media (min-width: 1200px) {
  .feature-area-3 .feature-area-3-image-group .image-4 img {
    transform: scale(1);
  }
}

.feature-area-3 .feature-area-3-content {
  padding-top: 35px;
}

@media (min-width: 1200px) {
  .feature-area-3 .feature-area-3-content {
    padding-top: 0px;
    margin-right: 75px;
  }
}

@media (min-width: 1600px) {
  .feature-area-3 .feature-area-3-content {
    margin-right: 165px;
  }
}

.feature-area-3 .feature-area-3-content h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 47px;
}

@media (min-width: 576px) {
  .feature-area-3 .feature-area-3-content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.feature-area-3 .feature-area-3-content p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 25px;
}

.feature-area-3 .feature-area-3-content .feature-area-3-service {
  margin-top: 53px;
}

.feature-area-3 .feature-area-3-content .feature-area-3-service ul {
  margin-bottom: -23px;
}

.feature-area-3 .feature-area-3-content .feature-area-3-service ul li {
  margin-bottom: 23px;
}

.feature-area-3 .feature-area-3-content .feature-area-3-service .service-icon {
  border-radius: 10px;
  margin-right: 32px;
}

.feature-area-3 .feature-area-3-content .feature-area-3-service .service-icon i {
  height: 60px;
  width: 60px;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  transform: rotate(-45deg);
}

.feature-area-3 .feature-area-3-content .feature-area-3-service .service-content {
  margin-top: -6px;
}

.feature-area-3 .feature-area-3-content .feature-area-3-service .service-content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.feature-area-3 .feature-area-3-content .feature-area-3-service .service-content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-3
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l3-1 {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .feature-area-l3-1 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .feature-area-l3-1 {
    padding-top: 130px;
  }
}

.feature-area-l3-1 .section__heading i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  border-radius: 20px;
  background-color: #2bd67b;
  margin-bottom: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-4 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l4 .heading h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: normal;
}

.feature-l4-widget-items {
  margin-top: 40px;
  margin-bottom: -40px;
}

@media (min-width: 768px) {
  .feature-l4-widget-items {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .feature-l4-widget-items {
    margin-top: 70px;
  }
}

.feature-l4-widget-items .col-lg-4 {
  margin-bottom: 40px;
}

.feature-l4-widget__icon img {
  margin-right: 30px;
  width: 30px;
}

@media (min-width: 768px) {
  .feature-l4-widget__icon img {
    width: initial;
  }
}

.feature-l4-widget__content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 30px;
}

.feature-l4-widget__content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
}

@media (min-width: 768px) {
  .feature-l4-widget__content p {
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .feature-l4-widget__content p {
    padding-right: 26px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-4 Feature-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.video-feature-l4-items {
  margin-top: 50px;
  margin-bottom: -40px;
}

@media (min-width: 768px) {
  .video-feature-l4-items {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .video-feature-l4-items {
    margin-top: 100px;
  }
}

.video-feature-l4-items .col-lg-4 {
  margin-bottom: 40px;
}

.video-fea-l4-widget__icon i {
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  box-shadow: 7px 7px 14px rgba(65, 111, 244, 0.1);
  background-color: #416ff4;
  margin-right: 25px;
}

.video-fea-l4-widget__content h4 {
  color: #162647;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: normal;
  text-align: left;
}

.video-fea-l4-widget__content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .video-fea-l4-widget__content p {
    padding-right: 45px;
  }
}

@media (min-width: 992px) {
  .video-fea-l4-widget__content p {
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .video-fea-l4-widget__content p {
    padding-right: 47px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-4 Feature-3
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.doctor-list-area-l4 {
  position: relative;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .doctor-list-area-l4 {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .doctor-list-area-l4 {
    padding-bottom: 130px;
  }
}

.doctor-list-area-l4:after {
  position: absolute;
  content: "";
  background: #F7F9FB;
  height: 85%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media (min-width: 768px) {
  .doctor-list-area-l4:after {
    height: 80%;
  }
}

@media (min-width: 992px) {
  .doctor-list-area-l4:after {
    height: 76%;
  }
}

@media (min-width: 1200px) {
  .doctor-list-area-l4:after {
    height: 67%;
  }
}

.doctor-list-l4-item {
  margin-bottom: -75px;
  padding-top: 50px;
}

@media (min-width: 768px) {
  .doctor-list-l4-item {
    padding-top: 70px;
  }
}

@media (min-width: 992px) {
  .doctor-list-l4-item {
    padding-top: 100px;
  }
}

.doctor-list-l4-item .col-xxl-3 {
  margin-bottom: 75px;
}

.doctor-list-l4-item .card-doctor-identity {
  border: 0;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
  padding: 0px 15px 20px 15px;
  transition: all 0.4s ease-in-out;
}

.doctor-list-l4-item .card-doctor-identity:hover {
  box-shadow: 0 33px 77px rgba(0, 0, 0, 0.13);
}

.doctor-list-l4-item__image {
  margin-top: -40px;
}

.doctor-list-l4-item__content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 30px;
}

.doctor-list-l4-item__content p {
  color: #81838C;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
}

.doctor-list-l4-item__list ul {
  margin-top: 10px;
}

.doctor-list-l4-item__list ul li {
  color: #0D152E;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: 22px;
  margin-bottom: 6px;
}

.doctor-list-l4-item__list ul li span {
  margin-right: 8px;
}

.doctor-list-l4-item__button {
  margin-top: 20px;
}

.doctor-list-l4-item__button .btn--call-now {
  width: 108px;
  height: 40px;
  border-radius: 122px;
  background-color: #2bd67b;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

.doctor-list-l4-item__button .btn--availability {
  width: 108px;
  height: 40px;
  border-radius: 122px;
  border: 1px solid #101C3D;
  color: #0D152E;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

.doctor-list-l4-item__button .btn--availability:hover {
  color: #0D152E !important;
}

.doc-list-explore-btn {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .doc-list-explore-btn {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .doc-list-explore-btn {
    margin-top: 100px;
  }
}

.doc-list-explore-btn .btn {
  width: 228px;
  height: 60px;
  box-shadow: 15px 15px 30px rgba(65, 111, 244, 0.3);
  border-radius: 33px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-6 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l5-1 {
  padding-top: 45px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .feature-area-l5-1 {
    padding-top: 65px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .feature-area-l5-1 {
    padding-top: 115px;
    padding-bottom: 130px;
  }
}

.feature-area-l5-1 .section__heading h2 {
  color: #fff;
}

.feature-area-l5-1 .section__heading p {
  color: #687497;
}

.feature-l5-1-items {
  margin-bottom: -40px;
  margin-top: 80px;
}

.feature-l5-1-items .col-lg-3:last-child:after {
  display: none;
}

.feature-l5-1-items .col-lg-3 {
  position: relative;
  margin-bottom: 40px;
}

.feature-l5-1-items .col-lg-3:after {
  position: absolute;
  content: "";
  background: url(/image/landing-5/arrow-long.png) no-repeat;
  top: 50%;
  right: -58%;
  transform: translate(-50%, -50%);
  height: 35px;
  width: 107px;
  display: none;
}

@media (min-width: 992px) {
  .feature-l5-1-items .col-lg-3:after {
    display: block;
    right: -68%;
  }
}

@media (min-width: 1200px) {
  .feature-l5-1-items .col-lg-3:after {
    right: -58%;
  }
}

.feature-l5-1-items__card {
  padding: 0px 22px 26px 22px;
  background: #0D152E;
  border-radius: 15px;
}

.feature-l5-1-items__card .number {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: #1d2e5e;
  display: inline-block;
  border-radius: 50%;
  margin-top: -22px;
}

.feature-l5-1-items__card .number span {
  color: #416ff4;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
}

.feature-l5-1-items__card .content {
  margin-top: 53px;
}

.feature-l5-1-items__card .content h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
}

.feature-l5-1-items__card .content p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-6 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l6 {
  position: relative;
  padding-top: 45px;
}

@media (min-width: 768px) {
  .feature-area-l6 {
    padding-top: 65px;
  }
}

@media (min-width: 992px) {
  .feature-area-l6 {
    padding-top: 0px;
  }
}

@media (min-width: 1200px) {
  .feature-area-l6 {
    padding-top: 75px;
  }
}

@media (min-width: 1200px) {
  .feature-area-l6 .section__heading-2 p {
    padding: 0px 5px;
  }
}

@media (min-width: 1366px) {
  .feature-area-l6 .section__heading-2 p {
    padding: 0px;
  }
}

.feature-area-l6 .feature-widget-items {
  margin-top: 40px;
  margin-bottom: -45px;
}

@media (min-width: 768px) {
  .feature-area-l6 .feature-widget-items {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .feature-area-l6 .feature-widget-items {
    margin-top: 75px;
  }
}

.feature-area-l6 .feature-widget {
  height: 100%;
  margin-bottom: 45px;
}

.feature-area-l6 .feature-widget .feature-widget-icon i {
  color: #416ff4;
  font-size: 24px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  line-height: 80px;
  box-shadow: 0 12px 48px rgba(146, 146, 146, 0.16);
  background-color: #fff;
}

.feature-area-l6 .feature-widget .feature-widget-icon__2 i {
  color: #2bd67b;
}

.feature-area-l6 .feature-widget .feature-widget-icon__3 i {
  color: #EED22D;
}

.feature-area-l6 .feature-widget .feature-widget-icon__4 i {
  color: #9726EC;
}

.feature-area-l6 .feature-widget .feature-widget-content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 30px;
}

.feature-area-l6 .feature-widget .feature-widget-content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
}

@media (min-width: 480px) {
  .feature-area-l6 .feature-widget .feature-widget-content p {
    margin: 25px 35px 0px 35px;
  }
}

@media (min-width: 576px) {
  .feature-area-l6 .feature-widget .feature-widget-content p {
    margin: 25px 0px 0px 0px;
  }
}

@media (min-width: 768px) {
  .feature-area-l6 .feature-widget .feature-widget-content p {
    margin: 25px 35px 0px 35px;
  }
}

@media (min-width: 992px) {
  .feature-area-l6 .feature-widget .feature-widget-content p {
    margin: 25px 0px 0px 0px;
  }
}

@media (min-width: 1600px) {
  .feature-area-l6 .feature-widget .feature-widget-content p {
    margin: 25px 26px 0px 26px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-7 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-card-items-l7-1 {
  margin: 0px -15px -30px -15px;
  padding-top: 45px;
}

@media (min-width: 768px) {
  .feature-card-items-l7-1 {
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .feature-card-items-l7-1 {
    margin: 0px;
    padding-top: 85px;
  }
}

.feature-card-items-l7-1 .feature-card {
  box-shadow: 10px 10px 40px rgba(111, 118, 138, 0.16);
  background-color: #fefffe;
  padding: 30px 35px 30px 35px;
  flex: 0 0 85%;
  margin: 0px 15px 30px 15px;
}

@media (min-width: 576px) {
  .feature-card-items-l7-1 .feature-card {
    flex: 0 0 44%;
  }
}

@media (min-width: 768px) {
  .feature-card-items-l7-1 .feature-card {
    flex: 0 0 29%;
  }
}

@media (min-width: 992px) {
  .feature-card-items-l7-1 .feature-card {
    margin: 0px;
    flex: 0 0 18%;
  }
}

.feature-card-items-l7-1 .feature-card h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.feature-card-items-l7-1 .feature-card2 {
  box-shadow: 10px 10px 40px rgba(111, 118, 138, 0.16);
  background-color: rgba(111, 118, 138, 0.16);
  padding: 30px 35px 30px 35px;
  flex: 0 0 85%;
  margin: 0px 15px 30px 15px;
}

@media (min-width: 576px) {
  .feature-card-items-l7-1 .feature-card2 {
    flex: 0 0 44%;
  }
}

@media (min-width: 768px) {
  .feature-card-items-l7-1 .feature-card2 {
    flex: 0 0 29%;
  }
}

@media (min-width: 992px) {
  .feature-card-items-l7-1 .feature-card2 {
    margin: 0px;
    flex: 0 0 18%;
  }
}

.feature-card-items-l7-1 .feature-card2 h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-8 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l8-1 {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .feature-area-l8-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .feature-area-l8-1 {
    padding-top: 120px;
    padding-bottom: 130px;
  }
}

.feature-area-l8-1__total-item {
  margin-bottom: -30px;
}

@media (min-width: 768px) {
  .feature-area-l8-1__total-item {
    margin-bottom: -50px;
  }
}

@media (min-width: 992px) {
  .feature-area-l8-1__total-item {
    margin-bottom: -133px;
  }
}

.feature-area-l8-1__heading h2 {
  color: #0D152E;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 50px;
  margin-bottom: 40px;
}

@media (min-width: 576px) {
  .feature-area-l8-1__heading h2 {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .feature-area-l8-1__heading h2 {
    padding-right: 60px;
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .feature-area-l8-1__heading h2 {
    font-size: 42px;
    line-height: 55px;
  }
}

@media (min-width: 1200px) {
  .feature-area-l8-1__heading h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.widget--feature-l8-1 {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .widget--feature-l8-1 {
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .widget--feature-l8-1 {
    margin-bottom: 133px;
  }
}

.widget--feature-l8-1__icon i {
  font-size: 21px;
  color: #fff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 7px 7px 14px rgba(65, 111, 244, 0.1);
  background-color: #416ff4;
  margin-right: 25px;
}

.widget--feature-l8-1__head h4 {
  color: #162647;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: normal;
}

@media (min-width: 992px) {
  .widget--feature-l8-1__content {
    padding-right: 35px;
  }
}

.widget--feature-l8-1__content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-top: 27px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-8 Feature-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l8-2 {
  padding-top: 55px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .feature-area-l8-2 {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .feature-area-l8-2 {
    padding-top: 130px;
    padding-bottom: 105px;
  }
}

.feature-area-l8-2__total-item {
  margin-bottom: -30px;
}

@media (min-width: 768px) {
  .feature-area-l8-2__total-item {
    margin-bottom: -50px;
  }
}

@media (min-width: 992px) {
  .feature-area-l8-2__total-item {
    margin-bottom: -133px;
  }
}

.feature-area-l8-2__heading h2 {
  color: #0D152E;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 50px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .feature-area-l8-2__heading h2 {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .feature-area-l8-2__heading h2 {
    padding-right: 60px;
    font-size: 40px;
    line-height: 50px;
    margin-top: -10px;
  }
}

@media (min-width: 1600px) {
  .feature-area-l8-2__heading h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.widget--feature-l8-2 {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .widget--feature-l8-2 {
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .widget--feature-l8-2 {
    margin-bottom: 133px;
  }
}

.widget--feature-l8-2__icon i {
  font-size: 15px;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 7px 7px 14px rgba(65, 111, 244, 0.1);
  background-color: #416ff4;
  margin-right: 25px;
}

@media (min-width: 1600px) {
  .widget--feature-l8-2__content {
    padding-right: 35px;
  }
}

.widget--feature-l8-2__content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.widget--feature-l8-2__content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-top: 0px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-9 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-l9-items {
  margin-bottom: -30px;
  padding-top: 40px;
}

@media (min-width: 768px) {
  .feature-l9-items {
    padding-top: 55px;
  }
}

@media (min-width: 992px) {
  .feature-l9-items {
    padding-top: 77px;
  }
}

.feature-l9-items .col-lg-4 {
  margin-bottom: 30px;
}

.card--feature-l9 {
  background: #262626;
  border: 0;
  border-radius: 0px;
  border-top: 5px solid #b2282d;
  padding: 50px 30px;
  height: 100%;
}

.card--feature-l9__icon img {
  border-radius: 20px;
  box-shadow: 0 18px 35px rgba(194, 194, 194, 0.16);
  margin-bottom: 27px;
  height: 84px;
}

.card--feature-l9__heading h4 {
  color: #0D152E;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
}

.card--feature-l9__content p {
  color: #182748;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 25px;
}

.card--feature-l9__btn .btn {
  width: 180px;
  height: 60px;
  border-radius: 10px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 25px;
}

.card--feature-l9--2 {
  background: #262626;
}

.card--feature-l9--2 .btn {
  background: #2bd67b;
}

.card--feature-l9--3 {
  background: #262626;
}

.card--feature-l9--3 .btn {
  background: #F7E36D;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-9 Feature-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l9-1 {
  padding-top: 55px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .feature-area-l9-1 {
    padding-top: 75px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .feature-area-l9-1 {
    padding-top: 127px;
    padding-bottom: 110px;
  }
}

.feature-area-l9-1--items {
  margin-bottom: 0px;
}

.widget--feature-l9-1 {
  margin-bottom: 30px;
}

.widget--feature-l9-1__icon i {
  font-size: 18px;
  color: #101C3D;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 8px 11px 19px rgba(164, 160, 160, 0.27);
  background-color: #fff;
  margin-right: 32px;
}

@media (min-width: 1600px) {
  .widget--feature-l9-1__content {
    padding-right: 35px;
  }
}

.widget--feature-l9-1__content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.widget--feature-l9-1__content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-bottom: 0;
  margin-top: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-9 Feature-3
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l9-2 {
  position: relative;
}

@media (min-width: 992px) {
  .feature-area-l9-2 {
    padding-bottom: 130px;
  }
}

.feature-area-l9-2:after {
  position: absolute;
  content: "";
  background: #FBFBFB;
  height: 76%;
  width: 100%;
  top: 195px;
  left: 0;
  z-index: -1;
  display: none;
}

@media (min-width: 992px) {
  .feature-area-l9-2:after {
    display: block;
    top: 200px;
  }
}

@media (min-width: 1200px) {
  .feature-area-l9-2:after {
    top: 185px;
  }
}

@media (min-width: 1600px) {
  .feature-area-l9-2:after {
    top: 195px;
  }
}

.feature-area-l9-2 .section__heading-2 h2 {
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .feature-area-l9-2 .section__heading-2 h2 {
    margin-bottom: 40px;
  }
}

.feature-area-l9-2 .feature-items {
  margin-bottom: -30px;
}

.card--feature-l9-2 {
  border-radius: 10px;
  border: 1px solid #E5E5E5;
  background-color: #fff;
  padding: 30px;
  transition: all 0.4s ease-in-out;
  margin-bottom: 30px;
  height: 100%;
}

.card--feature-l9-2:hover {
  box-shadow: 0 22px 44px rgba(149, 149, 149, 0.16);
}

.card--feature-l9-2__content {
  padding-top: 28px;
}

.card--feature-l9-2__content span {
  color: #416ff4;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

.card--feature-l9-2__content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 30px;
  margin-top: 7px;
}

.widget--lecture {
  margin: 20px -30px 0px -30px;
}

.widget--lecture-item {
  margin: 0px 30px;
}

.widget--lecture-item i {
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 11px;
  text-align: center;
  box-shadow: 1px 3px 10px rgba(94, 94, 94, 0.16);
  background-color: #fff;
  color: #101C3D;
  margin-right: 10px;
  border-radius: 50%;
}

.widget--lecture-item span {
  color: #81838C;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

.explore-lessons-btn .btn {
  width: 222px;
  height: 60px;
  border-radius: 30px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .explore-lessons-btn .btn {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .explore-lessons-btn .btn {
    margin-top: 70px;
  }
}

.l9-slider-arrow-1 {
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .l9-slider-arrow-1 {
    margin-bottom: 0px;
  }
}

.l9-slider-arrow-1 i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #101C3D;
  border: 1px solid #101C3D;
  border-radius: 50%;
  margin: 0px 6px;
  cursor: pointer;
}

.feature-slider-l9-1 {
  margin: 0px -15px;
}

.feature-slider-l9-1 .single-item {
  margin: 0px 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-1 Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
@media (min-width: 320px) {
  .content-area-1 {
    padding-top: 20px;
  }
}

@media (min-width: 768px) {
  .content-area-1 {
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  .content-area-1 {
    padding-top: 70px;
  }
}

.content-area-1 .section__heading h2 {
  margin-right: 10px;
}

.content-area-1 .content-image-group-1 {
  position: relative;
}

.content-area-1 .content-image-group-1 .image__1 img {
  box-shadow: 40px 44px 81px rgba(111, 118, 138, 0.16);
  border-radius: 10px;
}

@media (min-width: 320px) {
  .content-area-1 .content-image-group-1 .image__2 {
    display: none;
  }
}

@media (min-width: 768px) {
  .content-area-1 .content-image-group-1 .image__2 {
    display: block;
    position: absolute;
    bottom: -50px;
    left: -18px;
  }
}

.content-area-1 .content-image-group-1 .image__2 img {
  box-shadow: 15px 39px 53px rgba(111, 118, 138, 0.15);
  border-radius: 10px;
}

@media (min-width: 320px) {
  .content-area-1 .content {
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  .content-area-1 .content {
    padding-top: 115px;
  }
}

@media (min-width: 1200px) {
  .content-area-1 .content {
    padding-right: 100px;
  }
}

@media (min-width: 1366px) {
  .content-area-1 .content {
    padding-right: 55px;
  }
}

@media (min-width: 1600px) {
  .content-area-1 .content {
    padding-right: 160px;
  }
}

@media (min-width: 320px) {
  .content-area-2 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

@media (min-width: 768px) {
  .content-area-2 {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .content-area-2 {
    padding-bottom: 130px;
    padding-top: 180px;
  }
}

.content-area-2 .content-image-group-2 {
  position: relative;
}

.content-area-2 .content-image-group-2 .image__1 img {
  box-shadow: -24px 52px 99px rgba(111, 118, 138, 0.16);
  border-radius: 10px;
}

@media (min-width: 320px) {
  .content-area-2 .content-image-group-2 .image__1 {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media (min-width: 992px) {
  .content-area-2 .content-image-group-2 .image__1 {
    margin-right: 70px;
    margin-left: 65px;
  }
}

.content-area-2 .content-image-group-2 .image__2 img {
  box-shadow: 15px 39px 53px rgba(111, 118, 138, 0.15);
  border-radius: 10px;
}

@media (min-width: 320px) {
  .content-area-2 .content-image-group-2 .image__2 {
    display: none;
  }
}

@media (min-width: 768px) {
  .content-area-2 .content-image-group-2 .image__2 {
    display: block;
    position: absolute;
    bottom: -87px;
    left: 0px;
    z-index: -1;
  }
}

@media (min-width: 320px) {
  .content-area-2 .content {
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  .content-area-2 .content {
    padding-top: 150px;
  }
}

@media (min-width: 992px) {
  .content-area-2 .content {
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .content-area-2 .content {
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .content-area-2 .content {
    padding-right: 85px;
  }
}

.integration-area {
  position: relative;
}

@media (min-width: 320px) {
  .integration-area {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (min-width: 1200px) {
  .integration-area {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media (min-width: 320px) {
  .integration-area .content {
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  .integration-area .content {
    padding-top: 120px;
  }
}

@media (min-width: 992px) {
  .integration-area .content {
    padding-top: 165px;
  }
}

@media (min-width: 1200px) {
  .integration-area .content {
    margin-left: 105px;
    padding-top: 0;
  }
}

@media (min-width: 320px) {
  .integration-area .image__1 {
    margin-left: -160px;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__1 {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 0px;
  }
}

@media (min-width: 1366px) {
  .integration-area .image__1 {
    margin-left: -65px;
  }
}

@media (min-width: 1600px) {
  .integration-area .image__1 {
    margin-left: 0px;
  }
}

.integration-area .image__1 img {
  width: 100%;
  height: 270px;
}

@media (min-width: 480px) {
  .integration-area .image__1 img {
    height: 400px;
  }
}

@media (min-width: 576px) {
  .integration-area .image__1 img {
    height: 415px;
  }
}

@media (min-width: 768px) {
  .integration-area .image__1 img {
    height: 550px;
  }
}

@media (min-width: 992px) {
  .integration-area .image__1 img {
    height: 600px;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__1 img {
    width: 86%;
    height: 555px;
  }
}

@media (min-width: 1366px) {
  .integration-area .image__1 img {
    width: 100%;
  }
}

@media (min-width: 1600px) {
  .integration-area .image__1 img {
    width: 120%;
  }
}

.integration-area .image__2 {
  position: relative;
}

.integration-area .image__2 img {
  position: absolute;
}

@media (min-width: 320px) {
  .integration-area .image__2 img {
    left: 33%;
    top: -304px;
    width: 40%;
  }
}

@media (min-width: 480px) {
  .integration-area .image__2 img {
    left: 54%;
    top: -425px;
    width: 30%;
  }
}

@media (min-width: 768px) {
  .integration-area .image__2 img {
    left: 58%;
    top: -621px;
    width: initial;
  }
}

@media (min-width: 992px) {
  .integration-area .image__2 img {
    left: 58%;
    top: -700px;
    width: initial;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__2 img {
    left: 44%;
    top: -341px;
  }
}

.integration-area .image__3 {
  position: relative;
}

.integration-area .image__3 img {
  position: absolute;
}

@media (min-width: 320px) {
  .integration-area .image__3 img {
    left: 0%;
    top: -289px;
    width: 30%;
  }
}

@media (min-width: 480px) {
  .integration-area .image__3 img {
    left: 90px;
    top: -420px;
  }
}

@media (min-width: 768px) {
  .integration-area .image__3 img {
    left: 63px;
    top: -634px;
    width: 40%;
  }
}

@media (min-width: 992px) {
  .integration-area .image__3 img {
    left: 90px;
    top: -675px;
    width: 35%;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__3 img {
    left: -30px;
    top: -317px;
    width: 50%;
  }
}

@media (min-width: 1600px) {
  .integration-area .image__3 img {
    width: initial;
  }
}

.integration-area .image__4 {
  position: relative;
}

.integration-area .image__4 img {
  position: absolute;
}

@media (min-width: 320px) {
  .integration-area .image__4 img {
    left: 75%;
    top: -244px;
    width: 30%;
  }
}

@media (min-width: 480px) {
  .integration-area .image__4 img {
    left: 78%;
    top: -312px;
  }
}

@media (min-width: 768px) {
  .integration-area .image__4 img {
    left: 74%;
    top: -441px;
    width: 40%;
  }
}

@media (min-width: 992px) {
  .integration-area .image__4 img {
    left: 74%;
    top: -524px;
    width: 35%;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__4 img {
    left: 425px;
    top: -218px;
    width: 50%;
  }
}

@media (min-width: 1600px) {
  .integration-area .image__4 img {
    width: initial;
  }
}

.integration-area .image__5 {
  position: relative;
}

.integration-area .image__5 img {
  position: absolute;
  top: 38px;
}

@media (min-width: 320px) {
  .integration-area .image__5 img {
    left: 68%;
    top: -143px;
    width: 30%;
  }
}

@media (min-width: 480px) {
  .integration-area .image__5 img {
    top: -182px;
    left: 76%;
  }
}

@media (min-width: 768px) {
  .integration-area .image__5 img {
    top: -226px;
    left: 64%;
    width: 40%;
  }
}

@media (min-width: 992px) {
  .integration-area .image__5 img {
    top: -253px;
    left: 70%;
    width: 35%;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__5 img {
    top: 38px;
    left: 398px;
    width: 50%;
  }
}

@media (min-width: 1600px) {
  .integration-area .image__5 img {
    left: 350px;
    width: initial;
  }
}

.integration-area .image__6 {
  position: relative;
}

.integration-area .image__6 img {
  position: absolute;
}

@media (min-width: 320px) {
  .integration-area .image__6 img {
    left: 38%;
    top: -63px;
    width: 30%;
  }
}

@media (min-width: 480px) {
  .integration-area .image__6 img {
    left: 270px;
    top: -95px;
  }
}

@media (min-width: 768px) {
  .integration-area .image__6 img {
    left: 255px;
    top: -131px;
    width: 40%;
  }
}

@media (min-width: 992px) {
  .integration-area .image__6 img {
    left: 331px;
    top: -131px;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__6 img {
    left: 135px;
    top: 166px;
    width: 50%;
  }
}

@media (min-width: 1366px) {
  .integration-area .image__6 img {
    width: initial;
  }
}

.integration-area .image__7 {
  position: relative;
}

.integration-area .image__7 img {
  position: absolute;
  left: -50px;
  top: 105px;
}

@media (min-width: 320px) {
  .integration-area .image__7 img {
    left: 0%;
    top: -108px;
    width: 30%;
  }
}

@media (min-width: 480px) {
  .integration-area .image__7 img {
    left: 82px;
    top: -100px;
  }
}

@media (min-width: 768px) {
  .integration-area .image__7 img {
    left: -17px;
    top: -172px;
    width: 40%;
  }
}

@media (min-width: 992px) {
  .integration-area .image__7 img {
    left: 0;
    top: -153px;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__7 img {
    left: -50px;
    top: 105px;
    width: 50%;
  }
}

@media (min-width: 1366px) {
  .integration-area .image__7 img {
    width: initial;
  }
}

.integration-area .image__8 {
  position: relative;
}

.integration-area .image__8 img {
  position: absolute;
}

@media (min-width: 320px) {
  .integration-area .image__8 img {
    left: -6%;
    top: -196px;
    width: 30%;
  }
}

@media (min-width: 480px) {
  .integration-area .image__8 img {
    top: -274px;
    left: 5%;
  }
}

@media (min-width: 576px) {
  .integration-area .image__8 img {
    top: -268px;
    left: 2%;
  }
}

@media (min-width: 768px) {
  .integration-area .image__8 img {
    left: -12%;
    top: -398px;
    width: 40%;
  }
}

@media (min-width: 992px) {
  .integration-area .image__8 img {
    left: -7%;
    top: -430px;
    width: 35%;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__8 img {
    top: -98px;
    left: -103px;
    width: 50%;
  }
}

@media (min-width: 1366px) {
  .integration-area .image__8 img {
    left: -126px;
    width: initial;
  }
}

.integration-area .image__9 {
  position: relative;
}

.integration-area .image__9 img {
  position: absolute;
  left: 182px;
  top: -61px;
}

@media (min-width: 320px) {
  .integration-area .image__9 img {
    left: 32%;
    top: -190px;
    width: 30%;
  }
}

@media (min-width: 480px) {
  .integration-area .image__9 img {
    left: 186px;
    top: -255px;
  }
}

@media (min-width: 768px) {
  .integration-area .image__9 img {
    left: 236px;
    top: -352px;
    width: 40%;
  }
}

@media (min-width: 992px) {
  .integration-area .image__9 img {
    left: 334px;
    top: -470px;
  }
}

@media (min-width: 1200px) {
  .integration-area .image__9 img {
    left: 182px;
    top: -61px;
    width: 50%;
  }
}

@media (min-width: 1366px) {
  .integration-area .image__9 img {
    width: initial;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-2 Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
@media (min-width: 320px) {
  .content-area-3 {
    padding-top: 60px;
  }
}

@media (min-width: 480px) {
  .content-area-3 {
    padding-top: 87px;
  }
}

@media (min-width: 768px) {
  .content-area-3 {
    padding-top: 107px;
  }
}

@media (min-width: 992px) {
  .content-area-3 {
    padding-top: 160px;
  }
}

.content-area-3 .content {
  margin-right: 0px;
  padding-top: 40px;
}

@media (min-width: 768px) {
  .content-area-3 .content {
    margin-right: 0px;
  }
}

@media (min-width: 1200px) {
  .content-area-3 .content {
    margin-right: 80px;
    padding-top: 0;
  }
}

@media (min-width: 1366px) {
  .content-area-3 .content {
    margin-right: 80px;
  }
}

@media (min-width: 1600px) {
  .content-area-3 .content {
    margin-right: 170px;
  }
}

.content-area-3 .content h2 {
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 47px;
}

@media (min-width: 576px) {
  .content-area-3 .content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.content-area-3 .content p {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 30px;
}

.content-area-3 .content-area-3-image-group {
  position: relative;
}

@media (min-width: 320px) {
  .content-area-3 .content-area-3-image-group {
    text-align: center;
  }
}

@media (min-width: 480px) {
  .content-area-3 .content-area-3-image-group {
    text-align: end;
    margin-right: 50px;
  }
}

.content-area-3 .content-area-3-image-group .image-1 img {
  box-shadow: 0 34px 41px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.content-area-3 .content-area-3-image-group .image-2 {
  display: none;
}

@media (min-width: 480px) {
  .content-area-3 .content-area-3-image-group .image-2 {
    display: block;
    position: absolute;
    top: -5%;
    right: 4%;
    z-index: -1;
  }
}

.content-area-3 .content-area-3-image-group .image-3 {
  position: absolute;
  top: 56%;
  left: 0%;
}

@media (min-width: 480px) {
  .content-area-3 .content-area-3-image-group .image-3 {
    left: -4%;
  }
}

@media (min-width: 576px) {
  .content-area-3 .content-area-3-image-group .image-3 {
    left: 4%;
  }
}

@media (min-width: 768px) {
  .content-area-3 .content-area-3-image-group .image-3 {
    left: -12%;
  }
}

@media (min-width: 992px) {
  .content-area-3 .content-area-3-image-group .image-3 {
    left: -10%;
  }
}

@media (min-width: 1200px) {
  .content-area-3 .content-area-3-image-group .image-3 {
    left: 8%;
  }
}

@media (min-width: 1366px) {
  .content-area-3 .content-area-3-image-group .image-3 {
    left: 10%;
  }
}

@media (min-width: 1600px) {
  .content-area-3 .content-area-3-image-group .image-3 {
    left: 25%;
  }
}

.content-area-3 .content-area-3-image-group .image-3 img {
  box-shadow: -15px 24px 78px rgba(111, 118, 138, 0.16);
  border-radius: 5px;
}

@media (min-width: 320px) {
  .content-area-3 .content-area-3-image-group .image-3 img {
    display: none;
  }
}

@media (min-width: 480px) {
  .content-area-3 .content-area-3-image-group .image-3 img {
    transform: scale(0.8);
    display: block;
  }
}

@media (min-width: 768px) {
  .content-area-3 .content-area-3-image-group .image-3 img {
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .content-area-3 .content-area-3-image-group .image-3 img {
    transform: scale(0.8);
  }
}

@media (min-width: 1200px) {
  .content-area-3 .content-area-3-image-group .image-3 img {
    transform: scale(1);
  }
}

.content-area-3 .content-area-3-image-group .image-4 {
  position: absolute;
}

@media (min-width: 320px) {
  .content-area-3 .content-area-3-image-group .image-4 {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 480px) {
  .content-area-3 .content-area-3-image-group .image-4 {
    top: 56%;
    right: -10%;
    left: inherit;
    transform: inherit;
  }
}

.content-area-3 .content-area-3-image-group .image-4 img {
  box-shadow: -15px 24px 78px rgba(111, 118, 138, 0.16);
  border-radius: 10px;
}

@media (min-width: 992px) {
  .content-area-3 .content-area-3-image-group .image-4 img {
    transform: scale(0.8);
  }
}

@media (min-width: 1200px) {
  .content-area-3 .content-area-3-image-group .image-4 img {
    transform: scale(1);
  }
}

.content-area-3 .content-area-3-image-group .image-5 {
  position: absolute;
}

@media (min-width: 320px) {
  .content-area-3 .content-area-3-image-group .image-5 {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 480px) {
  .content-area-3 .content-area-3-image-group .image-5 {
    right: -10%;
    top: 72%;
    left: inherit;
    transform: inherit;
  }
}

.content-area-3 .content-area-3-image-group .image-5 img {
  box-shadow: -15px 24px 78px rgba(111, 118, 138, 0.16);
  border-radius: 10px;
}

@media (min-width: 992px) {
  .content-area-3 .content-area-3-image-group .image-5 img {
    transform: scale(0.8);
  }
}

@media (min-width: 1200px) {
  .content-area-3 .content-area-3-image-group .image-5 img {
    transform: scale(1);
  }
}

@media (min-width: 320px) {
  .content-area-4 {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (min-width: 480px) {
  .content-area-4 {
    padding-top: 87px;
    padding-bottom: 80px;
  }
}

@media (min-width: 768px) {
  .content-area-4 {
    padding-top: 107px;
    padding-bottom: 100px;
  }
}

@media (min-width: 992px) {
  .content-area-4 {
    padding-top: 160px;
    padding-bottom: 130px;
  }
}

.content-area-4 .content-area-4-image-group {
  position: relative;
}

.content-area-4 .content-area-4-image-group .image-1 img {
  box-shadow: 32px 51px 84px rgba(111, 118, 138, 0.16);
  border-radius: 30px;
}

.content-area-4 .content-area-4-image-group .image-2 {
  display: none;
}

@media (min-width: 480px) {
  .content-area-4 .content-area-4-image-group .image-2 {
    display: block;
    position: absolute;
    top: -5%;
    left: 14%;
    z-index: -1;
  }
}

@media (min-width: 576px) {
  .content-area-4 .content-area-4-image-group .image-2 {
    left: 18%;
  }
}

@media (min-width: 768px) {
  .content-area-4 .content-area-4-image-group .image-2 {
    left: 14%;
  }
}

@media (min-width: 992px) {
  .content-area-4 .content-area-4-image-group .image-2 {
    left: 14%;
  }
}

@media (min-width: 1200px) {
  .content-area-4 .content-area-4-image-group .image-2 {
    left: 20%;
  }
}

@media (min-width: 1600px) {
  .content-area-4 .content-area-4-image-group .image-2 {
    left: 24%;
  }
}

.content-area-4 .content-area-4-image-group .image-3 {
  position: absolute;
  top: 50%;
  left: 5%;
}

@media (min-width: 1200px) {
  .content-area-4 .content-area-4-image-group .image-3 {
    left: 5%;
  }
}

@media (min-width: 1600px) {
  .content-area-4 .content-area-4-image-group .image-3 {
    left: 13%;
  }
}

.content-area-4 .content-area-4-image-group .image-3 img {
  box-shadow: -15px 24px 78px rgba(111, 118, 138, 0.16);
  border-radius: 5px;
}

@media (min-width: 320px) {
  .content-area-4 .content-area-4-image-group .image-3 img {
    display: none;
  }
}

@media (min-width: 480px) {
  .content-area-4 .content-area-4-image-group .image-3 img {
    display: block;
  }
}

.content-area-4 .content-area-4-image-group .image-4 {
  position: absolute;
}

@media (min-width: 320px) {
  .content-area-4 .content-area-4-image-group .image-4 {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 480px) {
  .content-area-4 .content-area-4-image-group .image-4 {
    top: 50%;
    right: 7%;
    left: inherit;
    transform: inherit;
  }
}

@media (min-width: 992px) {
  .content-area-4 .content-area-4-image-group .image-4 {
    right: 7%;
  }
}

@media (min-width: 1200px) {
  .content-area-4 .content-area-4-image-group .image-4 {
    right: 22%;
  }
}

.content-area-4 .content-area-4-image-group .image-4 img {
  box-shadow: -15px 24px 78px rgba(111, 118, 138, 0.16);
  border-radius: 10px;
}

.content-area-4 .content {
  padding-top: 40px;
}

@media (min-width: 1200px) {
  .content-area-4 .content {
    margin-right: 70px;
    padding-top: 0px;
  }
}

@media (min-width: 1366px) {
  .content-area-4 .content {
    margin-right: 70px;
  }
}

@media (min-width: 1600px) {
  .content-area-4 .content {
    margin-right: 160px;
  }
}

.content-area-4 .content h2 {
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 47px;
}

@media (min-width: 576px) {
  .content-area-4 .content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.content-area-4 .content p {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.content-area-4 .content .section__heading {
  margin-bottom: 45px;
}

.content-area-4 .widgets-list {
  margin-bottom: -22px;
}

.content-area-4 .widgets-list .btn {
  color: #fff;
  width: 195px;
  height: 65px;
  border-radius: 6px;
  background-color: #416ff4;
  margin-top: 35px;
}

.content-area-4 .widget {
  margin-bottom: 22px;
}

.content-area-4 .widget .widget-icon i {
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-color: #2bd67b;
  border-radius: 50%;
  font-size: 10px;
  margin-right: 12px;
}

.content-area-4 .widget p {
  color: #182748;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: 25px;
}

@media (min-width: 768px) {
  .content-area-4 .widget p {
    font-size: 17px;
  }
}

@media (min-width: 1200px) {
  .content-area-4 .widget p {
    font-size: 18px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-3 Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l3-1 {
  padding-top: 100px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .content-area-l3-1 {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .content-area-l3-1 {
    padding-top: 150px;
    padding-bottom: 130px;
  }
}

.content-box-l3-1 {
  margin-top: 45px;
}

@media (min-width: 768px) {
  .content-box-l3-1 {
    padding-right: 90px;
  }
}

@media (min-width: 992px) {
  .content-box-l3-1 {
    margin-top: 0;
    padding-right: 0px;
  }
}

@media (min-width: 1200px) {
  .content-box-l3-1 {
    padding-right: 45px;
  }
}

@media (min-width: 1600px) {
  .content-box-l3-1 {
    padding-right: 65px;
  }
}

.content-box-l3-1 i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  border-radius: 20px;
  background-color: #416ff4;
  margin-bottom: 30px;
}

.content-box-l3-1 .btn {
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  min-width: 85px;
  letter-spacing: -0.53px;
  line-height: normal;
  height: 22px;
  border-radius: 0;
  border-bottom: 2px solid #416ff4;
  padding: 0px 0px 15px 0px !important;
  margin-top: 40px;
}

.content-l3-1-image-group {
  position: relative;
}

.content-l3-1-image-group .image-1 img {
  width: 100%;
}

.content-l3-1-image-group .image-2 {
  position: absolute;
  top: -38px;
  left: -36px;
  z-index: -1;
}

.content-l3-1-image-group .image-3 {
  position: absolute;
  top: 160px;
  right: -42px;
  z-index: -1;
  display: none;
}

@media (min-width: 576px) {
  .content-l3-1-image-group .image-3 {
    display: block;
  }
}

.content-l3-1-image-group .image-4 {
  position: absolute;
  top: -58px;
  right: -70px;
  z-index: -1;
}

.video-conference-people {
  margin-top: 25px;
}

.video-conference-people img {
  width: 100%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-3 Content-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l3-2 {
  padding-bottom: 60px;
  position: relative;
}

@media (min-width: 768px) {
  .content-area-l3-2 {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .content-area-l3-2 {
    padding-bottom: 0;
  }
}

.content-area-l3-2:after {
  position: absolute;
  content: '';
  background: #416ff4;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index: -1;
}

@media (min-width: 992px) {
  .content-area-l3-2:after {
    width: 75%;
    border-radius: 80px 0px 0px 0px;
  }
}

.content-area-l3-2--image img {
  padding: 60px 0px 40px 0px;
}

@media (min-width: 768px) {
  .content-area-l3-2--image img {
    padding: 80px 0px 50px 0px;
  }
}

@media (min-width: 992px) {
  .content-area-l3-2--image img {
    padding: 100px 0px;
  }
}

@media (min-width: 768px) {
  .content-l3-2-content {
    padding-right: 45px;
  }
}

@media (min-width: 992px) {
  .content-l3-2-content {
    padding-right: 0px;
  }
}

@media (min-width: 1200px) {
  .content-l3-2-content {
    padding-right: 45px;
  }
}

@media (min-width: 1366px) {
  .content-l3-2-content {
    padding-right: 45px;
  }
}

@media (min-width: 1600px) {
  .content-l3-2-content {
    padding-right: 140px;
  }
}

.content-l3-2-content h2 {
  color: #fff;
}

.content-l3-2-content p {
  color: #fff;
}

.content-l3-2-content--list {
  margin-top: 45px;
}

.content-l3-2-content--list ul {
  margin-bottom: -20px;
}

.content-l3-2-content--list ul li {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: 25px;
  margin-bottom: 20px;
}

.content-l3-2-content--list ul li i {
  font-size: 10px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  margin-right: 15px;
  border-radius: 50%;
  color: #416ff4;
  background-color: #2bd67b;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-3 Content-3
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l3-3 {
  padding-top: 60px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .content-area-l3-3 {
    padding-top: 80px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .content-area-l3-3 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

.content-box-l3-3 {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .content-box-l3-3 {
    padding-top: 45px;
  }
}

@media (min-width: 992px) {
  .content-box-l3-3 {
    padding-top: 0;
  }
}

.content-box-l3-3 i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  border-radius: 20px;
  background-color: #416ff4;
  margin-bottom: 30px;
}

.content-l3-3-image-group {
  position: relative;
}

.content-l3-3-image-group img {
  transform: scale(0.8);
}

@media (min-width: 480px) {
  .content-l3-3-image-group img {
    transform: scale(1);
  }
}

.content-l3-3-image-group .image-1 img {
  transform: scale(1);
}

.content-l3-3-image-group .image-2 {
  position: absolute;
  top: 34%;
  left: 51%;
  transform: translate(-50%, -50%);
}

.content-l3-3-image-group .image-3 {
  position: absolute;
  top: 14%;
  left: 9%;
}

@media (min-width: 480px) {
  .content-l3-3-image-group .image-3 {
    top: 26%;
  }
}

.content-l3-3-image-group .image-4 {
  position: absolute;
  bottom: 4%;
  left: 22%;
}

@media (min-width: 480px) {
  .content-l3-3-image-group .image-4 {
    bottom: 11%;
  }
}

.content-l3-3-image-group .image-5 {
  position: absolute;
  bottom: 34%;
  right: 23%;
}

.content-l3-3-image-group .image-6 {
  position: absolute;
  top: 3%;
  right: 4%;
}

@media (min-width: 480px) {
  .content-l3-3-image-group .image-6 {
    top: 10%;
  }
}

.content-l3-3-image-group .image-7 {
  position: absolute;
  top: -17%;
  right: 30%;
}

@media (min-width: 480px) {
  .content-l3-3-image-group .image-7 {
    top: -3%;
    right: 35%;
  }
}

.content-l3-3-image-group .image-8 {
  position: absolute;
  top: 0%;
  left: 25%;
}

.content-l3-3-image-group .image-9 {
  position: absolute;
  bottom: 40%;
  left: 33%;
}

.content-l3-3-image-group .image-10 {
  position: absolute;
  bottom: 36%;
  right: 40%;
}

.content-l3-3-image-group .image-11 {
  position: absolute;
  top: -7%;
  right: 20%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-4 Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l4 {
  padding-top: 40px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .content-area-l4 {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .content-area-l4 {
    padding-top: 110px;
    padding-bottom: 190px;
  }
}

.content-area-l4 .content-l4-1--content {
  padding-top: 110px;
}

@media (min-width: 768px) {
  .content-area-l4 .content-l4-1--content {
    padding-right: 0px;
  }
}

@media (min-width: 992px) {
  .content-area-l4 .content-l4-1--content {
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .content-area-l4 .content-l4-1--content {
    padding-right: 15px;
  }
}

@media (min-width: 1600px) {
  .content-area-l4 .content-l4-1--content {
    padding-right: 105px;
  }
}

.content-area-l4 .content-l4-1--content h2 {
  margin-bottom: 30px;
}

.content-area-l4 .content-l4-1--content .content-l4-p2 {
  margin-top: 35px;
}

.content-area-l4 .content-l4-1--content .btn {
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  min-width: 85px;
  letter-spacing: -0.53px;
  line-height: normal;
  height: 22px;
  border-radius: 0;
  border-bottom: 2px solid #416ff4;
  padding: 0px 0px 15px 0px !important;
  margin-top: 55px;
}

.content-l4-1-image-group {
  position: relative;
  text-align: center;
}

.content-l4-1-image-group .image-1 img {
  width: 100%;
}

@media (min-width: 480px) {
  .content-l4-1-image-group .image-1 img {
    width: 70%;
  }
}

@media (min-width: 576px) {
  .content-l4-1-image-group .image-1 img {
    width: 100%;
  }
}

.content-l4-1-image-group .image-2 {
  position: absolute;
  bottom: -73px;
  left: 00px;
  z-index: -1;
}

@media (min-width: 768px) {
  .content-l4-1-image-group .image-2 {
    left: -20px;
  }
}

.content-l4-1-image-group .image-2 img {
  width: 70%;
}

@media (min-width: 768px) {
  .content-l4-1-image-group .image-2 img {
    width: initial;
  }
}

.content-l4-1-image-group .image-3 {
  position: absolute;
  bottom: 110px;
  right: 0px;
}

@media (min-width: 768px) {
  .content-l4-1-image-group .image-3 {
    right: -100px;
  }
}

.content-l4-1-image-group .image-3 img {
  box-shadow: 4px 28px 62px rgba(152, 152, 152, 0.16);
  border-radius: 10px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-4 Video Content
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.video-content-area-l4 {
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .video-content-area-l4 {
    padding-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .video-content-area-l4 {
    padding-bottom: 105px;
  }
}

@media (min-width: 768px) {
  .video-content-area-l4 h2 {
    padding: 0px 48px;
  }
}

@media (min-width: 992px) {
  .video-content-area-l4 h2 {
    padding: 0px 48px;
  }
}

.video-l4-area {
  position: relative;
  z-index: 1;
  border-radius: 45px;
  padding: 85px 0px 20px 0px;
  background-position: center !important;
  margin-top: 30px;
}

@media (min-width: 480px) {
  .video-l4-area {
    padding: 145px 0px 80px 0px;
  }
}

@media (min-width: 768px) {
  .video-l4-area {
    margin-top: 40px;
    padding: 164px 0px 100px 0px;
  }
}

@media (min-width: 992px) {
  .video-l4-area {
    background-position: inherit !important;
    padding: 252px 0px 190px 0px;
    margin-top: 55px;
  }
}

.video-l4-area:after {
  position: absolute;
  content: "";
  background: #101C3D;
  width: 100%;
  height: 100%;
  border-radius: 45px;
  left: 0;
  top: 0;
  opacity: 0.09;
  z-index: -1;
}

.video-l4-area h6 {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: normal;
  margin-top: 33px;
}

.video-l4-area .video-icon i {
  width: 82px;
  height: 82px;
  color: #fff;
  background-color: #416ff4;
  border-radius: 50%;
  text-align: center;
  line-height: 82px;
  position: relative;
  z-index: 1;
}

.video-l4-area .video-icon i:after {
  position: absolute;
  content: "";
  background: #416ff4;
  width: 120px;
  height: 120px;
  left: 50%;
  top: 50%;
  opacity: .3;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-5 Video Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l5-1 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .content-area-l5-1 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .content-area-l5-1 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

.content-area-l5-1 .section__heading h2 {
  color: #fff;
}

.content-area-l5-1 .section__heading p {
  color: #687497;
}

.content-area-l5-1 .content {
  margin-top: 45px;
}

@media (min-width: 768px) {
  .content-area-l5-1 .content {
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .content-area-l5-1 .content {
    padding-right: 0px;
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .content-area-l5-1 .content {
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .content-area-l5-1 .content {
    padding-right: 25px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-5  Content-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l5-2 {
  padding-top: 45px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .content-area-l5-2 {
    padding-top: 65px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .content-area-l5-2 {
    padding-top: 115px;
    padding-bottom: 110px;
  }
}

.content-area-l5-2 .section__heading {
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .content-area-l5-2 .section__heading {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .content-area-l5-2 .section__heading {
    margin-bottom: 75px;
  }
}

.content-area-l5-2 .section__heading h2 {
  color: #fff;
}

.content-area-l5-2 .section__heading p {
  color: #687497;
}

.dashboard-feature-items {
  margin-bottom: -30px;
  margin-top: 60px;
}

@media (min-width: 768px) {
  .dashboard-feature-items {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .dashboard-feature-items {
    margin-top: 115px;
  }
}

.dashboard-feature-items .col-lg-4 {
  margin-bottom: 30px;
}

.dashboard-feature-items__card i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 8px;
  background-color: #1D2E5E;
  color: #416ff4;
  font-size: 18px;
  margin-right: 30px;
}

@media (min-width: 576px) {
  .dashboard-feature-items__card i {
    margin-right: 15px;
  }
}

@media (min-width: 768px) {
  .dashboard-feature-items__card i {
    margin-right: 30px;
  }
}

.dashboard-feature-items__card h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
}

@media (min-width: 768px) {
  .dashboard-feature-items__card .content {
    padding-right: 41px;
  }
}

@media (min-width: 992px) {
  .dashboard-feature-items__card .content {
    padding-right: 0px;
  }
}

@media (min-width: 1200px) {
  .dashboard-feature-items__card .content {
    padding-right: 41px;
  }
}

@media (min-width: 1600px) {
  .dashboard-feature-items__card .content {
    padding-right: 102px;
  }
}

.dashboard-feature-items__card .content p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-5  Content-3
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l5-3 {
  padding-top: 60px;
  padding-bottom: 45px;
}

@media (min-width: 768px) {
  .content-area-l5-3 {
    padding-top: 80px;
    padding-bottom: 65px;
  }
}

@media (min-width: 992px) {
  .content-area-l5-3 {
    padding-top: 130px;
    padding-bottom: 115px;
  }
}

.content-area-l5-3 .section__heading {
  margin-bottom: 55px;
}

.content-area-l5-3 .section__heading h2 {
  color: #fff;
}

.content-area-l5-3 .content-box {
  padding-top: 45px;
}

@media (min-width: 992px) {
  .content-area-l5-3 .content-box {
    padding-top: 0;
  }
}

@media (min-width: 1600px) {
  .content-area-l5-3 .content-box {
    padding-right: 75px;
  }
}

.content-area-l5-3 .content-box .hero-area-content-l5__btn {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .content-area-l5-3 .content-box .hero-area-content-l5__btn {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .content-area-l5-3 .content-box .hero-area-content-l5__btn {
    margin-top: 85px;
  }
}

.content-l5-3-feature {
  margin-bottom: 30px;
}

.content-l5-3-feature .icon-box i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 8px;
  background-color: #1D2E5E;
  color: #416ff4;
  font-size: 18px;
  margin-right: 30px;
}

.content-l5-3-feature .content h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
}

.content-l5-3-feature .content p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-6 Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l6-1 {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .content-area-l6-1 {
    padding-top: 105px;
  }
}

@media (min-width: 992px) {
  .content-area-l6-1 {
    padding-top: 150px;
  }
}

.content-area-l6-1 .section__heading-2 {
  margin-top: 45px;
}

@media (min-width: 480px) {
  .content-area-l6-1 .section__heading-2 {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .content-area-l6-1 .section__heading-2 {
    margin-top: 0px;
  }
}

.content-area-l6-1 .section__heading-2 h4 {
  color: #416ff4;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-transform: uppercase;
}

.content-area-l6-1 .section__heading-2 h2 {
  margin-top: 30px;
}

@media (min-width: 1600px) {
  .content-area-l6-1 .section__heading-2 h2 {
    padding-right: 30px;
  }
}

.content-area-l6-1 .section__heading-2 p {
  margin: 0 0px 0 0;
}

@media (min-width: 1200px) {
  .content-area-l6-1 .section__heading-2 p {
    margin: 0 66px 0 0;
  }
}

@media (min-width: 1600px) {
  .content-area-l6-1 .section__heading-2 p {
    margin: 0 156px 0 0;
  }
}

.content-area-l6-1 .content-area-image-group {
  position: relative;
  z-index: 1;
}

.content-area-l6-1 .content-area-image-group .image-1 {
  position: absolute;
  top: -28px;
  left: 50px;
}

.content-area-l6-1 .content-area-image-group .image-1 img {
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  display: none;
}

@media (min-width: 480px) {
  .content-area-l6-1 .content-area-image-group .image-1 img {
    display: block;
  }
}

.content-area-l6-1 .content-area-image-group .image-2 {
  position: absolute;
  right: -30px;
  bottom: -30px;
}

@media (min-width: 768px) {
  .content-area-l6-1 .content-area-image-group .image-2 {
    right: -7%;
    bottom: -20px;
  }
}

.content-area-l6-1 .content-area-image-group .image-2 img {
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  display: none;
}

@media (min-width: 576px) {
  .content-area-l6-1 .content-area-image-group .image-2 img {
    display: block;
  }
}

.content-area-l6-1 .content-area-image-group .image-3 img {
  width: 100%;
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
}

@media (min-width: 1200px) {
  .content-area-l6-1 .content-area-image-group .image-3 img {
    margin: 0px 30px 0px 110px;
  }
}

.content-area-l6-2 {
  padding-top: 90px;
  padding-bottom: 55px;
}

@media (min-width: 320px) {
  .content-area-l6-2 {
    padding-top: 50px;
  }
}

@media (min-width: 480px) {
  .content-area-l6-2 {
    padding-top: 90px;
  }
}

@media (min-width: 768px) {
  .content-area-l6-2 {
    padding-top: 108px;
    padding-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .content-area-l6-2 {
    padding-top: 190px;
    padding-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .content-area-l6-2 {
    padding-bottom: 185px;
  }
}

.content-area-l6-2 .section__heading-2 {
  margin-top: 50px;
}

@media (min-width: 576px) {
  .content-area-l6-2 .section__heading-2 {
    margin-top: 115px;
  }
}

@media (min-width: 992px) {
  .content-area-l6-2 .section__heading-2 {
    margin-top: 0;
  }
}

.content-area-l6-2 .section__heading-2 h4 {
  color: #416ff4;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-transform: uppercase;
}

.content-area-l6-2 .section__heading-2 h2 {
  margin-top: 30px;
  padding-right: 0px;
}

@media (min-width: 1200px) {
  .content-area-l6-2 .section__heading-2 h2 {
    padding-right: 15px;
  }
}

@media (min-width: 1366px) {
  .content-area-l6-2 .section__heading-2 h2 {
    padding-right: 15px;
  }
}

@media (min-width: 1600px) {
  .content-area-l6-2 .section__heading-2 h2 {
    padding-right: 105px;
  }
}

.content-area-l6-2 .section__heading-2 p {
  margin: 0 0px 0 0;
}

@media (min-width: 1200px) {
  .content-area-l6-2 .section__heading-2 p {
    margin: 0 72px 0 0;
  }
}

@media (min-width: 1366px) {
  .content-area-l6-2 .section__heading-2 p {
    margin: 0 45px 0 0;
  }
}

@media (min-width: 1600px) {
  .content-area-l6-2 .section__heading-2 p {
    margin: 0 165px 0 0;
  }
}

.content-area-l6-2 .content-area-image-group {
  position: relative;
}

.content-area-l6-2 .content-area-image-group .image-1 {
  position: absolute;
  top: -38px;
  right: 175px;
}

.content-area-l6-2 .content-area-image-group .image-1 img {
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  display: none;
}

@media (min-width: 480px) {
  .content-area-l6-2 .content-area-image-group .image-1 img {
    display: block;
  }
}

.content-area-l6-2 .content-area-image-group .image-2 {
  position: absolute;
  left: -30px;
  bottom: -55px;
}

@media (min-width: 768px) {
  .content-area-l6-2 .content-area-image-group .image-2 {
    left: -8%;
  }
}

.content-area-l6-2 .content-area-image-group .image-2 img {
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  display: none;
}

@media (min-width: 576px) {
  .content-area-l6-2 .content-area-image-group .image-2 img {
    display: block;
  }
}

.content-area-l6-2 .content-area-image-group .image-3 img {
  width: 100%;
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
}

@media (min-width: 1200px) {
  .content-area-l6-2 .content-area-image-group .image-3 img {
    width: 476px;
    margin: 0px 25px 0px 35px;
  }
}

.content-area-l6-3 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .content-area-l6-3 {
    padding-top: 80px;
    padding-bottom: 105px;
  }
}

@media (min-width: 992px) {
  .content-area-l6-3 {
    padding-top: 130px;
    padding-bottom: 160px;
  }
}

.content-area-l6-3 .dashboard-bg {
  position: relative;
  z-index: 2;
  border-radius: 16px;
  padding: 15px;
}

@media (min-width: 480px) {
  .content-area-l6-3 .dashboard-bg {
    padding: 30px 45px 45px 45px;
  }
}

@media (min-width: 992px) {
  .content-area-l6-3 .dashboard-bg {
    padding: 55px 50px 70px 50px;
  }
}

@media (min-width: 1200px) {
  .content-area-l6-3 .dashboard-bg {
    padding: 55px 80px 70px 80px;
  }
}

.content-area-l6-3 .dashboard-bg::before {
  position: absolute;
  content: "";
  background: #416ff4;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
  z-index: -1;
}

@media (min-width: 768px) {
  .content-area-l6-3 .dashboard-bg::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #082B91;
    opacity: 0.07;
    top: 30px;
    left: 30px;
    border-radius: 15px;
    z-index: -2;
  }
}

.content-area-l6-3 h2 {
  color: #fff;
  font-weight: 700;
  font-size: 36px;
  font-style: normal;
  letter-spacing: -1.89px;
  line-height: 48px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .content-area-l6-3 h2 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (min-width: 1200px) {
  .content-area-l6-3 h2 {
    font-size: 42px;
    line-height: 59px;
    padding-right: 0px;
  }
}

@media (min-width: 1366px) {
  .content-area-l6-3 h2 {
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .content-area-l6-3 h2 {
    padding-right: 150px;
  }
}

.content-area-l6-3 .content {
  margin-top: 23px;
  padding-right: 10px;
}

@media (min-width: 1200px) {
  .content-area-l6-3 .content {
    padding-right: 0px;
  }
}

@media (min-width: 1600px) {
  .content-area-l6-3 .content {
    padding-right: 110px;
  }
}

.content-area-l6-3 .content p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: 30px;
  margin-bottom: 30px;
}

.content-area-l6-3 .content-area__btn .btn {
  width: 180px;
  height: 60px;
  border-radius: 7px;
}

@media (min-width: 320px) {
  .content-area-l6-3 .content-area__btn .btn {
    margin-top: 5px;
  }
}

@media (min-width: 768px) {
  .content-area-l6-3 .content-area__btn .btn {
    margin-top: 30px;
  }
}

.content-area-l6-3 .content-area__btn .btn:hover {
  color: #416ff4 !important;
}

.content-area-l6-3 .content-area-image {
  margin-top: 45px;
}

@media (min-width: 992px) {
  .content-area-l6-3 .content-area-image {
    margin-top: 23px;
  }
}

.content-area-l6-3 .content-area-image img {
  width: 100%;
}

.integrated-area .section__heading-2 {
  margin-bottom: 90px;
}

.integrated-area .section__heading-2 h4 {
  color: #416ff4;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

.integrated-area .section__heading-2 h2 {
  font-size: 42px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.89px;
  line-height: normal;
  margin-top: 30px;
}

.integrated-area .integrated-area-image-group {
  position: relative;
  margin-top: -50px;
}

@media (min-width: 768px) {
  .integrated-area .integrated-area-image-group {
    margin-top: -40px;
  }
}

@media (min-width: 992px) {
  .integrated-area .integrated-area-image-group {
    margin-top: 0px;
  }
}

.integrated-area .integrated-area-image-group .image-1 img {
  width: 100%;
  transform: scale(1.6);
}

@media (min-width: 480px) {
  .integrated-area .integrated-area-image-group .image-1 img {
    transform: scale(1.1);
  }
}

@media (min-width: 768px) {
  .integrated-area .integrated-area-image-group .image-1 img {
    transform: scale(1);
  }
}

.integrated-area .integrated-area-image-group .image-2 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.integrated-area .integrated-area-image-group .image-3 {
  position: absolute;
  top: 0;
  left: -15%;
}

@media (min-width: 480px) {
  .integrated-area .integrated-area-image-group .image-3 {
    left: 10%;
  }
}

@media (min-width: 768px) {
  .integrated-area .integrated-area-image-group .image-3 {
    left: 25%;
  }
}

.integrated-area .integrated-area-image-group .image-4 {
  position: absolute;
  top: 0;
  right: -15%;
}

@media (min-width: 480px) {
  .integrated-area .integrated-area-image-group .image-4 {
    right: 10%;
  }
}

@media (min-width: 768px) {
  .integrated-area .integrated-area-image-group .image-4 {
    right: 25%;
  }
}

.integrated-area .integrated-area-image-group .image-5 {
  position: absolute;
  top: 10%;
  left: 7%;
}

@media (min-width: 480px) {
  .integrated-area .integrated-area-image-group .image-5 {
    top: 25%;
    left: 22%;
  }
}

@media (min-width: 768px) {
  .integrated-area .integrated-area-image-group .image-5 {
    top: 34%;
    left: 34%;
  }
}

.integrated-area .integrated-area-image-group .image-6 {
  position: absolute;
  top: 10%;
  right: 7%;
}

@media (min-width: 480px) {
  .integrated-area .integrated-area-image-group .image-6 {
    top: 25%;
    right: 22%;
  }
}

@media (min-width: 768px) {
  .integrated-area .integrated-area-image-group .image-6 {
    top: 34%;
    right: 34%;
  }
}

.integrated-area .integrated-area-image-group .image-7 {
  position: absolute;
  bottom: -70%;
  left: -12%;
}

@media (min-width: 480px) {
  .integrated-area .integrated-area-image-group .image-7 {
    bottom: -15%;
    left: 10%;
  }
}

@media (min-width: 768px) {
  .integrated-area .integrated-area-image-group .image-7 {
    bottom: 0;
    left: 18%;
  }
}

.integrated-area .integrated-area-image-group .image-8 {
  position: absolute;
  bottom: -70%;
  right: -12%;
}

@media (min-width: 480px) {
  .integrated-area .integrated-area-image-group .image-8 {
    bottom: -15%;
    right: 10%;
  }
}

@media (min-width: 768px) {
  .integrated-area .integrated-area-image-group .image-8 {
    bottom: 0;
    right: 18%;
  }
}

.integrated-area .integrated-area-image-group img {
  transform: scale(0.6);
}

@media (min-width: 768px) {
  .integrated-area .integrated-area-image-group img {
    transform: scale(0.8);
  }
}

@media (min-width: 992px) {
  .integrated-area .integrated-area-image-group img {
    transform: scale(1);
  }
}

.integrated-area .integrated-block-area {
  padding-top: 65px;
  padding-bottom: 60px;
  margin-bottom: -45px;
}

@media (min-width: 480px) {
  .integrated-area .integrated-block-area {
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  .integrated-area .integrated-block-area {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .integrated-area .integrated-block-area {
    padding-top: 100px;
    padding-bottom: 130px;
  }
}

.integrated-area .integrated-block-area .integrated-block {
  margin-bottom: 45px;
}

.integrated-area .integrated-block-area .integrated-block .icon img {
  margin-right: 30px;
}

.integrated-area .integrated-block-area .integrated-block .content h6 {
  color: #0D152E;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
  margin-bottom: 10px;
}

.integrated-area .integrated-block-area .integrated-block .content span {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

.integrated-area .integrated-block-area .integrated-block .content span a {
  color: #416ff4;
  text-decoration: none;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-7 Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l7-1 {
  padding-bottom: 48px;
}

@media (min-width: 768px) {
  .content-area-l7-1 {
    padding-bottom: 65px;
  }
}

@media (min-width: 992px) {
  .content-area-l7-1 {
    padding-bottom: 130px;
  }
}

.content-area-l7-1 .content-area-l7-1-image img {
  width: 100%;
}

@media (min-width: 480px) {
  .content-area-l7-1 .content-area-l7-1-image img {
    width: initial;
  }
}

.content-area-l7-1 .content {
  padding-top: 40px;
}

@media (min-width: 992px) {
  .content-area-l7-1 .content h2 {
    font-size: 46px !important;
  }
}

@media (min-width: 1200px) {
  .content-area-l7-1 .content h2 {
    font-size: 48px !important;
  }
}

@media (min-width: 768px) {
  .content-area-l7-1 .content {
    padding-top: 50px;
    padding-right: 0px;
  }
}

@media (min-width: 992px) {
  .content-area-l7-1 .content {
    padding-top: 0;
    padding-right: 0px;
  }
}

@media (min-width: 1200px) {
  .content-area-l7-1 .content {
    padding-right: 25px;
  }
}

@media (min-width: 1600px) {
  .content-area-l7-1 .content {
    padding-right: 110px;
  }
}

.content-area-l7-1 .widgets-list {
  margin-bottom: -22px;
}

.content-area-l7-1 .widgets-list .btn {
  color: #fff;
  min-width: 181px;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  margin-top: 35px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .content-area-l7-1 .widgets-list .btn {
    margin-top: 45px;
    margin-bottom: 50px;
  }
}

.content-area-l7-1 .widget {
  margin-bottom: 22px;
}

.content-area-l7-1 .widget .widget-icon i {
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-color: #2bd67b;
  border-radius: 50%;
  font-size: 10px;
  margin-right: 12px;
}

.content-area-l7-1 .widget p {
  color: #182748;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: 25px;
}

@media (min-width: 768px) {
  .content-area-l7-1 .widget p {
    font-size: 17px;
  }
}

@media (min-width: 1200px) {
  .content-area-l7-1 .widget p {
    font-size: 18px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-7 Content-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l7-2 {
  padding-bottom: 38px;
  text-align: center;
}

@media (min-width: 768px) {
  .content-area-l7-2 {
    text-align: left;
    padding-bottom: 17px;
  }
}

@media (min-width: 992px) {
  .content-area-l7-2 {
    padding-bottom: 130px;
  }
}

.content-area-l7-2 .section__heading {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .content-area-l7-2 .section__heading {
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .content-area-l7-2 .section__heading {
    margin-bottom: 70px;
  }
}

.content-l7-2-items .block-top {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .content-l7-2-items .block-top {
    margin-bottom: 40px;
    margin-right: 30px;
  }
}

@media (min-width: 992px) {
  .content-l7-2-items .block-top {
    margin-right: 0px;
    margin-bottom: 80px;
  }
}

.content-l7-2-items .number {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  box-shadow: 0 25px 17px rgba(111, 118, 138, 0.16);
  border-radius: 30px;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  display: inline-block;
  margin-bottom: 32px;
}

.content-l7-2-items span {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
}

.content-l7-2-items h4 {
  color: #0D152E;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
  margin-bottom: 22px;
}

.content-l7-2-items p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
}

@media (min-width: 992px) {
  .content-l7-2-items .content-l7-2-content-1 {
    text-align: right;
    padding-left: 0px;
  }
}

@media (min-width: 1600px) {
  .content-l7-2-items .content-l7-2-content-1 {
    padding-left: 40px;
  }
}

@media (min-width: 992px) {
  .content-l7-2-items .content-l7-2-content-2 {
    padding-left: 0px;
  }
}

@media (min-width: 1600px) {
  .content-l7-2-items .content-l7-2-content-2 {
    padding-right: 66px;
  }
}

.content-l7-2-items .content-l7-2-center-img {
  text-align: center;
  margin: 0px 0px 30px 0px;
}

@media (min-width: 768px) {
  .content-l7-2-items .content-l7-2-center-img {
    margin: 0px 0px 45px 0px;
  }
}

@media (min-width: 992px) {
  .content-l7-2-items .content-l7-2-center-img {
    margin: 0px;
  }
}

.content-l7-2-items .content-l7-2-center-img img {
  width: 100%;
}

@media (min-width: 480px) {
  .content-l7-2-items .content-l7-2-center-img img {
    width: initial;
  }
}

@media (min-width: 576px) {
  .content-l7-2-items .content-l7-2-center-img img {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .content-l7-2-items .content-l7-2-center-img img {
    width: initial;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing- integrate area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.integration-area-l7-1 {
  padding-top: 47px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .integration-area-l7-1 {
    padding-top: 67px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .integration-area-l7-1 {
    padding-top: 117px;
    padding-bottom: 130px;
  }
}

.integration-brand-area {
  margin: 0px -6px -20px -6px;
}

.integration-brand-area .brand-single {
  margin: 0px 6px 20px 6px;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  box-shadow: 10px 10px 30px rgba(111, 118, 138, 0.16);
  border-radius: 10px;
  background-color: #fff;
}

.integrate-l7-content {
  padding-top: 67px;
}

.integrate-l7-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 38px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-8 Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l8-1__image img {
  width: 100%;
}

@media (min-width: 768px) {
  .content-area-l8-1__image img {
    width: initial;
  }
}

@media (min-width: 992px) {
  .content-area-l8-1__image img {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .content-area-l8-1__image img {
    width: initial;
  }
}

.content--l8-1 {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .content--l8-1 {
    padding-right: 85px;
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .content--l8-1 {
    padding-right: 0px;
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .content--l8-1 {
    padding-right: 85px;
  }
}

.content--l8-1 h2 {
  color: #0D152E;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 50px;
}

@media (min-width: 576px) {
  .content--l8-1 h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.content--l8-1 p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 37px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-9 Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l9-1 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 576px) {
  .content-area-l9-1 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media (min-width: 768px) {
  .content-area-l9-1 {
    padding-top: 115px;
    padding-bottom: 115px;
  }
}

@media (min-width: 992px) {
  .content-area-l9-1 {
    padding-top: 115px;
  }
}

@media (min-width: 1200px) {
  .content-area-l9-1 {
    padding-top: 130px;
    padding-bottom: 165px;
  }
}

@media (min-width: 1366px) {
  .content-area-l9-1 {
    padding-top: 135px;
  }
}

@media (min-width: 1600px) {
  .content-area-l9-1 {
    padding-top: 160px;
  }
}

.content-area-l9-1--image {
  position: relative;
}

.content-area-l9-1--image img {
  width: 100%;
}

.content-area-l9-1--image:after {
  position: absolute;
  content: "";
  border-radius: 18px;
  border: 2px solid #F7B26D;
  height: 100%;
  width: 100%;
  top: -35px;
  left: 40px;
  z-index: -1;
  display: none;
}

@media (min-width: 576px) {
  .content-area-l9-1--image:after {
    display: block;
  }
}

.content-l9-1 {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .content-l9-1 {
    padding-top: 45px;
  }
}

@media (min-width: 992px) {
  .content-l9-1 {
    padding-top: 0;
    padding-right: 30px;
  }
}

@media (min-width: 1600px) {
  .content-l9-1 {
    padding-right: 120px;
  }
}

.content-l9-1__service {
  margin-top: 60px;
}

.content-l9-1__service ul {
  margin-bottom: -15px;
}

.content-l9-1__service ul li {
  margin-bottom: 15px;
}

.content-l9-1__service .icon i {
  font-size: 18px;
  color: #101C3D;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 8px 11px 19px rgba(164, 160, 160, 0.27);
  background-color: #fff;
  margin-right: 25px;
}

.content-l9-1__service .content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.content-l9-1__service .content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-top: 18px;
}

.content-l9-1__service .btn {
  width: 180px;
  height: 61px;
  border-radius: 30px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  margin-top: 45px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-9 Content-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l9-2 {
  padding-bottom: 47px;
}

@media (min-width: 768px) {
  .content-area-l9-2 {
    padding-bottom: 67px;
  }
}

@media (min-width: 992px) {
  .content-area-l9-2 {
    padding-bottom: 117px;
  }
}

.content-area-l9-2--image {
  position: relative;
}

.content-area-l9-2--image img {
  width: 100%;
}

.content-area-l9-2--image:after {
  position: absolute;
  content: "";
  border-radius: 18px;
  border: 2px solid #29CE76;
  height: 100%;
  width: 100%;
  top: -35px;
  left: 40px;
  z-index: -1;
  display: none;
}

@media (min-width: 576px) {
  .content-area-l9-2--image:after {
    display: block;
  }
}

@media (min-width: 992px) {
  .content-area-l9-2--image:after {
    left: -40px;
  }
}

@media (min-width: 1366px) {
  .content-area-l9-2--image:after {
    left: 40px;
  }
}

.content-l9-2 {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .content-l9-2 {
    padding-top: 45px;
  }
}

@media (min-width: 992px) {
  .content-l9-2 {
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .content-l9-2 {
    padding-right: 30px;
  }
}

@media (min-width: 1600px) {
  .content-l9-2 {
    padding-right: 120px;
  }
}

.content-l9-2__service {
  margin-top: 50px;
}

.content-l9-2__service .expertise-item {
  margin-bottom: -12px;
}

.content-l9-2__service .expertise-item .expertise-single {
  margin-bottom: 12px;
}

.content-l9-2__service .expertise-head h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 25px;
}

.content-l9-2__service .icon-area i {
  font-size: 8px;
  color: #fff;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 8px 11px 19px rgba(164, 160, 160, 0.27);
  background-color: #2bd67b;
  margin-right: 15px;
}

.content-l9-2__service .content h4 {
  color: #0D152E;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
  margin-bottom: 0;
}

.content-l9-2__work {
  margin-top: 50px;
}

.content-l9-2__work ul {
  margin: 0px -12px;
}

.content-l9-2__work ul li {
  margin: 0px 12px;
}

.content-l9-2__work ul li img {
  box-shadow: 0 19px 33px rgba(159, 159, 159, 0.25);
  background-color: #fff;
  padding: 15px;
  border-radius: 50%;
}

.content-l9-2__work h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Brand     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
@media (min-width: 320px) {
  .brand-area {
    margin: 0px -30px -30px -30px;
    padding-top: 60px;
  }
}

@media (min-width: 768px) {
  .brand-area {
    padding-top: 80px;
  }
}

@media (min-width: 1200px) {
  .brand-area {
    margin: 0px;
    padding-top: 85px;
  }
}

@media (min-width: 320px) {
  .brand-area .brand-area__single {
    margin: 0px 30px 30px 30px;
  }
}

@media (min-width: 1200px) {
  .brand-area .brand-area__single {
    margin: 0px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Brand Area -L5     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l5 {
  padding-bottom: 130px;
}

.brand-area-l5 .section__heading h2 {
  color: #fff;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .brand-area-l5 .section__heading h2 {
    margin-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .brand-area-l5 .section__heading h2 {
    margin-bottom: 105px;
  }
}

.brand-items-l5 {
  margin: 0px -30px -55px -30px;
}

.brand-items-l5 .single-item {
  margin: 0px 30px 55px 30px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing area landing-1    CSS
 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.pricing-area-l1-1 {
  margin-bottom: -45px;
}

@media (min-width: 320px) {
  .pricing-area-l1-1 {
    padding-top: 45px;
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .pricing-area-l1-1 {
    padding-top: 65px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .pricing-area-l1-1 {
    padding-top: 115px;
    padding-bottom: 130px;
  }
}

@media (min-width: 1200px) {
  .pricing-area-l1-1 {
    padding-top: 160px;
  }
}

.pricing-area-l1-1 .price-card {
  transition: all 0.4s ease-in-out;
  padding: 75px 30px;
  margin-bottom: 45px;
  border: 1px solid #eaeaea;
}

.pricing-area-l1-1 .price-card h4 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: normal;
}

.pricing-area-l1-1 .price-card p {
  color: #81838c;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.pricing-area-l1-1 .price-card h2 {
  color: #0D152E;
  font-size: 48px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: normal;
  padding-top: 35px;
}

.pricing-area-l1-1 .price-card .btn--price {
  min-width: 190px;
  height: 55px;
  color: #182748;
  border-radius: 6px;
  letter-spacing: -0.53px;
  border: 1px solid #e5e5e5;
  margin-top: 25px;
}

@media (min-width: 320px) {
  .pricing-area-l1-1 .price-card {
    box-shadow: 3px 67px 99px rgba(111, 118, 138, 0.16);
    border-radius: 14px;
  }
  .pricing-area-l1-1 .price-card .btn--price {
    background: #416ff4;
    color: #fff;
  }
}

@media (min-width: 992px) {
  .pricing-area-l1-1 .price-card {
    box-shadow: none;
  }
  .pricing-area-l1-1 .price-card .btn--price {
    background: transparent;
    border: 1px solid #e5e5e5;
    color: #182748;
  }
  .pricing-area-l1-1 .price-card:hover {
    background: #fff;
    box-shadow: 3px 67px 99px rgba(111, 118, 138, 0.16);
    border-radius: 14px;
  }
  .pricing-area-l1-1 .price-card:hover .btn--price {
    background: #416ff4;
    color: #fff;
  }
}

.pricing-area-l1-1 .toggle-btn {
  padding: 35px 0px 85px 0px;
}

.pricing-area-l1-1 .toggle-btn .btn-toggle {
  margin: 0px 15px;
}

.pricing-area-l1-1 .toggle-btn h4 {
  color: #182748;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.pricing-area-l1-1 .toggle-btn h3 {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.3px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  border-radius: 15px;
  background-color: #2bd67b;
  padding: 3px 12px;
  margin-left: 15px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing switch l-1   CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.pricing-area-l1-1 [data-pricing-dynamic][data-value-active="yearly"] .dynamic-value:before {
  display: inline-block;
  content: attr(data-yearly);
}

.pricing-area-l1-1 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] {
  background: #416ff4;
}

.pricing-area-l1-1 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] span {
  left: calc(100% - 24px);
}

.pricing-area-l1-1 [data-pricing-dynamic][data-value-active="monthly"] .dynamic-value:before {
  display: inline-block;
  content: attr(data-monthly);
}

.pricing-area-l1-1 .dynamic-value:before {
  display: inline-block;
  content: attr(data-active);
}

.pricing-area-l1-1 .static-value:before {
  display: inline-block;
  content: attr(data-active);
}

.pricing-area-l1-1 .btn-toggle {
  width: 48px;
  height: 24px;
  border-radius: 17px;
  background-color: #eeeeee !important;
  position: relative;
}

.pricing-area-l1-1 .btn-toggle span {
  width: 16px;
  height: 16px;
  background-color: #416ff4;
  position: absolute;
  left: 5px;
  top: 4px;
  transition: 0.4s;
  border-radius: 0px;
  pointer-events: none;
}

.pricing-area-l1-1 .btn-toggle-2 {
  width: 70px;
  height: 30px;
  border-radius: 15px;
  background-color: #eeeeee;
  position: relative;
  z-index: 0;
}

.pricing-area-l1-1 .btn-toggle-2 .round {
  height: 30px;
  width: 30px;
  left: 0px;
  top: 0;
  background: #416ff4 !important;
  border-radius: 50%;
  display: block;
  z-index: -1;
}

.pricing-area-l1-1 .btn-toggle-2 span {
  display: none;
}

.pricing-area-l1-1 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle {
  background: #416ff4 !important;
}

.pricing-area-l1-1 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2 .round {
  background: #eeeeee !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing L-3     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.pricing-area-l3-1 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .pricing-area-l3-1 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .pricing-area-l3-1 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

.pricing-area-l3-1 .section__heading {
  margin-bottom: 60px;
}

@media (min-width: 576px) {
  .pricing-area-l3-1 .section__heading p {
    padding: 0px 10px;
  }
}

@media (min-width: 768px) {
  .pricing-area-l3-1 .section__heading p {
    padding: 0px 70px;
  }
}

@media (min-width: 992px) {
  .pricing-area-l3-1 .section__heading p {
    padding: 0px 100px;
  }
}

@media (min-width: 1200px) {
  .pricing-area-l3-1 .section__heading p {
    padding: 0px 70px;
  }
}

.pricing-area-l3-1 .section__heading i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  border-radius: 20px;
  background-color: #416ff4;
  margin-bottom: 25px;
}

.pricing-l3-1-items {
  margin-bottom: 0px;
}

.pricing-l3-1-items .col-lg-8 {
  margin-bottom: 25px;
}

.pricing-l3-1-items .col-lg-4 {
  margin-bottom: 25px;
}

.card--pricing-l3-1 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 0px;
  background-color: #fefffe;
  border: 1px solid #fefffe;
  padding: 32px 35px 38px 35px;
  transition: all 0.4s ease-in-out;
}

.card--pricing-l3-1:hover {
  box-shadow: 0 52px 54px rgba(0, 0, 0, 0.1);
}

.card--pricing-l3-1__head h6 {
  color: #A8ABB3;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}

.card--pricing-l3-1 .price-l3-border {
  height: 1px;
  border-radius: 6px;
  background-color: #EAECED;
  margin: 50px 0px 45px 0px;
}

.card--pricing-l3-1__price h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: normal;
  margin-top: 45px;
}

.card--pricing-l3-1__price span {
  color: #fefffe;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

.card--pricing-l3-1__price .price-l3-btn .btn {
  width: 182px;
  height: 45px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  padding: 0px 20px;
  line-height: normal;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 38px;
}

.card--pricing-l3-1__service ul {
  margin-bottom: -20px;
  display: inline-grid;
}

.card--pricing-l3-1__service ul li {
  color: #0D152E;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
  margin-bottom: 20px;
}

.card--pricing-l3-1__service ul li i {
  font-size: 10px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  margin-right: 15px;
  border-radius: 50%;
  color: #fff;
  background-color: #2bd67b;
}

.implemented {
  background-color: #b2282d;
  height: 26.875rem;
}

.implemented span {
  color: #fefffe;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

.testing {
  background-color: #595959;
  height: 26.875rem;
}

.testing span {
  color: #fefffe;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

.indevelopment {
  background-color: #767676;
  height: 26.875rem;
}

.indevelopment span {
  color: #fefffe;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

@media screen and (max-width: 950px) {
  .implemented {
    height: 30rem !important;
  }
  .testing {
    height: 30rem !important;
  }
  .indevelopment {
    height: 30rem !important;
  }
}

@media screen and (max-width: 850px) {
  .implemented {
    height: 30rem !important;
  }
  .testing {
    height: 30rem !important;
  }
  .indevelopment {
    height: 30rem !important;
  }
}

@media screen and (max-width: 770px) {
  .implemented {
    height: 30rem !important;
  }
  .testing {
    height: 30rem !important;
  }
  .indevelopment {
    height: 30rem !important;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing-area l8     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.priceing-area-l8-1 {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .priceing-area-l8-1 {
    padding-top: 40px;
  }
}

@media (min-width: 992px) {
  .priceing-area-l8-1 {
    padding-top: 115px;
  }
}

.priceing-area-l8-1 .section__heading h2 {
  margin-bottom: 30px;
}

.priceing-area-l8-1 [data-pricing-dynamic][data-value-active="yearly"] .dynamic-value:before {
  display: inline-block;
  content: attr(data-yearly);
}

.priceing-area-l8-1 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] {
  background: #416ff4;
}

.priceing-area-l8-1 [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] span {
  left: calc(100% - 24px);
}

.priceing-area-l8-1 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] {
  background: #416ff4;
}

.priceing-area-l8-1 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] span {
  left: calc(100% - 52%);
}

.priceing-area-l8-1 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:before {
  color: #000 !important;
}

.priceing-area-l8-1 [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:after {
  color: #fff !important;
}

.priceing-area-l8-1 [data-pricing-dynamic][data-value-active="monthly"] .dynamic-value:before {
  display: inline-block;
  content: attr(data-monthly);
}

.priceing-area-l8-1 .btn-toggle-2[data-pricing-trigger].active:before {
  color: #fff !important;
}

.priceing-area-l8-1 .btn-toggle-2[data-pricing-trigger].active:after {
  color: #000 !important;
}

.priceing-area-l8-1 .dynamic-value:before {
  display: inline-block;
  content: attr(data-active);
}

.priceing-area-l8-1 .static-value:before {
  display: inline-block;
  content: attr(data-active);
}

.priceing-area-l8-1 .btn-toggle {
  width: 48px;
  height: 24px;
  border-radius: 17px;
  background-color: #f8f8f8 !important;
  position: relative;
  margin: 0 15px;
}

.priceing-area-l8-1 .btn-toggle span {
  width: 16px;
  height: 16px;
  background-color: #416ff4;
  position: absolute;
  left: 5px;
  top: 4px;
  transition: 0.4s;
  border-radius: 500px;
  pointer-events: none;
}

.priceing-area-l8-1 .btn-toggle-8 {
  width: 265px;
  height: 42px;
  border-radius: 30px;
  background-color: #fff !important;
  position: relative;
  margin: 0 15px;
  z-index: 1;
}

.priceing-area-l8-1 .btn-toggle-8:before {
  position: absolute;
  content: "PER MONTHLY" !important;
  width: 50%;
  text-transform: uppercase;
  color: #fff;
  height: 34px;
  background: transparent;
  left: 6px;
  top: 4px;
  line-height: 34px;
  font-size: 12px;
  font-weight: 700 !important;
  letter-spacing: 0.5px;
}

.priceing-area-l8-1 .btn-toggle-8:after {
  position: absolute;
  content: "PER YEARLY" !important;
  width: 50%;
  font-size: 13px;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #0D152E;
  height: 34px;
  background: transparent;
  right: 4px;
  top: 4px;
  line-height: 34px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.priceing-area-l8-1 .btn-toggle-8 .round {
  width: 50%;
  height: 34px;
  top: 3px;
  left: 4px;
  background: #416ff4;
  display: block;
  z-index: -1;
}

.priceing-area-l8-1 .btn-toggle-8 span {
  display: none;
}

.priceing-area-l8-1 .btn-toggle[data-value-active="yearly"] {
  background-color: #416ff4 !important;
}

.priceing-area-l8-1 .btn-toggle[data-value-active="yearly"] span {
  left: calc(100% - 25px) !important;
}

.priceing-area-l8-1 .btn-toggle-square {
  height: 65px;
  display: inline-flex;
  position: relative;
  z-index: 1;
  padding: 5px;
  align-items: center;
}

.priceing-area-l8-1 .btn-toggle-square.bg-whisper-3:hover, .priceing-area-l8-1 .btn-toggle-square.bg-whisper-3:focus {
  background: #fff !important;
}

.priceing-area-l8-1 .btn-toggle-square span {
  min-width: 160px;
  min-height: 50px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.priceing-area-l8-1 .btn-toggle-square span:first-child {
  border-radius: 10px 0 0 10px;
}

.priceing-area-l8-1 .btn-toggle-square span:nth-child(2) {
  border-radius: 0px 10px 10px 0;
}

.priceing-area-l8-1 .btn-toggle-square span.active {
  color: #fff;
  background: #416ff4;
}

.priceing-area-l8-1 .popular-pricing:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 4px;
  background: #f46f7c;
  border-radius: 6px;
}

.priceing-area-l8-1 .popular-pricing.popular-pricing-2:before {
  height: 3px;
  top: -3px;
  background: red;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing area landing-8    CSS
 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.priceing_area_four .sec_title {
  margin-bottom: 50px;
}

.price_info_two .price_head {
  display: table;
  width: 100%;
}

.price_info_two .price_head .p_head {
  width: calc(100%/5);
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  padding: 31px 0;
}

.price_info_two .price_head .p_head h5 {
  color: #81838C;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

@media (min-width: 576px) {
  .price_info_two .price_head .p_head h5 {
    font-size: 16px;
  }
}

.price_info_two .price_head .p_head p {
  color: #182748;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.price_info_two .price_head .p_head h2 {
  color: #0D152E;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: normal;
  margin-top: 27px;
}

@media (min-width: 576px) {
  .price_info_two .price_head .p_head h2 {
    font-size: 34px;
  }
}

.ex_team_item .hover_content .n_hover_content ul,
.job_listing .listing_tab .list_item .joblisting_text ul,
.price_info_two .price_head .p_head h5,
.priceing_area_four .sec_title h2 {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .price_info_two {
    padding-top: 63px;
  }
}

@media (min-width: 992px) {
  .price_info_two .price_head .p_head:nth-child(9) {
    background: #101C3D;
    border-radius: 9px 9px 0px 0px;
  }
  .price_info_two .price_item:nth-child(6) {
    background: #101C3D;
  }
  .price_info_two .price_item:nth-child(6) a {
    color: #fff !important;
  }
  .price_info_two .price_item:nth-child(6) span {
    color: #fff !important;
  }
  .price_info_two .price_item:nth-child(6) i {
    color: #fff !important;
  }
  .p_head:nth-child(6) h2 {
    color: #fff !important;
  }
  .p_head:nth-child(6) p {
    color: #fff !important;
  }
}

.price_info_two .price_item {
  width: calc(100%/5);
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  cursor: pointer;
  padding: 15px 0;
}

.price_info_two .price_item span {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #222d39;
}

.pr_list:last-child .price_item:nth-child(4) {
  border-radius: 0px 0px 9px 9px;
}

.price_info_two .price_item:first-child {
  text-align: left;
}

.price_info_two .price_item span i {
  font-size: 15px;
  color: #182748;
}

.pr_list:last-child {
  border: none !important;
}

.p_head:nth-child(9) h5 {
  color: #fff !important;
  border-radius: 30px;
  background-color: #2bd67b;
  padding: 0px 11px;
}

@media (min-width: 576px) {
  .p_head:nth-child(9) h5 {
    display: inline-block;
    padding: 3px 20px;
  }
}

.price_info_two .price_item .pr_title {
  position: relative;
  display: inline-block;
  color: #182748;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.price_btn i {
  margin-left: 13px;
  background-color: #fff;
  color: #416ff4;
  border: 2px solid #416ff4;
  border-radius: 50%;
  padding: 4px;
  font-size: 12px;
}

@media (min-width: 992px) {
  .price_item.price-get-started-btn-3 i {
    background-color: #101C3D;
    color: #fff;
    border: 2px solid #fff;
  }
}

@media (min-width: 480px) {
  .pr_list.pricing-get-strated-btn {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .pr_list.pricing-get-strated-btn {
    display: inherit;
  }
}

.price_info_two .price_item .check {
  color: #182748;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.price_info_two .pr_list {
  display: table;
  width: 100%;
  border-bottom: 1px solid #eaeced;
}

.price_info_two a {
  text-decoration: none;
}

.price_info_two .price_btn {
  color: #416ff4;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

@media (min-width: 576px) {
  .price_info_two .price_btn {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .price_info_two .pr_list,
  .price_info_two .price_head .p_head.time {
    display: block;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .price_info_two .price_item:first-child {
    text-align: center;
    padding: 5px 0;
  }
}

@media (max-width: 1199px) {
  .price_info_two .price_item:first-child {
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .price_info_two .price_item {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .price_info_two .price_item:before {
    content: attr(data-title);
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 500;
    color: #fefffe;
  }
}

@media (max-width: 768px) {
  .price_info_two .price_item:first-child h5 {
    width: initial;
    border: 0;
    float: none;
    padding: 15px 0px;
  }
}

@media (max-width: 768px) {
  .price_info_two .price_item span {
    border-left: 1px dashed #ededed;
    display: block;
    float: right;
    font-size: 1.1rem;
    height: 100%;
    line-height: 3.1rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 50%;
  }
}

@media (max-width: 768px) and (min-width: 768px) {
  .pr_list.pricing-get-strated-btn {
    display: flex;
  }
}

@media (max-width: 768px) and (min-width: 992px) {
  .pr_list.pricing-get-strated-btn {
    display: initial;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
          Pricing area Inner 01
 <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.pricing-01-area {
  padding-top: 98px;
}

@media (min-width: 480px) {
  .pricing-01-area {
    padding-top: 115px;
  }
}

@media (min-width: 768px) {
  .pricing-01-area {
    padding-top: 135px;
  }
}

@media (min-width: 992px) {
  .pricing-01-area {
    padding-top: 160px;
  }
}

.pricing-02-area {
  padding-top: 98px;
  padding-bottom: 45px;
}

@media (min-width: 480px) {
  .pricing-02-area {
    padding-top: 115px;
  }
}

@media (min-width: 768px) {
  .pricing-02-area {
    padding-top: 135px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .pricing-02-area {
    padding-top: 160px;
    padding-bottom: 120px;
  }
}

.pricing-03-area {
  padding-top: 98px;
}

@media (min-width: 480px) {
  .pricing-03-area {
    padding-top: 115px;
  }
}

@media (min-width: 768px) {
  .pricing-03-area {
    padding-top: 135px;
  }
}

@media (min-width: 992px) {
  .pricing-03-area {
    padding-top: 160px;
  }
}

#pricing_mobile {
  display: none;
}

@media screen and (max-width: 770px) {
  #pricing_mobile {
    display: block;
  }
}

#pricing_desktop {
  display: none;
}

@media screen and (min-width: 770px) {
  #pricing_desktop {
    display: block;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer landing-1
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area {
  background: #101C3D;
}

.footer-area .footer-quick-link-area {
  margin-bottom: -45px;
}

@media (min-width: 320px) {
  .footer-area {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .footer-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .footer-area {
    padding-top: 100px;
    padding-bottom: 140px;
  }
}

@media (min-width: 320px) {
  .footer-area .join-us-content {
    margin-right: 0px;
  }
}

@media (min-width: 768px) {
  .footer-area .join-us-content {
    margin-right: 65px;
  }
}

.footer-area .join-us-content h2 {
  color: #fff;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
}

.footer-area .join-us-btn .btn {
  min-width: 180px;
  height: 60px;
  color: #fff;
  border-radius: 6px;
  background-color: #416ff4;
}

@media (min-width: 320px) {
  .footer-area .join-us-btn .btn {
    margin-top: 25px;
  }
}

@media (min-width: 768px) {
  .footer-area .join-us-btn .btn {
    margin-top: 0;
  }
}

.footer-area .border-footer {
  margin: 85px 0px 120px 0px;
  background-color: #fff;
  opacity: 0.1;
  height: 1px;
}

@media (min-width: 320px) {
  .footer-area .border-footer {
    margin: 40px 0px 40px 0px;
  }
}

@media (min-width: 768px) {
  .footer-area .border-footer {
    margin: 60px 0px 80px 0px;
  }
}

@media (min-width: 992px) {
  .footer-area .border-footer {
    margin: 85px 0px 120px 0px;
  }
}

.footer-area .footer-area-list-item h4 {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 320px) {
  .footer-area .footer-area-list-item h4 {
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .footer-area .footer-area-list-item h4 {
    margin-bottom: 40px;
  }
}

.footer-area .footer-area-list-item {
  margin-bottom: 45px;
}

.footer-area .footer-area-list-item ul {
  margin-bottom: -15px;
}

.footer-area .footer-area-list-item ul li {
  margin-bottom: 15px;
}

.footer-area .footer-area-list-item ul li a {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-decoration: none;
}

@media (min-width: 320px) {
  .footer-area .get-in-touch-area {
    margin-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 992px) {
  .footer-area .get-in-touch-area {
    margin-left: 65px;
    padding-right: 15px;
  }
}

.footer-area .get-in-touch-area p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
}

.footer-area .get-in-touch-area h3 {
  color: #416ff4;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.82px;
  line-height: normal;
  margin-bottom: 32px;
}

.footer-area .get-in-touch-area ul {
  margin: 0px -6px;
}

.footer-area .get-in-touch-area ul li {
  display: inline-block;
  margin: 0px 6px;
}

.footer-area .get-in-touch-area ul li i {
  width: 40px;
  height: 40px;
  background-color: #0D152E;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer landing-2
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer--area-2 h4 {
  text-transform: initial;
  font-size: 16px;
  letter-spacing: -0.53px;
}

.footer--area-2 .nav-cta-content .cta-area-btn-group {
  margin: 22px -8px -20px -8px;
}

.footer--area-2 .nav-cta-content .cta-area-btn-group a {
  margin: 0px 8px 20px 8px;
  display: inline-block;
}

.footer--area-2 .subscribe-newsletter {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .footer--area-2 .subscribe-newsletter {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .footer--area-2 .subscribe-newsletter {
    margin-top: 0;
    margin-left: 80px;
  }
}

.footer--area-2 .subscribe-newsletter h4 {
  margin-bottom: 19px;
}

.footer--area-2 .subscribe-newsletter .form-control {
  font-size: 16px;
  font-weight: 400;
  color: #687497;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  border: 0;
  height: 65px;
  border-radius: 6px;
  background-color: #0D152E;
  padding: 0px 150px 0px 18px;
}

.footer--area-2 .subscribe-newsletter .btn {
  color: #fff;
  width: 115px;
  height: 45px;
  border-radius: 6px;
  background-color: #416ff4;
  margin-left: 0px;
  margin-top: 10px;
}

@media (min-width: 576px) {
  .footer--area-2 .subscribe-newsletter .btn {
    margin-left: -140px;
  }
}

.footer--area-2 .footer-quick-link-area {
  padding-top: 50px;
}

@media (min-width: 768px) {
  .footer--area-2 .footer-quick-link-area {
    padding-top: 70px;
  }
}

@media (min-width: 992px) {
  .footer--area-2 .footer-quick-link-area {
    padding-top: 140px;
  }
}

.copy-right-area {
  padding: 25px 0px;
}

.copy-right-area .copy-right-text p {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.copy-right-area .social-link {
  justify-content: center;
  margin: 20px 0px 0px 0px;
}

@media (min-width: 576px) {
  .copy-right-area .social-link {
    justify-content: flex-end;
    margin: 0px -12px 0px -12px;
  }
}

.copy-right-area .social-link ul {
  margin: 0;
  padding: 0;
}

.copy-right-area .social-link h4 {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 0;
}

.copy-right-area .social-link a {
  color: #687497;
  font-size: 18px;
  margin: 0px 12px;
}

.copy-right-area .social-link a:hover {
  color: #2bd67b;
}

.footer-2-border {
  border: 1px solid rgba(255, 255, 255, 0.08);
  width: 100%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer landing-3
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l3 {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 88px;
}

@media (min-width: 768px) {
  .footer-area-l3 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .footer-area-l3 {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.footer-area-l3 .footer-quick-link-area {
  margin-bottom: -45px;
}

.footer-area-l3 .col-lg-3 {
  margin-bottom: 45px;
}

.footer-area-l3 .footer-area-list-item h4 {
  color: #0D152E;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 320px) {
  .footer-area-l3 .footer-area-list-item h4 {
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .footer-area-l3 .footer-area-list-item h4 {
    margin-bottom: 40px;
  }
}

.footer-area-l3 .footer-area-list-item ul {
  margin-bottom: -15px;
}

.footer-area-l3 .footer-area-list-item ul li {
  margin-bottom: 15px;
}

.footer-area-l3 .footer-area-list-item ul li a {
  color: #0D152E;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-decoration: none;
}

.footer-area-l3 .get-in-touch-area {
  margin-left: 0px;
  padding-right: 0px;
  padding-top: 45px;
}

@media (min-width: 576px) {
  .footer-area-l3 .get-in-touch-area {
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .footer-area-l3 .get-in-touch-area {
    margin-left: 65px;
    padding-right: 15px;
  }
}

.footer-area-l3 .get-in-touch-area p {
  color: #0D152E;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
}

.footer-area-l3 .get-in-touch-area h3 {
  color: #416ff4;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.82px;
  line-height: normal;
  margin-bottom: 32px;
}

.footer-area-l3 .get-in-touch-area ul {
  margin: 0px -6px;
}

.footer-area-l3 .get-in-touch-area ul li {
  display: inline-block;
  margin: 0px 6px;
}

.footer-area-l3 .get-in-touch-area ul li i {
  width: 40px;
  height: 40px;
  background-color: #F7F9FB;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #687497;
}

.footer-3-border {
  height: 1px;
  background-color: #EAECED;
  margin-bottom: 40px;
}

.copy-right-area-l3 {
  padding-bottom: 35px;
}

.copy-right-area-l3 p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer landing-4
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-border-l4 {
  height: 1px;
  background-color: #fff;
  opacity: 0.1;
  margin-top: 50px;
}

@media (min-width: 768px) {
  .footer-border-l4 {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .footer-border-l4 {
    margin-top: 105px;
  }
}

.footer-area-4 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer-area-4 .footer-quick-link-area {
  margin-bottom: -50px;
}

@media (min-width: 576px) {
  .footer-area-4 {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}

@media (min-width: 768px) {
  .footer-area-4 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .footer-area-4 {
    padding-top: 100px;
    padding-bottom: 140px;
  }
}

.footer-area-4 .footer-area-list-item h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  opacity: 0.7;
}

@media (min-width: 320px) {
  .footer-area-4 .footer-area-list-item h4 {
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .footer-area-4 .footer-area-list-item h4 {
    margin-bottom: 40px;
  }
}

.footer-area-4 .footer-area-list-item {
  margin-bottom: 50px;
}

.footer-area-4 .footer-area-list-item ul {
  margin-bottom: -15px;
}

.footer-area-4 .footer-area-list-item ul li {
  margin-bottom: 15px;
}

.footer-area-4 .footer-area-list-item ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  text-decoration: none;
  opacity: 0.99;
}

.footer-area-4 .social-link {
  padding-top: 40px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .footer-area-4 .social-link {
    padding-top: 50px;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .footer-area-4 .social-link {
    padding-top: 150px;
  }
}

.footer-area-4 .social-link h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 13px;
}

.footer-area-4 .social-link ul {
  margin: 0;
  padding: 0;
  margin: 0px -12px;
}

.footer-area-4 .social-link ul a {
  color: #fff;
  font-size: 18px;
  margin: 0px 12px;
  transition: all 0.4s ease-in-out;
}

.footer-area-4 .social-link ul a:hover {
  color: #2bd67b;
}

.contact-details-l4-area {
  margin-bottom: -30px;
}

.contact-details-l4-area .contact-details-l4 {
  margin-bottom: 30px;
}

.contact-details-l4-area .contact-details-l4 i {
  color: #fff;
  margin-right: 15px;
  font-size: 18px;
}

.contact-details-l4-area .contact-details-l4 .content {
  margin-bottom: -5px;
  margin-top: -5px;
}

.contact-details-l4-area .contact-details-l4 .content a {
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  text-decoration: none;
  margin-bottom: 5px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer landing-5
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-l5 {
  background: #101C3D;
}

.footer-area-l5 .footer-quick-link-area {
  margin-bottom: -45px;
}

@media (min-width: 320px) {
  .footer-area-l5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .footer-area-l5 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .footer-area-l5 {
    padding-top: 100px;
    padding-bottom: 140px;
  }
}

@media (min-width: 320px) {
  .footer-area-l5 .join-us-content {
    margin-right: 0px;
  }
}

@media (min-width: 768px) {
  .footer-area-l5 .join-us-content {
    margin-right: 65px;
  }
}

.footer-area-l5 .join-us-content h2 {
  color: #fff;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
}

.footer-area-l5 .join-us-btn .btn {
  min-width: 180px;
  height: 60px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  border-radius: 30px;
  background-color: #416ff4;
}

@media (min-width: 320px) {
  .footer-area-l5 .join-us-btn .btn {
    margin-top: 25px;
  }
}

@media (min-width: 768px) {
  .footer-area-l5 .join-us-btn .btn {
    margin-top: 0;
  }
}

.footer-area-l5 .border-footer {
  margin: 85px 0px 120px 0px;
  background-color: #fff;
  opacity: 0.1;
  height: 1px;
}

@media (min-width: 320px) {
  .footer-area-l5 .border-footer {
    margin: 40px 0px 40px 0px;
  }
}

@media (min-width: 768px) {
  .footer-area-l5 .border-footer {
    margin: 60px 0px 80px 0px;
  }
}

@media (min-width: 992px) {
  .footer-area-l5 .border-footer {
    margin: 85px 0px 120px 0px;
  }
}

.footer-area-l5 .footer-area-list-item h4 {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 320px) {
  .footer-area-l5 .footer-area-list-item h4 {
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .footer-area-l5 .footer-area-list-item h4 {
    margin-bottom: 40px;
  }
}

.footer-area-l5 .footer-area-list-item {
  margin-bottom: 45px;
}

.footer-area-l5 .footer-area-list-item ul {
  margin-bottom: -15px;
}

.footer-area-l5 .footer-area-list-item ul li {
  margin-bottom: 15px;
}

.footer-area-l5 .footer-area-list-item ul li a {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-decoration: none;
}

@media (min-width: 320px) {
  .footer-area-l5 .get-in-touch-area {
    margin-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 992px) {
  .footer-area-l5 .get-in-touch-area {
    margin-left: 65px;
    padding-right: 15px;
  }
}

.footer-area-l5 .get-in-touch-area p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
}

.footer-area-l5 .get-in-touch-area h3 {
  color: #416ff4;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.82px;
  line-height: normal;
  margin-bottom: 32px;
}

.footer-area-l5 .get-in-touch-area ul {
  margin: 0px -6px;
}

.footer-area-l5 .get-in-touch-area ul li {
  display: inline-block;
  margin: 0px 6px;
}

.footer-area-l5 .get-in-touch-area ul li i {
  width: 40px;
  height: 40px;
  background-color: #0D152E;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer landing-6
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-6 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer-area-6 .footer-quick-link-area {
  margin-bottom: -50px;
}

@media (min-width: 576px) {
  .footer-area-6 {
    padding-top: 60px;
    padding-bottom: 70px;
  }
}

@media (min-width: 768px) {
  .footer-area-6 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .footer-area-6 {
    padding-top: 100px;
    padding-bottom: 140px;
  }
}

.footer-area-6 .footer-area-list-item h4 {
  color: #101C3D;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
}

@media (min-width: 320px) {
  .footer-area-6 .footer-area-list-item h4 {
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .footer-area-6 .footer-area-list-item h4 {
    margin-bottom: 40px;
  }
}

.footer-area-6 .footer-area-list-item {
  margin-bottom: 50px;
}

.footer-area-6 .footer-area-list-item ul {
  margin-bottom: -15px;
}

.footer-area-6 .footer-area-list-item ul li {
  margin-bottom: 15px;
}

.footer-area-6 .footer-area-list-item ul li a {
  color: #0E1833;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  text-decoration: none;
}

.footer-area-6 .social-link {
  padding-top: 40px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .footer-area-6 .social-link {
    padding-top: 50px;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .footer-area-6 .social-link {
    padding-top: 150px;
  }
}

.footer-area-6 .social-link h4 {
  color: #101C3D;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 13px;
}

.footer-area-6 .social-link ul {
  margin: 0;
  padding: 0;
  margin: 0px -12px;
}

.footer-area-6 .social-link ul a {
  color: #687497;
  font-size: 18px;
  margin: 0px 12px;
}

.footer-area-6 .download-image {
  margin-bottom: -20px;
}

.footer-area-6 .download-image a {
  margin-bottom: 20px;
  display: inline-block;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer landing-8
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-area-8-list-item h4 {
  opacity: 0.6;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 2.41px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.footer-area-8-list-item a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  display: inline-block;
  margin-bottom: 27px;
}

@media (min-width: 992px) {
  .footer-area-8 .subscribe-newsletter {
    margin-left: 80px;
  }
}

.footer-area-8 .subscribe-newsletter p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 27px;
}

.footer-area-8 .subscribe-newsletter .form-control {
  font-size: 16px;
  font-weight: 400;
  color: #687497;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  border: 0;
  width: 100%;
  height: 70px;
  border-radius: 35px;
  background-color: #fff;
  padding: 0px 76px 0px 32px;
}

.footer-area-8 .subscribe-newsletter .btn {
  color: #fff;
  min-width: auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #416ff4;
  margin-left: 0px;
  margin-left: -68px;
  margin-top: 5px;
}

.copy-right-area-8 {
  padding: 40px 0px 40px 0px;
}

@media (min-width: 768px) {
  .copy-right-area-8 {
    padding: 85px 0px 40px 0px;
  }
}

.copy-right-area-8 .copy-right-text p {
  color: #EAECED;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-left: 15px;
}

@media (min-width: 480px) {
  .copy-right-area-8 .copy-right-text p {
    margin-left: 45px;
  }
}

.copy-right-area-8 .social-link {
  justify-content: center;
  margin: 30px 0px 0px 0px;
}

@media (min-width: 768px) {
  .copy-right-area-8 .social-link {
    justify-content: flex-end;
    margin: 0px -12px 0px -12px;
  }
}

.copy-right-area-8 .social-link ul {
  margin: 0;
  padding: 0;
}

.copy-right-area-8 .social-link i {
  color: #fff;
  font-size: 14px;
  margin: 0px 12px;
  background: rgba(13, 21, 46, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer landing-9
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer--area-9 {
  padding-top: 0;
}

.footer-quick-link-area-9 {
  padding-top: 0 !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer Inner
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.footer-inner {
  background: #101C3D;
}

.footer-inner .footer-quick-link-area {
  margin-bottom: -45px;
}

@media (min-width: 320px) {
  .footer-inner {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .footer-inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .footer-inner {
    padding-top: 107px;
    padding-bottom: 115px;
  }
}

.footer-inner .footer-area-list-item h4 {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 320px) {
  .footer-inner .footer-area-list-item h4 {
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .footer-inner .footer-area-list-item h4 {
    margin-bottom: 40px;
  }
}

.footer-inner .footer-area-list-item {
  margin-bottom: 45px;
}

.footer-inner .footer-area-list-item ul {
  margin-bottom: -15px;
}

.footer-inner .footer-area-list-item ul li {
  margin-bottom: 15px;
}

.footer-inner .footer-area-list-item ul li a {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-decoration: none;
}

@media (min-width: 320px) {
  .footer-inner .get-in-touch-area {
    margin-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 992px) {
  .footer-inner .get-in-touch-area {
    margin-left: 65px;
    padding-right: 15px;
  }
}

.footer-inner .get-in-touch-area p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
}

.footer-inner .get-in-touch-area h3 {
  color: #416ff4;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.82px;
  line-height: normal;
  margin-bottom: 32px;
}

.footer-inner .get-in-touch-area ul {
  margin: 0px -6px;
}

.footer-inner .get-in-touch-area ul li {
  display: inline-block;
  margin: 0px 6px;
}

.footer-inner .get-in-touch-area ul li i {
  width: 40px;
  height: 40px;
  background-color: #0D152E;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}

.focus-reset:focus {
  box-shadow: none;
  outline: none;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         landing-1 testimonial 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area {
  background-position: 43% 100% !important;
  background-repeat: no-repeat !important;
}

@media (min-width: 320px) {
  .testimonial-area {
    padding-top: 40px;
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .testimonial-area {
    padding-bottom: 80px;
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .testimonial-area {
    padding-top: 115px;
    background-position: 43% 65%;
  }
}

@media (min-width: 1200px) {
  .testimonial-area {
    padding-bottom: 100px;
    background-position: 43% 100%;
  }
}

.testimonial-area .testimonial--slider {
  padding-bottom: 80px;
}

@media (min-width: 320px) {
  .testimonial-area .swiper-wrapper {
    padding-top: 20px;
  }
}

@media (min-width: 768px) {
  .testimonial-area .swiper-wrapper {
    margin-left: -25vw;
    padding-top: 50px;
  }
}

@media (min-width: 992px) {
  .testimonial-area .swiper-wrapper {
    margin-left: -24vw;
  }
}

@media (min-width: 1200px) {
  .testimonial-area .swiper-wrapper {
    margin-left: -23vw;
  }
}

@media (min-width: 1366px) {
  .testimonial-area .swiper-wrapper {
    margin-left: -26vw;
  }
}

@media (min-width: 1600px) {
  .testimonial-area .swiper-wrapper {
    margin-left: -18vw;
  }
}

.testimonial-area .swiper-navigation i {
  height: 40px;
  width: 40px;
  line-height: 37px;
  border: 2px solid #fefffe;
  border-radius: 50%;
  text-align: center;
  margin: 0px 5px;
  color: #fefffe;
}

.tesimonial-card {
  padding: 40px;
  box-shadow: 14px 18px 69px rgba(186, 186, 186, 0.16);
  border-radius: 10px;
  background-color: #fff;
}

.tesimonial-card img {
  margin-right: 25px;
}

.tesimonial-card h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.tesimonial-card span {
  color: #9ea1ab;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.tesimonial-card p {
  color: #0D152E;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 25px;
}

.tesimonial-card .testimonial__star {
  margin: 30px -4px 0px -4px;
}

.tesimonial-card .testimonial__star i {
  font-size: 24px;
  color: #fbb040;
  margin: 0px 4px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         landing-2 testimonial 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-2 {
  padding-top: 80px;
  padding-bottom: 90px;
}

@media (min-width: 768px) {
  .testimonial-area-2 {
    padding-top: 100px;
    padding-bottom: 110px;
  }
}

@media (min-width: 992px) {
  .testimonial-area-2 {
    padding-top: 120px;
  }
}

@media (min-width: 1200px) {
  .testimonial-area-2 {
    padding-top: 155px;
  }
}

.testimonial-area-2 .quote-image {
  position: absolute;
  top: -4%;
  right: 28%;
  z-index: 1;
}

.testimonial-area-2 .l2-testimonial-text-slider .content {
  margin-top: 45px;
}

@media (min-width: 992px) {
  .testimonial-area-2 .l2-testimonial-text-slider .content {
    margin-top: 0px;
  }
}

.testimonial-area-2 .l2-testimonial-text-slider br {
  display: none;
}

@media (min-width: 768px) {
  .testimonial-area-2 .l2-testimonial-text-slider br {
    display: block;
  }
}

.testimonial-area-2 .l2-testimonial-text-slider h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 47px;
  margin-bottom: 40px;
}

@media (min-width: 576px) {
  .testimonial-area-2 .l2-testimonial-text-slider h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.testimonial-area-2 .l2-testimonial-text-slider p {
  color: #0D152E;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .testimonial-area-2 .l2-testimonial-text-slider p {
    margin-right: 32px;
  }
}

@media (min-width: 1200px) {
  .testimonial-area-2 .l2-testimonial-text-slider p {
    margin-right: 42px;
  }
}

@media (min-width: 1366px) {
  .testimonial-area-2 .l2-testimonial-text-slider p {
    margin-right: 132px;
  }
}

.testimonial-area-2 .l2-testimonial-text-slider .star-icon {
  margin: 0px -4px;
}

.testimonial-area-2 .l2-testimonial-text-slider .star-icon .fas {
  color: #ffa136;
  margin: 0px 4px;
}

.testimonial-area-2 .l2-testimonial-text-slider .client-identity {
  margin-top: 35px;
}

.testimonial-area-2 .l2-testimonial-text-slider .client-identity .icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 15px;
  border: 1px solid #e6e6e6;
}

.testimonial-area-2 .l2-testimonial-text-slider .client-identity .fas {
  color: #e0565b;
  font-size: 25px;
}

.testimonial-area-2 .l2-testimonial-text-slider .client-identity h6 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  text-align: left;
}

.testimonial-area-2 .l2-testimonial-text-slider .client-identity span {
  color: #9ea1ab;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.testimonial-area-2 .slick-slide img {
  border-radius: 30px 30px 100px 30px;
}

.testimonial-area-2 .next-prev-btn3 {
  position: relative;
  margin-top: 65px;
}

.testimonial-area-2 .next-prev-btn3 span {
  cursor: pointer;
  color: #81838c;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 30px;
  border-bottom: 2px solid #81838c;
}

.testimonial-area-2 .next-prev-btn3 span.prev3 {
  position: absolute;
  left: 0;
}

.testimonial-area-2 .next-prev-btn3 span.next3 {
  position: absolute;
  right: 0;
}

.testimonial-area-2 .next-prev-btn3 .slick-arrow.active {
  color: #416ff4 !important;
  border-bottom: 2px solid #416ff4 !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         landing-4 testimonial 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l4 {
  padding: 45px 0px 0px 0px;
}

@media (min-width: 768px) {
  .testimonial-area-l4 {
    padding: 65px 0px 0px 0px;
  }
}

@media (min-width: 992px) {
  .testimonial-area-l4 {
    padding: 115px 0px 0px 0px;
  }
}

.testimonial-l4-slider {
  position: relative;
  padding: 30px 0px 60px 0px;
  background-size: 100% !important;
}

@media (min-width: 768px) {
  .testimonial-l4-slider {
    padding: 45px 0px 80px 0px;
  }
}

@media (min-width: 992px) {
  .testimonial-l4-slider {
    padding: 65px 0px 100px 0px;
  }
}

.testimonial-l4-slider .single-item {
  margin: 0px 40px;
}

.testimonial-l4-slider__quote .quote-left {
  position: absolute;
  top: -30px;
  left: -35px;
  color: #9EA1AB;
}

.testimonial-l4-slider__quote .quote-right {
  position: absolute;
  bottom: -30px;
  right: 0;
  color: #9EA1AB;
}

.testimonial-l4-slider__star {
  margin: 0px -5px;
  margin-bottom: 30px;
}

.testimonial-l4-slider__star i {
  color: #EF8E1F;
  margin: 0px 5px;
}

.testimonial-l4-slider__content {
  position: relative;
}

.testimonial-l4-slider__content p {
  color: #0D152E;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: 37px;
}

.testimonial-l4-slider__name span {
  color: #182748;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
  display: inline-block;
  margin-top: 17px;
}

.testimonial-l4-slider .slick-dots li button {
  background-color: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
}

.testimonial-l4-slider .slick-dots li button:focus {
  outline: none;
  box-shadow: none;
}

.testimonial-l4-slider .slick-dots li.slick-active {
  background-color: #416ff4;
}

.testimonial-l4-slider .slick-dots {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 45px 0 0;
  justify-content: center;
}

.testimonial-l4-slider .slick-dots li {
  background-color: #d8d8d8;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px;
  -webkit-transition: .4s;
  transition: .4s;
  cursor: pointer;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         landing-9 testimonial 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.testimonial-area-l9-1--content {
  background-position: center !important;
  padding: 40px 0px 60px 0px;
  margin-bottom: 45px;
}

@media (min-width: 480px) {
  .testimonial-area-l9-1--content {
    margin-bottom: 135px;
  }
}

@media (min-width: 768px) {
  .testimonial-area-l9-1--content {
    margin-bottom: 240px;
  }
}

@media (min-width: 992px) {
  .testimonial-area-l9-1--content {
    padding: 130px 0px 240px 0px;
    margin-bottom: 350px;
  }
}

@media (min-width: 1600px) {
  .testimonial-area-l9-1--content {
    margin-bottom: 380px;
  }
}

.testimonial-slider-l9-1 .single-item {
  box-shadow: 0 -1px 35px rgba(59, 59, 59, 0.16);
  border-radius: 12px;
  background-color: #fff;
  padding: 0px 40px 32px 40px;
  margin: 55px 25px;
}

.testimonial-slider-l9-1 .slider-image img {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: -35px;
}

.testimonial-slider-l9-1 .slider-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: -12px;
}

.testimonial-slider-l9-1 .slider-content i {
  font-size: 25px;
  color: #eaeaea;
}

.testimonial-slider-l9-1 .user-identity span {
  color: #f7b26d;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.slider-l9-main {
  position: relative;
}

.l9-slider-arrow-2 i {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #416ff4;
  background: rgba(65, 111, 244, 0.2);
  border-radius: 50%;
  margin: 0px 6px;
  cursor: pointer;
}

.l9-slider-arrow-2 .prev9-1 {
  position: absolute;
  top: 45%;
  z-index: 1;
}

.l9-slider-arrow-2 .next9-1 {
  position: absolute;
  top: 45%;
  right: 0;
  z-index: 1;
}

.testimonial-l9-image-group {
  position: relative;
}

.testimonial-l9-image-group .image-1 {
  position: absolute;
  display: none;
}

@media (min-width: 768px) {
  .testimonial-l9-image-group .image-1 {
    display: block;
    top: 0;
    left: 0%;
  }
}

@media (min-width: 992px) {
  .testimonial-l9-image-group .image-1 {
    top: -30px;
    left: 22%;
  }
}

.testimonial-l9-image-group .image-2 {
  position: absolute;
  display: none;
}

@media (min-width: 768px) {
  .testimonial-l9-image-group .image-2 {
    display: block;
    top: 160px;
    left: 0%;
  }
}

@media (min-width: 992px) {
  .testimonial-l9-image-group .image-2 {
    top: 115px;
    left: 12%;
  }
}

.testimonial-l9-image-group .image-3 {
  position: absolute;
  display: none;
}

@media (min-width: 768px) {
  .testimonial-l9-image-group .image-3 {
    display: block;
    top: 335px;
    left: 7%;
  }
}

@media (min-width: 992px) {
  .testimonial-l9-image-group .image-3 {
    top: 320px;
    left: 25%;
  }
}

.testimonial-l9-image-group .image-4 {
  position: absolute;
  display: none;
}

@media (min-width: 768px) {
  .testimonial-l9-image-group .image-4 {
    display: block;
    top: 300px;
    right: 0%;
  }
}

@media (min-width: 992px) {
  .testimonial-l9-image-group .image-4 {
    right: 19%;
  }
}

.testimonial-l9-image-group .image-5 {
  position: absolute;
  display: none;
}

@media (min-width: 768px) {
  .testimonial-l9-image-group .image-5 {
    display: block;
    top: -16px;
    right: 0%;
  }
}

@media (min-width: 992px) {
  .testimonial-l9-image-group .image-5 {
    top: 17px;
    right: 13%;
  }
}

.tc_mobile {
  height: 25rem;
}

@media screen and (max-width: 950px) {
  .tc_mobile {
    height: 30rem !important;
  }
}

@media screen and (max-width: 850px) {
  .tc_mobile {
    height: 35rem !important;
  }
}

.how-it-works-area {
  padding-top: 47px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .how-it-works-area {
    padding-top: 67px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .how-it-works-area {
    padding-top: 117px;
    padding-bottom: 105px;
  }
}

.how-it-works-area .section__heading {
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .how-it-works-area .section__heading {
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .how-it-works-area .section__heading {
    padding-bottom: 70px;
  }
}

.how-it-works-area .section__heading p {
  opacity: 0.6;
}

.how-it-works-area .how-it-works-items {
  margin-bottom: -45px;
}

.how-it-works-area .how-it--card {
  background: transparent;
  margin-bottom: 45px;
  border: none;
}

.how-it-works-area .how-it--card__number {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin: auto;
  margin-top: 50px;
  margin-bottom: 23px;
}

.how-it-works-area .how-it--card__number span {
  color: #fff;
  background-color: #101C3D;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
}

.how-it-works-area .how-it--card__headeing h4 {
  color: #fff;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: normal;
}

@media (min-width: 1200px) {
  .how-it-works-area .how-it--card__content {
    margin: 0px 6px;
  }
}

@media (min-width: 1366px) {
  .how-it-works-area .how-it--card__content {
    margin: 0px 40px;
  }
}

.how-it-works-area .how-it--card__content p {
  color: #F5F5F5;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-top: 10px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-2 CTA Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area {
  position: relative;
}

.cta-area .cta-content {
  padding: 50px 0px 0px 0px;
}

@media (min-width: 768px) {
  .cta-area .cta-content {
    padding: 70px 0px 0px 0px;
  }
}

@media (min-width: 992px) {
  .cta-area .cta-content {
    padding: 132px 0px 130px 0px;
  }
}

@media (min-width: 1200px) {
  .cta-area .cta-content {
    padding: 132px 140px 130px 0px;
  }
}

.cta-area .cta-content h2 {
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 47px;
}

@media (min-width: 576px) {
  .cta-area .cta-content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.cta-area .cta-content .cta-area-btn-group {
  margin: 33px -8px -20px -8px;
}

.cta-area .cta-content .cta-area-btn-group a {
  margin: 0px 8px 20px 8px;
  display: inline-block;
}

.cta-area .cta-area-image-group {
  padding-top: 50px;
  margin-left: 0px;
  text-align: center;
}

@media (min-width: 992px) {
  .cta-area .cta-area-image-group {
    padding-top: 100px;
  }
}

@media (min-width: 1200px) {
  .cta-area .cta-area-image-group {
    margin-left: 90px;
  }
}

@media (min-width: 1366px) {
  .cta-area .cta-area-image-group {
    margin-left: 185px;
  }
}

.cta-area .cta-area-image-group .image-1 {
  position: relative;
  bottom: 0;
  z-index: 1;
}

@media (min-width: 992px) {
  .cta-area .cta-area-image-group .image-1 {
    position: absolute;
  }
}

.cta-area .cta-area-image-group .image-1 img {
  width: 100%;
}

@media (min-width: 480px) {
  .cta-area .cta-area-image-group .image-1 img {
    width: initial;
  }
}

.cta-area .cta-area-image-group .image-2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

@media (min-width: 320px) {
  .cta-area .cta-area-image-group .image-2 img {
    display: none;
  }
}

@media (min-width: 480px) {
  .cta-area .cta-area-image-group .image-2 img {
    display: block;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-3 CTA Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l3-1 {
  padding: 45px 0px 55px 0px;
  position: relative;
}

@media (min-width: 768px) {
  .cta-area-l3-1 {
    padding: 55px 0px 65px 0px;
  }
}

@media (min-width: 992px) {
  .cta-area-l3-1 {
    padding: 75px 0px 85px 0px;
  }
}

.cta-l3-image-group .image-1 {
  position: absolute;
  top: 20%;
  left: 4%;
}

@media (min-width: 992px) {
  .cta-l3-image-group .image-1 {
    left: 13%;
  }
}

.cta-l3-image-group .image-2 {
  position: absolute;
  bottom: 14%;
  left: 3%;
}

@media (min-width: 992px) {
  .cta-l3-image-group .image-2 {
    bottom: 14%;
    left: 3%;
  }
}

.cta-l3-image-group .image-3 {
  position: absolute;
  bottom: 25%;
  left: 22%;
}

@media (min-width: 992px) {
  .cta-l3-image-group .image-3 {
    bottom: 29%;
    left: 30%;
  }
}

.cta-l3-image-group .image-4 {
  position: absolute;
  bottom: 24%;
  right: 24%;
}

@media (min-width: 992px) {
  .cta-l3-image-group .image-4 {
    right: 31%;
  }
}

.cta-l3-image-group .image-5 {
  position: absolute;
  bottom: 13%;
  right: 7%;
}

@media (min-width: 992px) {
  .cta-l3-image-group .image-5 {
    bottom: 26%;
    right: 17%;
  }
}

.cta-l3-image-group .image-6 {
  position: absolute;
  top: 20%;
  right: 4%;
}

@media (min-width: 992px) {
  .cta-l3-image-group .image-6 {
    right: 15%;
  }
}

.cta-l3-image-group .image-7 {
  position: absolute;
  top: 4%;
  right: 17%;
}

@media (min-width: 992px) {
  .cta-l3-image-group .image-7 {
    top: 10%;
    right: 30%;
  }
}

.cta-l3-content h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 48px;
}

@media (min-width: 576px) {
  .cta-l3-content h2 {
    font-size: 42px;
    line-height: 54px;
  }
}

@media (min-width: 768px) {
  .cta-l3-content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.cta-l3-content .btn {
  width: 180px;
  height: 60px;
  border-radius: 7px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 43px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-4 CTA Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-form-area-l4 {
  position: relative;
  z-index: 1;
  padding-top: 45px;
}

@media (min-width: 768px) {
  .contact-form-area-l4 {
    padding-top: 65px;
  }
}

@media (min-width: 992px) {
  .contact-form-area-l4 {
    padding-top: 115px;
  }
}

.contact-form-area-l4:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #416ff4;
  opacity: 0.93;
  z-index: -1;
}

.contact-form-area-l4 .section__heading h2 {
  color: #fff;
  margin-bottom: 27px;
}

.contact-form-area-l4 .section__heading p {
  color: #fff;
}

@media (min-width: 768px) {
  .contact-form-area-l4 .section__heading p {
    padding: 0px 40px;
  }
}

.contact-form-l4 {
  margin: 40px 0px 0px 0px;
}

@media (min-width: 768px) {
  .contact-form-l4 {
    margin: 50px 0px 0px 0px;
  }
}

@media (min-width: 992px) {
  .contact-form-l4 {
    margin: 78px 0px 0px 0px;
  }
}

.contact-form-l4 .form-control {
  width: 100%;
  height: 60px;
  border-radius: 69px;
  background-color: #fff;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  padding-left: 25px;
}

.contact-form-l4__btn .btn {
  width: 100%;
  height: 60px;
  border-radius: 69px;
  background-color: #2bd67b;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.contact-form-l4 .row {
  margin-bottom: -30px;
}

.contact-form-l4 .col-lg-4 {
  margin-bottom: 30px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-6 CTA Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-6 {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .cta-area-6 {
    padding-top: 80px;
    padding-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .cta-area-6 {
    padding-top: 130px;
    padding-bottom: 55px;
  }
}

.cta-area-6 .cta-area-bg {
  position: relative;
  z-index: 2;
  border-radius: 16px;
  padding: 15px;
}

@media (min-width: 480px) {
  .cta-area-6 .cta-area-bg {
    padding: 65px 45px 45px 45px;
  }
}

@media (min-width: 992px) {
  .cta-area-6 .cta-area-bg {
    padding: 40px 50px 45px 50px;
  }
}

@media (min-width: 1200px) {
  .cta-area-6 .cta-area-bg {
    padding: 70px 60px 70px 80px;
  }
}

.cta-area-6 .cta-area-bg::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #0D152E;
  top: 0px;
  left: 0px;
  border-radius: 15px;
  z-index: -1;
}

@media (min-width: 768px) {
  .cta-area-6 .cta-area-bg::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #082B91;
    opacity: 0.07;
    top: 30px;
    left: 30px;
    border-radius: 15px;
    z-index: -2;
  }
}

.cta-area-6 .cta-area-bg .cta-content {
  position: relative;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .cta-area-6 .cta-area-bg .cta-content {
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .cta-area-6 .cta-area-bg .cta-content {
    margin-bottom: 0;
  }
}

.cta-area-6 .cta-area-bg .cta-content i {
  font-size: 35px;
  color: #283350;
}

@media (min-width: 480px) {
  .cta-area-6 .cta-area-bg .cta-content i {
    font-size: 70px;
  }
}

.cta-area-6 .cta-area-bg .cta-content .left-quote {
  position: absolute;
  left: -0px;
  top: -8px;
  z-index: -1;
}

@media (min-width: 480px) {
  .cta-area-6 .cta-area-bg .cta-content .left-quote {
    left: -28px;
    top: -33px;
  }
}

.cta-area-6 .cta-area-bg .cta-content .right-quote {
  position: absolute;
  margin-left: -22px;
  margin-top: 11px;
  z-index: -1;
}

.cta-area-6 .cta-area-bg .cta-content p {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -1.39px;
  line-height: 45px;
}

@media (min-width: 992px) {
  .cta-area-6 .cta-area-bg .cta-content p {
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .cta-area-6 .cta-area-bg .cta-content p {
    font-size: 30px;
  }
}

@media (min-width: 1600px) {
  .cta-area-6 .cta-area-bg .cta-content p {
    padding-right: 120px;
  }
}

.cta-area-6 .cta-area-bg .cta-content .user-identity {
  margin-top: 25px;
}

.cta-area-6 .cta-area-bg .cta-content .user-identity h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 25px;
}

.cta-area-6 .cta-area-bg .cta-content .user-identity span {
  color: #687497;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 25px;
}

.cta-area-6 .cta-area-bg .cta-main-image img {
  width: 100%;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-7 CTA Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l7 {
  position: relative;
}

.cta-area-l7 .cta-content-l7 {
  padding: 30px 0px 0px 0px;
}

@media (min-width: 480px) {
  .cta-area-l7 .cta-content-l7 {
    padding: 50px 0px 0px 30px;
  }
}

@media (min-width: 768px) {
  .cta-area-l7 .cta-content-l7 {
    padding: 70px 0px 0px 30px;
  }
}

@media (min-width: 992px) {
  .cta-area-l7 .cta-content-l7 {
    padding: 110px 0px 120px 50px;
  }
}

@media (min-width: 1200px) {
  .cta-area-l7 .cta-content-l7 {
    padding: 110px 10px 120px 75px;
  }
}

.cta-area-l7 .cta-content-l7 h2 {
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 47px;
}

@media (min-width: 576px) {
  .cta-area-l7 .cta-content-l7 h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.cta-area-l7 .cta-content-l7 .cta-area-btn-group {
  margin: 33px -8px -20px -8px;
}

.cta-area-l7 .cta-content-l7 .cta-area-btn-group a {
  margin: 0px 8px 20px 8px;
  display: inline-block;
}

.cta-area-l7 .cta-l7-bg {
  border-radius: 15px;
  background-color: #416ff4;
}

.cta-area-l7 .cta-area-image-group-l7 {
  padding-top: 50px;
  margin-left: 0px;
  text-align: center;
}

.cta-area-l7 .cta-area-image-group-l7 .image-1 {
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .cta-area-l7 .cta-area-image-group-l7 .image-1 {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    transform: translatex(-50%);
  }
}

.cta-area-l7 .cta-area-image-group-l7 .image-1 img {
  width: 100%;
}

@media (min-width: 480px) {
  .cta-area-l7 .cta-area-image-group-l7 .image-1 img {
    width: initial;
  }
}

.cta-area-l7 .cta-area-image-group-l7 .image-2 {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 0;
  transform: translatex(-50%);
}

@media (min-width: 320px) {
  .cta-area-l7 .cta-area-image-group-l7 .image-2 img {
    display: none;
  }
}

@media (min-width: 480px) {
  .cta-area-l7 .cta-area-image-group-l7 .image-2 img {
    display: block;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-8 CTA Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-area-l8-1 {
  padding-bottom: 57px;
}

@media (min-width: 768px) {
  .contact-area-l8-1 {
    padding-bottom: 77px;
  }
}

@media (min-width: 992px) {
  .contact-area-l8-1 {
    padding-bottom: 140px;
  }
}

.cta-area-l8-1 {
  background: #fff;
  border-radius: 23px;
  padding: 17px 30px 30px 30px;
}

@media (min-width: 768px) {
  .cta-area-l8-1 {
    padding: 30px 30px 35px 30px;
  }
}

@media (min-width: 992px) {
  .cta-area-l8-1 {
    padding: 35px 60px 40px 60px;
  }
}

@media (min-width: 1600px) {
  .cta-area-l8-1__content {
    padding-right: 75px;
  }
}

.cta-area-l8-1__content h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
  margin-bottom: 12px;
}

.cta-area-l8-1__content p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 0;
}

.cta-area-l8-1__btn .btn {
  width: 218px;
  height: 60px;
  border-radius: 35px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .cta-area-l8-1__btn .btn {
    margin-top: 0;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
         Landing-9 CTA Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l9-1 {
  margin-top: 40px;
  background: #416ff4;
  border-radius: 23px;
  padding: 17px 30px 30px 30px;
}

@media (min-width: 768px) {
  .cta-area-l9-1 {
    margin-top: 50px;
    padding: 30px 30px 35px 30px;
  }
}

@media (min-width: 992px) {
  .cta-area-l9-1 {
    margin-top: 80px;
    padding: 70px 65px 66px 65px;
  }
}

@media (min-width: 1600px) {
  .cta-area-l9-1__content {
    padding-right: 75px;
  }
}

.cta-area-l9-1__content h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
}

@media (min-width: 768px) {
  .cta-area-l9-1__content h2 {
    font-size: 34px;
  }
}

.cta-area-l9-1__btn .btn {
  width: 180px;
  height: 60px;
  border-radius: 30px;
  background-color: #2bd67b;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .cta-area-l9-1__btn .btn {
    margin-top: 0;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Blog Home-6     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.blog-area-6 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .blog-area-6 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .blog-area-6 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

.blog-area-6 .section__heading-2 h4 {
  color: #416ff4;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

.blog-area-6 .section__heading-2 h2 {
  color: #0D152E;
  font-size: 42px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.89px;
  line-height: normal;
  margin-top: 30px;
  margin-bottom: 0px;
}

@media (min-width: 768px) {
  .blog-area-6 .section__heading-2 h2 {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .blog-area-6 .section__heading-2 h2 {
    margin-bottom: 80px;
  }
}

.blog-area-6 .blog-all-items {
  margin-bottom: -45px;
}

.blog-area-6 .blog-card {
  height: 100%;
  margin-bottom: 45px;
}

.blog-area-6 .blog-card .blog-image img {
  border-radius: 15px 15px 0px 0px;
}

.blog-area-6 .blog-card .blog-content {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
}

@media (min-width: 480px) {
  .blog-area-6 .blog-card .blog-content {
    padding: 35px 45px 45px 45px;
  }
}

.blog-area-6 .blog-card .blog-content h4 {
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.blog-area-6 .blog-card .blog-content h4 a {
  text-decoration: none;
  color: #0D152E;
}

.blog-area-6 .blog-card .blog-content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-top: 20px;
}

.blog-area-6 .blog-card .blog-content .read-more-area {
  margin-top: 30px;
}

.blog-area-6 .blog-card .blog-content .read-more-area span {
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.blog-area-6 .blog-card .blog-content .read-more-area i {
  font-size: 18px;
  color: #416ff4;
}

.blog-area-6 .blog-card .blog-content .read-more-area a {
  text-decoration: none;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Blog main     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.blog-banner {
  padding: 120px 0px 35px 0px;
}

@media (min-width: 480px) {
  .blog-banner {
    padding: 135px 0px 35px 0px;
  }
}

@media (min-width: 768px) {
  .blog-banner {
    padding: 145px 0px 52px 0px;
  }
}

.blog-banner h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
}

@media (min-width: 576px) {
  .blog-banner h2 {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .blog-banner h2 {
    font-size: 60px;
  }
}

.blog-banner p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.blog-main-area {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .blog-main-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .blog-main-area {
    padding-top: 80px;
    padding-bottom: 130px;
  }
}

.date-comment {
  margin-top: 40px;
}

.date-comment ul {
  margin: 0px -14px -20px -14px;
}

.date-comment ul li {
  display: inline-flex;
  align-items: center;
  margin: 0px 14px 20px 14px;
}

.date-comment span {
  margin-left: 11px;
}

.blog-content-area i {
  font-size: 15px;
  color: #416ff4;
}

.blog-content-area span {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.blog-content-area span a {
  color: #0D152E;
  text-decoration: none;
}

.blog-content-area h2 {
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.62px;
  line-height: 42px;
  margin-top: 30px;
}

@media (min-width: 576px) {
  .blog-content-area h2 {
    font-size: 34px;
    line-height: 45px;
  }
}

.blog-content-area h2 a {
  color: #0D152E;
  text-decoration: none;
}

.blog-content-area p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 18px;
}

.blog-content-area .continue-text a {
  display: inline-block;
  text-decoration: none;
  color: #416ff4;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
  margin-top: 8px;
}

.blog-all-items {
  margin-bottom: -30px;
  padding-top: 35px;
}

@media (min-width: 768px) {
  .blog-all-items {
    padding-top: 0;
  }
}

.blog-all-items .blog-item {
  margin-bottom: 30px;
  border-radius: 15px;
  border: 1px solid #EAECED;
  padding: 30px;
}

@media (min-width: 992px) {
  .blog-right-sidebar {
    padding-left: 0px;
  }
}

@media (min-width: 1200px) {
  .blog-right-sidebar {
    padding-left: 70px;
  }
}

@media (min-width: 1600px) {
  .blog-right-sidebar {
    padding-left: 100px;
  }
}

.search-form .form-control {
  height: 60px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  color: #81838c;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  padding-left: 25px;
}

.search-form button[type=submit] {
  position: absolute;
  right: 0px;
  top: 50%;
  color: #9EA1AB;
  background-color: transparent;
  cursor: pointer;
  transform: translate(-50%, -50%);
  border: 0;
}

.recent-posts h2 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 50px;
  margin-bottom: 35px;
}

.recent-posts .recent-post-item {
  margin-bottom: 30px;
}

.recent-posts .recent-post-item img {
  padding-right: 18px;
}

.recent-posts .recent-post-item .post-content h4 {
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
}

.recent-posts .recent-post-item .post-content h4 a {
  color: #0D152E;
  text-decoration: none;
}

.recent-posts .recent-post-item .post-content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 0;
}

.recent-posts .recent-post-item .post-content p a {
  color: #81838C;
  text-decoration: none;
}

.recent-posts .recent-post-item .post-content .post-date a {
  margin-left: 11px;
}

.blog-categories {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .blog-categories {
    margin-top: 75px;
  }
}

.blog-categories h2 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 30px;
}

.blog-categories .categories-item ul li {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 25px;
}

.blog-categories .categories-item ul li a {
  color: #81838C;
  text-decoration: none;
}

.blog-pagination {
  padding-top: 40px;
}

@media (min-width: 768px) {
  .blog-pagination {
    padding-top: 60px;
  }
}

.blog-pagination ul {
  margin: 0px -3px 0px -3px;
}

.blog-pagination ul li {
  display: inline-flex;
  margin: 0px 3px 0px 3px;
}

.blog-pagination a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #EAECED;
  background-color: #fff;
  text-decoration: none;
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  transition: all 0.4s ease-in-out;
}

.blog-pagination a:hover {
  background: #416ff4;
  color: #fff;
}

.blog-pagination a.active {
  background: #416ff4;
  color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Blog Details     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.blog-details-main-area {
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .blog-details-main-area {
    padding-top: 0px;
    padding-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .blog-details-main-area {
    padding-top: 0px;
    padding-bottom: 95px;
  }
}

@media (min-width: 1200px) {
  .blog-details-main-area .blog-right-sidebar {
    padding-left: 70px;
  }
}

@media (min-width: 1600px) {
  .blog-details-main-area .blog-right-sidebar {
    padding-left: 100px;
  }
}

.blog-details-banner {
  padding: 95px 0px 35px 0px;
}

@media (min-width: 480px) {
  .blog-details-banner {
    padding: 115px 0px 35px 0px;
  }
}

@media (min-width: 768px) {
  .blog-details-banner {
    padding: 140px 0px 55px 0px;
  }
}

@media (min-width: 992px) {
  .blog-details-banner {
    padding: 163px 0px 55px 0px;
  }
}

.blog-details-banner h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
}

.blog-sub-details {
  margin-top: 15px;
}

.blog-sub-details ul {
  margin: 0px -5px 0px -5px;
}

.blog-sub-details ul li {
  display: inline-flex;
  margin: 0px 5px 0px 5px;
}

.blog-sub-details ul li a {
  text-decoration: none;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.blog-sub-details ul li span {
  margin-right: 9px;
}

.blog-details-content {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .blog-details-content {
    padding-top: 0;
  }
}

.blog-details-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 28px;
}

.blog-details-content h4 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-top: 45px;
  margin-bottom: 30px;
}

.blog-single-content-area {
  margin-top: 50px;
}

.comment-area {
  padding-top: 45px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .comment-area {
    padding-top: 65px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .comment-area {
    padding-top: 100px;
    padding-bottom: 130px;
  }
}

.comment-body h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
  margin-bottom: 0px;
}

@media (min-width: 768px) {
  .comment-body h2 {
    margin-bottom: 20px;
  }
}

.comment-body label {
  color: #0D152E;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: normal;
  margin-bottom: 14px;
  margin-top: 35px;
}

.comment-body .form-control {
  height: 51px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  padding-left: 20px;
}

.comment-body .comment-box .form-control {
  height: 231px;
  padding-left: 24px;
  padding-top: 25px;
}

.comment-body .form-control::placeholder {
  color: #81838C;
  opacity: 1;
}

.comment-body .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 50px;
}

.blog-comment-list-area {
  padding-top: 48px;
  padding-bottom: 45px;
}

@media (min-width: 768px) {
  .blog-comment-list-area {
    padding-top: 68px;
    padding-bottom: 65px;
  }
}

@media (min-width: 992px) {
  .blog-comment-list-area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.blog-comment-list-area h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
  margin-bottom: 35px;
}

@media (min-width: 768px) {
  .blog-comment-list-area h2 {
    margin-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .blog-comment-list-area h2 {
    margin-bottom: 55px;
  }
}

.comment-card {
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  padding: 40px 40px 20px 40px;
  margin-bottom: 30px;
}

.comment-card img {
  margin-right: 15px;
}

.comment-card .author-name-date h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.comment-card .author-name-date span {
  color: #9EA1AB;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}

.comment-card .comment-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 30px;
}

.comment-load-more-btn .btn {
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.comment-load-more-btn .btn:hover {
  color: #416ff4 !important;
}

.comment-load-more-btn i {
  color: #416ff4;
  margin-left: 22px;
}

/*Calendar css*/
.calendar-area {
  position: relative;
  margin-top: 60px;
}

.calendar-area #calendar {
  background: #fff;
  border-radius: 0;
  border: 1px solid #ddd;
}

.calendar-area .current-date {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  padding: 15px 10px;
}

.calendar-area .current-month {
  color: #0D152E;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.09px;
  line-height: normal;
}

.calendar-area .week-days {
  display: flex;
  justify-content: space-between;
  color: #dfebed;
  font-weight: 600;
  padding: 0px 0px;
}

.calendar-area .week-day {
  padding: 10px;
  text-align: center;
  width: 43px;
  color: #161c2d;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.09px;
  line-height: normal;
  text-transform: uppercase;
}

.calendar-area .days {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.calendar-area .weeks {
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 0 0px;
}

.calendar-area .week {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-bottom: 0px;
}

.calendar-area .not-current-month {
  opacity: 0.3;
}

.calendar-area .day {
  padding: 10px;
  text-align: center;
  width: 43px;
  color: #81838C;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.09px;
  line-height: normal;
}

.calendar-area .day.today {
  width: 41px;
  height: 40px;
  background-color: #416ff4;
  color: #fff;
}

.calendar-area .day:not(.not-current-month):hover {
  font-weight: 600;
}

.calendar-area #prev,
.calendar-area #next {
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #81838C;
  padding: 15px;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.09px;
  line-height: normal;
  text-align: left;
}

.calendar-area #prev:before {
  content: "<";
  padding-right: 4px;
}

.calendar-area #next {
  right: 0;
}

.calendar-area #next:after {
  content: ">";
  padding-left: 4px;
}

.calendar-area #week-starter {
  position: fixed;
  top: 0;
  right: 0;
  color: #2b4450;
  padding: 20px;
  text-shadow: 2px 2px 1px #dfebed;
}

.calendar-area .week-starter__choice {
  cursor: pointer;
}

.calendar-area .week-starter__choice > input {
  cursor: pointer;
}

@media (min-width: 992px) {
  .min-height-100vh {
    min-height: 100vh;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .fixed-right-bg {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
  }
  .max-w-465 {
    max-width: 465px;
  }
}

.sign-in-menu {
  padding-top: 20px;
}

.full-bg-position {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    landing-8 contact area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-area-l8-1 {
  padding-top: 45px;
}

@media (min-width: 768px) {
  .contact-area-l8-1 {
    padding-top: 65px;
  }
}

@media (min-width: 992px) {
  .contact-area-l8-1 {
    padding-top: 105px;
  }
}

.contact-area-l8-1 .section__heading h2 {
  color: #fff;
}

.contact-area-l8-1 .section__heading p {
  color: #EAECED;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
}

@media (min-width: 480px) {
  .contact-area-l8-1 .section__heading p {
    padding: 0px 50px;
  }
}

@media (min-width: 576px) {
  .contact-area-l8-1 .section__heading p {
    padding: 0px 70px;
  }
}

.contact-area-l8-1 .contact-map {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .contact-area-l8-1 .contact-map {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .contact-area-l8-1 .contact-map {
    margin-top: 30px;
    margin-bottom: 110px;
  }
}

.contact-area-l8-1 .contact-map img {
  width: 100%;
}

@media (min-width: 1200px) {
  .contact-area-l8-1 .contact-map img {
    width: initial;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    landing-9 contact area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-l9-1__form-area {
  box-shadow: 0 23px 78px rgba(0, 0, 0, 0.09);
  border-radius: 22px;
  background-color: #fff;
  padding: 20px 15px 20px 15px;
  margin-top: -100px;
}

@media (min-width: 480px) {
  .contact-l9-1__form-area {
    padding: 30px 30px 40px 30px;
  }
}

@media (min-width: 768px) {
  .contact-l9-1__form-area {
    padding: 67px 85px 60px 85px;
    margin-top: -200px;
  }
}

@media (min-width: 992px) {
  .contact-l9-1__form-area {
    margin-top: -320px;
  }
}

.contact-l9-1__heading h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.39px;
  line-height: normal;
}

@media (min-width: 576px) {
  .contact-l9-1__heading h2 {
    font-size: 42px;
  }
}

.contact-l9-1__heading p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 20px;
}

.contact-l9-1__input {
  margin-top: 60px;
}

.contact-l9-1__input .form-group {
  margin-bottom: 20px;
}

.contact-l9-1__input .form-control {
  color: #9EA1AB;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  width: 100%;
  height: 60px;
  border-radius: 107px;
  border: 1px solid #E5E5E5;
  background-color: #F7F9FB;
  padding-left: 30px;
}

.contact-l9-1__input .begin-advance-input {
  width: 100%;
}

.contact-l9-1__input .begin-advance-input .form-control {
  padding-left: 60px;
}

.contact-l9-1__input .begin-advance-input .beginer {
  width: 100%;
  position: relative;
}

@media (min-width: 576px) {
  .contact-l9-1__input .begin-advance-input .beginer {
    width: 48%;
  }
}

.contact-l9-1__input .begin-advance-input .advanced {
  width: 100%;
  position: relative;
}

@media (min-width: 576px) {
  .contact-l9-1__input .begin-advance-input .advanced {
    width: 48%;
  }
}

.contact-l9-1__input .begin-advance-input .check-form {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translate(-50%, -50%);
}

.contact-l9-1__input .get-start-btn .btn {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Contact Us page 
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-us-area {
  padding-top: 85px;
}

@media (min-width: 768px) {
  .contact-us-area {
    padding-top: 113px;
  }
}

@media (min-width: 992px) {
  .contact-us-area {
    padding-top: 158px;
  }
}

.contact-us-area .contact-us-banner {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .contact-us-area .contact-us-banner {
    margin-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .contact-us-area .contact-us-banner {
    margin-bottom: 70px;
  }
}

.contact-us-area .contact-us-banner h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .contact-us-area .contact-us-banner h2 {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .contact-us-area .contact-us-banner h2 {
    font-size: 60px;
  }
}

.contact-us-area .contact-us-banner p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.contact-us-form-area {
  position: relative;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .contact-us-form-area {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .contact-us-form-area {
    padding-bottom: 130px;
  }
}

.contact-us-form-area:after {
  position: absolute;
  content: '';
  background: #0D152E;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 75%;
}

.contact-us-form {
  box-shadow: 0 -20px 79px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  background-color: #fff;
  padding: 33px 30px 40px 30px;
}

@media (min-width: 576px) {
  .contact-us-form {
    padding: 55px 50px 55px 50px;
  }
}

@media (min-width: 768px) {
  .contact-us-form {
    padding: 55px 75px 55px 75px;
  }
}

.contact-us-form label {
  color: #0D152E;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.6px;
  line-height: normal;
  margin-bottom: 20px;
}

.contact-us-form .form-control::placeholder {
  color: #81838C;
  opacity: 1;
}

.contact-us-form .form-control {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  margin-bottom: 28px;
  padding-left: 15px;
}

.contact-us-form textarea {
  height: 220px !important;
  padding-top: 15px;
}

.contact-us-form .btn {
  width: 100%;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  text-align: center;
}

.contact-us-form .btn.access-btn {
  color: #0d0d0d;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  min-width: 111px;
  height: 46px;
  background-color: #fefffe;
  border-radius: 0px;
  border: 1px solid #0d0d0d;
}

.contact-us-form .btn.access-btn:hover {
  color: #fefffe !important;
  border: 1px solid #fefffe !important;
  background-color: #0d0d0d !important;
}

.contact-get-in-touch-area {
  margin-bottom: -30px;
  padding-top: 60px;
}

@media (min-width: 768px) {
  .contact-get-in-touch-area {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .contact-get-in-touch-area {
    padding-top: 120px;
  }
}

.contact-get-in-touch-area .get-in-touch-single {
  margin-bottom: 30px;
}

.contact-get-in-touch-area .get-in-touch-single .icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 8px;
  background-color: #101C3D;
}

.contact-get-in-touch-area .get-in-touch-single .content h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  margin-top: 35px;
  margin-bottom: 25px;
}

.contact-get-in-touch-area .get-in-touch-single .content p {
  color: #687497;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: 30px;
}

.contact-get-in-touch-area .get-in-touch-single .content a {
  text-decoration: none;
  font-weight: 700;
  color: #416ff4;
}

.contact-us-supp-btn .btn {
  width: 100%;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 30px;
}

@media (min-width: 576px) {
  .contact-us-supp-btn .btn {
    width: 180px;
  }
}

@media (min-width: 768px) {
  .contact-us-supp-btn .btn {
    margin-top: 60px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Sign In page 
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.sign-in-menu .navbar-toggler {
  display: none;
}

.sign-in-area {
  padding-top: 40px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .sign-in-area {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .sign-in-area {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .sign-in-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    right: 30px;
    width: 437px;
  }
}

@media (min-width: 1200px) {
  .sign-in-content {
    right: 105px;
    width: 465px;
  }
}

.sign-in-tesimonial-card {
  margin-top: 40px;
  padding: 30px;
}

@media (min-width: 768px) {
  .sign-in-tesimonial-card {
    padding: 45px;
  }
}

@media (min-width: 992px) {
  .sign-in-tesimonial-card {
    margin-top: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
  }
}

@media (min-width: 1200px) {
  .sign-in-tesimonial-card {
    left: 75px;
  }
}

.sign-in-tesimonial-card img {
  margin-right: 15px;
}

.sign-in-tesimonial-card h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.sign-in-tesimonial-card span {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.sign-in-tesimonial-card p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 32px;
  margin-top: 25px;
  margin-bottom: 30px;
}

@media (min-width: 480px) {
  .sign-in-tesimonial-card p {
    font-size: 20px;
    line-height: 34px;
  }
}

@media (min-width: 768px) {
  .sign-in-tesimonial-card p {
    margin-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .sign-in-tesimonial-card p {
    margin-bottom: 50px;
  }
}

.sign-in-tesimonial-card .testimonial__star {
  margin: 0px -4px 0px -4px;
}

.sign-in-tesimonial-card .testimonial__star i {
  font-size: 24px;
  color: #fbb040;
  margin: 0px 4px;
}

.sign-in-content h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 46px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .sign-in-content h2 {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .sign-in-content h2 {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 70px;
  }
}

.sign-in-content label {
  color: #0d152e;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.6px;
  line-height: normal;
  margin-bottom: 20px;
}

.sign-in-content .form-control::placeholder {
  color: #81838C;
  opacity: 1;
}

.sign-in-content .form-control {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  margin-bottom: 28px;
  padding-left: 15px;
}

.sign-in-content .check-form {
  margin-right: 15px;
}

.sign-in-content .check-form .remember-text {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.sign-in-content .check-form span {
  margin-right: 12px;
}

.forgot-pass-text h6 {
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.forgot-pass-text a {
  text-decoration: none;
  font-weight: 700;
}

.sign-in-log-btn .btn {
  width: 100%;
  height: 61px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 25px;
}

.create-new-acc-text p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 25px;
}

.create-new-acc-text a {
  text-decoration: none;
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

@media (min-width: 992px) {
  .sign-up-area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (min-width: 1600px) {
  .sign-up-area {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       About Us Banner
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.about-us-banner {
  padding-top: 100px;
  padding-bottom: 60px;
}

@media (min-width: 480px) {
  .about-us-banner {
    padding-top: 115px;
  }
}

@media (min-width: 768px) {
  .about-us-banner {
    padding-top: 137px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .about-us-banner {
    padding-top: 213px;
    padding-bottom: 120px;
  }
}

.about-us-banner-content h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: 48px;
}

@media (min-width: 576px) {
  .about-us-banner-content h2 {
    font-size: 40px;
    line-height: 52px;
  }
}

@media (min-width: 768px) {
  .about-us-banner-content h2 {
    font-size: 55px;
    line-height: 67px;
  }
}

@media (min-width: 992px) {
  .about-us-banner-content h2 {
    font-size: 60px;
    line-height: 72px;
  }
}

.about-us-banner-content p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 15px;
}

.about-us-banner-content .btn {
  width: 180px;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 33px;
}

.inner-pages-menu li .nav-link {
  color: #0D152E !important;
}

.log-in-btn-inner {
  color: #0D152E !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       About Us Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.about-content-1 {
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .about-content-1 {
    padding-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .about-content-1 {
    padding-bottom: 120px;
  }
}

.about-content-1 .about-content-1-right {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .about-content-1 .about-content-1-right {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .about-content-1 .about-content-1-right {
    margin-top: 50px;
  }
}

.about-content-1-bottom {
  margin-top: 45px;
}

@media (min-width: 768px) {
  .about-content-1-bottom {
    margin-top: 65px;
  }
}

@media (min-width: 992px) {
  .about-content-1-bottom {
    margin-top: 115px;
  }
}

.about-content-1-heading h2 {
  color: #0D152E;
}

.about-content-1-content {
  margin-bottom: -35px;
}

@media (min-width: 1600px) {
  .about-content-1-content {
    padding-right: 65px;
  }
}

.about-content-1-content p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-bottom: 35px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       About Us Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.about-content-2 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .about-content-2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .about-content-2 {
    padding-top: 110px;
    padding-bottom: 130px;
  }
}

.about-content-2-img-2 {
  margin-top: 25px;
}

@media (min-width: 992px) {
  .about-content-2-img-2 img {
    padding-left: 70px;
  }
}

.about-content-2-right-img {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .about-content-2-right-img {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .about-content-2-right-img {
    margin-top: 75px;
  }
}

.about-content-2-content {
  padding-top: 40px;
}

@media (min-width: 768px) {
  .about-content-2-content {
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .about-content-2-content {
    padding-left: 95px;
    padding-right: 45px;
  }
}

@media (min-width: 1600px) {
  .about-content-2-content {
    padding-right: 155px;
  }
}

.about-content-2-content p {
  margin-bottom: 32px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       About Us page Team
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.about-us-temam-area {
  padding-top: 45px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .about-us-temam-area {
    padding-top: 65px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .about-us-temam-area {
    padding-top: 115px;
    padding-bottom: 120px;
  }
}

.about-us-temam-area .section__heading {
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .about-us-temam-area .section__heading {
    margin-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .about-us-temam-area .section__heading {
    margin-bottom: 65px;
  }
}

.about-team-item {
  margin-bottom: -40px;
}

@media (min-width: 768px) {
  .about-team-item {
    margin-bottom: -70px;
  }
}

.about-team-item .col-lg-3 {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .about-team-item .col-lg-3 {
    margin-bottom: 70px;
  }
}

.about-team-card__content {
  margin-top: 28px;
}

.about-team-card__content h6 {
  color: #0D152E;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
}

.about-team-card__content span {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

@media (min-width: 576px) {
  .about-team-card__view-open {
    padding: 90px 22px;
  }
}

@media (min-width: 768px) {
  .about-team-card__view-open {
    padding: 167px 37px;
  }
}

@media (min-width: 992px) {
  .about-team-card__view-open {
    padding: 72px 22px;
  }
}

@media (min-width: 1200px) {
  .about-team-card__view-open {
    padding: 116px 22px;
  }
}

@media (min-width: 1366px) {
  .about-team-card__view-open {
    padding: 116px 22px;
  }
}

@media (min-width: 1600px) {
  .about-team-card__view-open {
    padding: 145px 22px;
  }
}

.about-team-card__view-open h4 {
  color: #0D152E;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: 30px;
}

.about-team-card__view-open .btn {
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  min-width: 88px;
  letter-spacing: -0.53px;
  line-height: normal;
  height: 22px;
  border-radius: 0;
  border-bottom: 2px solid #416ff4;
  padding: 0px 0px 15px 0px !important;
  margin-top: 25px;
}

.about-team-card__view-open .btn:hover {
  color: #416ff4 !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Career video Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.video-icon i {
  width: 82px;
  height: 82px;
  color: #fff;
  background-color: #416ff4;
  border-radius: 50%;
  text-align: center;
  line-height: 82px;
  position: relative;
  z-index: 1;
}

.career-video-area {
  padding: 150px 0px 150px 0px;
}

@media (min-width: 768px) {
  .career-video-area {
    padding: 250px 0px 250px 0px;
  }
}

@media (min-width: 992px) {
  .career-video-area {
    padding: 340px 0px 340px 0px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Career Feature Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.career-feature {
  padding-top: 45px;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .career-feature {
    padding-top: 65px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .career-feature {
    padding-top: 115px;
    padding-bottom: 105px;
  }
}

.career-feature .section__heading {
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .career-feature .section__heading {
    margin-bottom: 65px;
  }
}

@media (min-width: 992px) {
  .career-feature .section__heading {
    margin-bottom: 85px;
  }
}

.career-feature-card-items {
  margin-bottom: -30px;
}

.career-feature-card-items .col-lg-4 {
  margin-bottom: 30px;
}

.career-feature-card__icon svg {
  margin-right: 15px;
}

@media (min-width: 768px) {
  .career-feature-card__icon svg {
    margin-right: 35px;
  }
}

.career-feature-card__content h4 {
  color: #0D152E;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
}

.career-feature-card__content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Career Testimonial Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.career-testimonial-area {
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .career-testimonial-area {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .career-testimonial-area {
    padding-bottom: 130px;
  }
}

.career-testimonial-area .career-testimonial-content {
  padding-top: 40px;
}

@media (min-width: 768px) {
  .career-testimonial-area .career-testimonial-content {
    padding-top: 50px;
  }
}

@media (min-width: 992px) {
  .career-testimonial-area .career-testimonial-content {
    padding-top: 0px;
  }
}

@media (min-width: 1200px) {
  .career-testimonial-area .career-testimonial-content {
    padding-right: 76px;
  }
}

.career-testimonial-area .career-testimonial-content .quote {
  margin-bottom: 50px;
}

.career-testimonial-area .career-testimonial-content h4 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
}

.career-testimonial-area .career-testimonial-content h6 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 50px;
}

.career-testimonial-area .career-testimonial-content span {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Career Job opening Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.career-job-opening-area {
  padding-top: 45px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .career-job-opening-area {
    padding-top: 65px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .career-job-opening-area {
    padding-top: 115px;
    padding-bottom: 130px;
  }
}

.career-job-opening-area .section__heading {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .career-job-opening-area .section__heading {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .career-job-opening-area .section__heading {
    margin-bottom: 80px;
  }
}

.career-job-card-items {
  margin-bottom: -20px;
}

.career-job-card {
  transition: all 0.4s ease-in-out;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 35px 45px 27px 40px;
}

.career-job-card:hover {
  box-shadow: 0 30px 44px rgba(13, 21, 46, 0.09);
}

.career-job-card:hover .career-job-card__arrow a {
  color: #416ff4;
}

.career-job-card__content h6 {
  color: #0D152E;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
}

.career-job-card__arrow a {
  color: #000;
}

.career-job-card__arrow i {
  font-size: 20px;
  cursor: pointer;
}

.job-details {
  margin-top: 15px;
  margin-right: 20px;
}

.job-details ul {
  margin: 0px -14px -20px -14px;
}

.job-details ul li {
  display: inline-flex;
  margin: 0px 14px 20px 14px;
}

.job-details span {
  margin-left: 7px;
}

.career-job-opening-btn {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .career-job-opening-btn {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .career-job-opening-btn {
    margin-top: 100px;
  }
}

.career-job-opening-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Career Details Banner
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.career-details-banner {
  padding-top: 98px;
  padding-bottom: 45px;
}

@media (min-width: 480px) {
  .career-details-banner {
    padding-top: 115px;
  }
}

@media (min-width: 768px) {
  .career-details-banner {
    padding-top: 135px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .career-details-banner {
    padding-top: 213px;
    padding-bottom: 70px;
  }
}

.career-details-banner h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: 48px;
}

@media (min-width: 576px) {
  .career-details-banner h2 {
    font-size: 40px;
    line-height: 52px;
  }
}

@media (min-width: 768px) {
  .career-details-banner h2 {
    font-size: 55px;
    line-height: 67px;
  }
}

@media (min-width: 992px) {
  .career-details-banner h2 {
    font-size: 60px;
    line-height: 72px;
  }
}

.job-details__2 {
  margin-top: 35px;
}

.career-details-content-1 {
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  .career-details-content-1 {
    margin-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .career-details-content-1 {
    margin-bottom: 130px;
  }
}

.career-details-content-1 p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 25px;
}

.career-details-content-1 h4 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-top: 50px;
  margin-bottom: 50px;
}

.responsibilities-area span {
  font-weight: 700;
  font-size: 20px;
  margin-right: 10px;
}

.responsibilities-area li {
  color: #0D152E;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: 40px;
  display: inline-flex;
}

.carrer-apply-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 50px;
}

.apply-contact-form-area {
  padding-top: 45px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .apply-contact-form-area {
    padding-top: 65px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .apply-contact-form-area {
    padding-top: 115px;
    padding-bottom: 130px;
  }
}

.apply-contact-form-area h2 {
  color: #fff;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .apply-contact-form-area h2 {
    margin-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .apply-contact-form-area h2 {
    margin-bottom: 75px;
  }
}

.apply-contact-form {
  margin-bottom: -13px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 22px 25px 30px 25px;
}

@media (min-width: 480px) {
  .apply-contact-form {
    padding: 32px 35px 40px 35px;
  }
}

.apply-contact-form .form-control::placeholder {
  color: #81838C;
  opacity: 1;
}

.apply-contact-form .form-group {
  margin-bottom: 13px;
}

.apply-contact-form .form-control {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  padding-left: 16px;
}

.apply-contact-form label {
  color: #0D152E;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 15px;
}

.apply-contact-form .nice-select {
  float: inherit;
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  height: 50px;
  line-height: 50px;
}

.apply-contact-form .nice-select .list {
  width: 100%;
}

.apply-contact-form textarea {
  height: 180px !important;
  padding-top: 15px;
}

.apply-contact-form .btn {
  width: 100%;
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 25px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Case studies  Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.case-studies-menu {
  background: #fff;
}

.case-studies-area {
  padding-top: 120px;
  padding-bottom: 60px;
}

@media (min-width: 480px) {
  .case-studies-area {
    padding-top: 135px;
  }
}

@media (min-width: 768px) {
  .case-studies-area {
    padding-top: 155px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .case-studies-area {
    padding-top: 180px;
    padding-bottom: 130px;
  }
}

.case-studies-area-content h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .case-studies-area-content h2 {
    font-size: 55px;
  }
}

@media (min-width: 992px) {
  .case-studies-area-content h2 {
    font-size: 60px;
  }
}

.case-studies-area-content p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.case-studies-items {
  margin-bottom: -25px;
  padding-top: 40px;
}

@media (min-width: 768px) {
  .case-studies-items {
    padding-top: 50px;
  }
}

@media (min-width: 992px) {
  .case-studies-items {
    padding-top: 77px;
  }
}

.case-studies-items .col-lg-4 {
  margin-bottom: 25px;
}

.case-studies-card {
  height: 100%;
}

.case-studies-card__content {
  background: #fff;
  padding: 30px 35px 30px 35px;
}

.case-studies-card__content h4 {
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
  margin-bottom: 25px;
}

.case-studies-card__content a {
  color: #0D152E;
  text-decoration: none;
}

.case-studies-card__content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 20px;
}

.case-studies-card__content .btn {
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  padding: 0;
  min-width: inherit;
  height: inherit;
}

.case-studies-card__content .btn:hover {
  color: #416ff4 !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Case study details  Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.case-study-area {
  padding-top: 108px;
}

@media (min-width: 480px) {
  .case-study-area {
    padding-top: 135px;
  }
}

@media (min-width: 768px) {
  .case-study-area {
    padding-top: 153px;
  }
}

@media (min-width: 992px) {
  .case-study-area {
    padding-top: 215px;
    padding-bottom: 95px;
  }
}

.case-study-image {
  position: relative;
}

@media (min-width: 992px) {
  .case-study-image {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    width: 42%;
    height: 100%;
    background-position: 100%;
  }
  .case-study-image img {
    display: none;
  }
}

@media (min-width: 1200px) {
  .case-study-image {
    width: 42%;
  }
}

@media (min-width: 1600px) {
  .case-study-image {
    width: 37%;
    background-position: 100%;
  }
}

.case-study-banner-content {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .case-study-banner-content {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .case-study-banner-content {
    padding-top: 0;
    padding-right: 0px;
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .case-study-banner-content {
    padding-right: 28px;
  }
}

@media (min-width: 1600px) {
  .case-study-banner-content {
    padding-right: 135px;
  }
}

.case-study-banner-content h4 {
  color: #ff9653;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 35px;
}

.case-study-banner-content h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 48px;
}

@media (min-width: 576px) {
  .case-study-banner-content h2 {
    font-size: 40px;
    line-height: 52px;
  }
}

@media (min-width: 768px) {
  .case-study-banner-content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.time-line-area {
  margin: 40px -15px 0px -15px;
}

@media (min-width: 768px) {
  .time-line-area {
    margin: 50px -15px 0px -15px;
  }
}

@media (min-width: 992px) {
  .time-line-area {
    margin: 65px -15px 0px -15px;
  }
}

.time-line-area .time-line {
  margin: 0px 15px 0px 15px;
}

.time-line-area .heading h6 {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 25px;
}

.time-line-area .content span {
  margin-right: 10px;
}

.time-line-area .content li {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 10px;
}

.case-study-content-area {
  padding-top: 50px;
  padding-bottom: 37px;
}

@media (min-width: 768px) {
  .case-study-content-area {
    padding-top: 60px;
    padding-bottom: 57px;
  }
}

@media (min-width: 992px) {
  .case-study-content-area {
    padding-top: 78px;
    padding-bottom: 107px;
  }
}

.case-study-content-area .case-content-1 p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 30px;
}

.case-about-project {
  border-radius: 10px;
  background-color: #416ff4;
  padding: 32px 25px 18px 25px;
  margin: 15px 0px 45px 0px;
}

@media (min-width: 768px) {
  .case-about-project {
    padding: 32px 35px 18px 35px;
    margin: 30px 0px 65px 0px;
  }
}

@media (min-width: 992px) {
  .case-about-project {
    margin: 45px 25px 75px 25px;
  }
}

.case-about-project h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-bottom: 30px;
}

.case-about-project p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
}

.case-challenge-content h4 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-bottom: 35px;
}

.case-challenge-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 25px;
}

.solution-area {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .solution-area {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .solution-area {
    margin-top: 67px;
  }
}

.solution-area h4 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-bottom: 35px;
}

.solution-area span {
  font-weight: 700;
  font-size: 20px;
  margin-right: 10px;
}

.solution-area li {
  color: #0D152E;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: 40px;
  display: inline-flex;
}

.case-result-area {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .case-result-area {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .case-result-area {
    margin-top: 67px;
  }
}

.case-result-area h4 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-bottom: 35px;
}

.case-result-area p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
}

.case-study-counter-area {
  margin-top: 50px;
  margin-bottom: -30px;
}

.case-study-counter-area .counter-item {
  margin-bottom: 30px;
}

.case-study-counter-area .counter-item h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2.97px;
  line-height: normal;
}

@media (min-width: 768px) {
  .case-study-counter-area .counter-item h2 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .case-study-counter-area .counter-item h2 {
    font-size: 90px;
  }
}

.case-study-counter-area .counter-item p {
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 10px;
}

.other-case-study-area {
  padding-bottom: 60px;
  padding-top: 45px;
}

@media (min-width: 768px) {
  .other-case-study-area {
    padding-top: 65px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .other-case-study-area {
    padding-top: 115px;
    padding-bottom: 130px;
  }
}

.other-case-study-area h2 {
  margin-bottom: -12px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    SHop main     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.shop-banner {
  padding: 117px 0px 50px 0px;
}

@media (min-width: 480px) {
  .shop-banner {
    padding: 135px 0px 50px 0px;
  }
}

@media (min-width: 768px) {
  .shop-banner {
    padding: 160px 0px 65px 0px;
  }
}

@media (min-width: 992px) {
  .shop-banner {
    padding: 200px 0px 103px 0px;
  }
}

.shop-banner h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
}

@media (min-width: 576px) {
  .shop-banner h2 {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .shop-banner h2 {
    font-size: 60px;
  }
}

.sort-by-check {
  margin-bottom: 40px;
}

.sort-by-text h6 {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.sort-by-dropdowown .nice-select {
  background-color: #fff;
  border-radius: 0px;
  border: none;
  height: 42px;
  line-height: 40px;
  padding-left: 18px;
  padding-right: 30px;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
}

.sort-by-dropdowown .nice-select:after {
  height: 8px;
  width: 8px;
  right: 6px;
  margin-top: -6px;
}

.shop-main-area {
  padding-top: 48px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .shop-main-area {
    padding-top: 63px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .shop-main-area {
    padding-bottom: 130px;
  }
}

.shop-all-items {
  padding-top: 30px;
  margin-bottom: -45px;
}

@media (min-width: 768px) {
  .shop-all-items {
    padding-top: 0;
    margin-bottom: -58px;
  }
}

.shop-all-items .col-lg-4 {
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .shop-all-items .col-lg-4 {
    margin-bottom: 58px;
  }
}

.shop-all-items .shop-item .product-content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 20px;
}

.shop-all-items .shop-item .product-content h4 a {
  color: #0D152E;
  text-decoration: none;
}

.shop-all-items .shop-item .product-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 9px;
  margin-bottom: 12px;
}

.shop-all-items .shop-item .product-content span {
  color: #9EA1AB;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
  text-decoration: line-through;
  margin-left: 2px;
}

.product-rating ul {
  margin: 0px -2px 0px -2px;
}

.product-rating ul li {
  display: inline-flex;
  color: #ffac4d;
  margin: 0px 2px 0px 2px;
  cursor: pointer;
}

.search-form .form-control {
  height: 60px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  padding-left: 25px;
}

.search-form button[type=submit] {
  position: absolute;
  right: 0px;
  top: 50%;
  color: #9EA1AB;
  background-color: transparent;
  cursor: pointer;
  transform: translate(-50%, -50%);
  border: 0;
}

.latest-products h2 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 50px;
  margin-bottom: 35px;
}

.latest-products .latest-products-item {
  margin-bottom: 5px;
}

.latest-products .latest-products-item img {
  padding-right: 18px;
}

.latest-products .latest-products-item .latest-products-content h4 {
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
  text-align: left;
}

.latest-products .latest-products-item .latest-products-content h4 a {
  color: #0D152E;
  text-decoration: none;
}

.latest-products .latest-products-item .latest-products-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.latest-products .latest-products-item .latest-products-content span {
  color: #81838C;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
  text-align: left;
  text-decoration: line-through;
  margin-left: 3px;
}

.shop-categories {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .shop-categories {
    margin-top: 75px;
  }
}

.shop-categories h2 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 30px;
}

.shop-categories .categories-item ul li {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 25px;
}

.shop-categories .categories-item ul li a {
  color: #81838C;
  text-decoration: none;
}

.shop-pagination {
  padding-top: 40px;
}

@media (min-width: 768px) {
  .shop-pagination {
    padding-top: 60px;
  }
}

.shop-pagination ul {
  margin: 0px -3px 0px -3px;
}

.shop-pagination ul li {
  display: inline-flex;
  margin: 0px 3px 0px 3px;
}

.shop-pagination a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #EAECED;
  background-color: #fff;
  text-decoration: none;
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  transition: all 0.4s ease-in-out;
}

.shop-pagination a:hover {
  background: #416ff4;
  color: #fff;
}

.shop-pagination a.active {
  background: #416ff4;
  color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    SHop single      CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.single-product-slider-area {
  padding-top: 112px;
  padding-bottom: 35px;
}

@media (min-width: 480px) {
  .single-product-slider-area {
    padding-top: 125px;
    padding-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .single-product-slider-area {
    padding-top: 150px;
    padding-bottom: 55px;
  }
}

@media (min-width: 992px) {
  .single-product-slider-area {
    padding-top: 170px;
    padding-bottom: 65px;
  }
}

.product-details-vertical-slider .single-slide {
  margin: 15px 4px 0px 4px;
}

@media (min-width: 768px) {
  .product-details-vertical-slider .single-slide {
    margin: 4px 0px;
  }
}

.single-product-details-area {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .single-product-details-area {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .single-product-details-area {
    padding-top: 0px;
  }
}

.single-product-details-area h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
  margin-top: 8px;
  margin-bottom: 13px;
}

.single-product-details-area .single-product-special-rate span {
  color: #2bd67b;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
  display: inline-block;
  margin-bottom: 10px;
}

.single-product-details-area .single-product-discount p {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: normal;
  display: flex;
  align-items: center;
}

.single-product-details-area .single-product-discount span {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  text-align: left;
  text-decoration: line-through;
  margin-left: 13px;
}

.single-product-details-area .single-product-content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 20px;
}

.single-product-details-area .single-product-identity .product-iden-title {
  width: 31%;
}

.single-product-details-area .single-product-identity .product-iden-descrip {
  width: 69%;
}

.single-product-details-area .single-product-identity h6 {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 21px;
  text-align: left;
}

.single-product-details-area .single-product-identity p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.single-product-btn {
  margin: 20px -8px -15px -8px;
}

.single-product-btn a {
  text-decoration: none;
}

.single-product-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin: 0px 8px 15px 8px;
}

.single-product-btn .sin-pro-buy-btn {
  background-color: #416ff4;
  color: #fff;
}

.single-product-btn .sin-pro-cart-btn {
  border: 1px solid #416ff4;
  color: #416ff4;
}

.single-product-btn .sin-pro-cart-btn:hover {
  color: #416ff4 !important;
}

.product-des-tab-content {
  margin-top: 30px;
}

.product-des-tab-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 22px;
}

@media (min-width: 1600px) {
  .product-des-tab-content p {
    padding-right: 55px;
  }
}

.product-description-area {
  padding-bottom: 30px;
}

@media (min-width: 768px) {
  .product-description-area {
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .product-description-area {
    padding-bottom: 100px;
  }
}

.product-description-tabs .nav-link {
  color: #81838C;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-top: 20px;
  margin-bottom: -1px;
  border: 0;
  margin-right: 30px;
}

@media (min-width: 480px) {
  .product-description-tabs .nav-link {
    font-size: 20px;
  }
}

@media (min-width: 576px) {
  .product-description-tabs .nav-link {
    margin-right: 60px;
  }
}

@media (min-width: 768px) {
  .product-description-tabs .nav-link {
    margin-right: 116px;
  }
}

.product-description-tabs .nav-link:last-child {
  margin-right: 0;
}

.product-description-tabs .nav-link.active {
  border-bottom: 1px solid #0D152E;
  color: #0D152E;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.related-products {
  padding-bottom: 55px;
}

@media (min-width: 768px) {
  .related-products {
    padding-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .related-products {
    padding-bottom: 125px;
  }
}

.related-products .section__heading {
  padding-top: 45px;
}

@media (min-width: 768px) {
  .related-products .section__heading {
    padding-top: 65px;
    padding-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .related-products .section__heading {
    padding-top: 90px;
    padding-bottom: 50px;
  }
}

.related-products .shop-all-items {
  margin-bottom: -45px;
}

@media (min-width: 768px) {
  .related-products .shop-all-items {
    margin-bottom: -58px;
  }
}

.related-products .shop-all-items .col-lg-3 {
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .related-products .shop-all-items .col-lg-3 {
    margin-bottom: 58px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    SHop Cart      CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.shop-cart-area {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .shop-cart-area {
    padding-top: 75px;
  }
}

.product-cart-all-acrd-items {
  margin-bottom: -30px;
}

.cart-product-body {
  margin-bottom: 30px;
  padding: 30px 0px;
  border-top: 1px solid #EAECED;
  border-bottom: 1px solid #EAECED;
}

.cart-details-main-block h3 {
  color: #0D152E;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.cart-details-main-block h2 {
  color: #182748;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 25px;
  text-align: left;
  padding-right: 30px;
}

@media (min-width: 576px) {
  .cart-details-main-block h2 {
    padding-right: 75px;
  }
}

@media (min-width: 768px) {
  .cart-details-main-block h2 {
    padding-right: 37px;
  }
}

@media (min-width: 992px) {
  .cart-details-main-block h2 {
    padding-right: 100px;
  }
}

.cart-details-main-block h4 {
  color: #182748;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.cart-details-main-block .product-image {
  margin-right: 20px;
}

.quantity-block button {
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.quantity-block .form-control {
  width: 50px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #EAECED;
  background-color: #fff;
  margin: 0px 10px;
}

.input--amount-control ::-webkit-inner-spin-button {
  display: none;
}

.cart-details-main-block .cart-product .product-block {
  margin-bottom: 30px;
  flex-basis: 100%;
}

@media (min-width: 480px) {
  .cart-details-main-block .cart-product .product-block {
    flex-basis: 85%;
  }
}

@media (min-width: 576px) {
  .cart-details-main-block .cart-product .product-block {
    flex-basis: 100%;
  }
}

@media (min-width: 768px) {
  .cart-details-main-block .cart-product .product-block {
    margin-bottom: 0;
    flex-basis: 45%;
  }
}

@media (min-width: 992px) {
  .cart-details-main-block .cart-product .product-block {
    flex-basis: 50%;
  }
}

@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .product-block {
    flex-basis: 40%;
  }
}

@media (min-width: 576px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 15%;
  }
}

@media (min-width: 768px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 15%;
  }
}

@media (min-width: 992px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 15%;
  }
}

@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 15%;
  }
}

.cart-details-main-block .cart-product .price-block {
  flex-basis: 20%;
}

@media (min-width: 768px) {
  .cart-details-main-block .cart-product .price-block {
    flex-basis: 15%;
  }
}

@media (min-width: 992px) {
  .cart-details-main-block .cart-product .price-block {
    flex-basis: 15%;
  }
}

@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .price-block {
    flex-basis: 15%;
  }
}

.cart-details-main-block .cart-product .quantity-block {
  flex-basis: 55%;
}

@media (min-width: 480px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 40%;
  }
}

@media (min-width: 576px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 40%;
  }
}

@media (min-width: 768px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 25%;
  }
}

@media (min-width: 992px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 25%;
  }
}

@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 25%;
  }
}

@media (min-width: 576px) {
  .cart-details-main-block .cart-product .total-block {
    flex-basis: 15%;
  }
}

@media (min-width: 768px) {
  .cart-details-main-block .cart-product .total-block {
    flex-basis: 15%;
  }
}

@media (min-width: 992px) {
  .cart-details-main-block .cart-product .total-block {
    flex-basis: 10%;
  }
}

@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .total-block {
    flex-basis: 20%;
  }
}

.cart-details-main-block .cart-product .cross-btn-positioning {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.continue-shop-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #416ff4;
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.continue-shop-btn .btn:hover {
  color: #416ff4 !important;
}

.continue-show-area {
  padding-top: 45px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .continue-show-area {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .continue-show-area {
    padding-bottom: 130px;
  }
}

.order-details-card {
  border-radius: 3px;
  border: 1px solid #EAECED;
  background-color: #F7F9FB;
  padding: 20px 20px 25px 20px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .order-details-card {
    margin-top: 0;
  }
}

.order-details-card h3 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 35px;
}

.order-details-card p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 19px;
}

.order-details-card strong {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.order-details-card .free-color {
  color: #2bd67b;
}

.order-details-card h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}

.order-details-card .total-price-area {
  margin-top: 15px;
}

.proceed-check-btn .btn {
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  width: 100%;
  margin-top: 5px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    SHop Checkout      CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.shop-checkout-area {
  padding-top: 50px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .shop-checkout-area {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .shop-checkout-area {
    padding-top: 80px;
    padding-bottom: 130px;
  }
}

.shop-checkout-area .order-details-card {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .shop-checkout-area .order-details-card {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .shop-checkout-area .order-details-card {
    margin-top: 0;
  }
}

.checkout-form-area h3 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-bottom: 20px;
}

.checkout-form-area label {
  color: #0D152E;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 20px;
}

.checkout-form-area .form-control {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  padding-left: 15px;
}

.checkout-form-area .form-control ::placeholder {
  color: #81838C;
  opacity: 1;
}

.checkout-form-area .another-card-add {
  margin-top: 33px;
}

.checkout-form-area .checkout-terms-text p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin: 30px 0px;
}

.checkout-form-area .checkout-terms-text a {
  color: #0D152E;
  text-decoration: underline;
}

.checkout-form-area .invisible {
  margin: 0;
}

@media (min-width: 576px) {
  .checkout-form-area .invisible {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.checkout-form-area .payment-card-img {
  width: 48%;
}

@media (min-width: 576px) {
  .checkout-form-area .payment-card-img {
    width: initial;
  }
}

.payment-form-control {
  height: 71px !important;
}

.payment-form-control label {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1px;
}

.payment-form-control span {
  margin-right: 15px;
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.visa-card-position {
  position: absolute;
  top: 0;
  right: 10px;
}

.question-icon-position {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.checkout-form-area .payment-head {
  margin-top: 55px;
  margin-bottom: 25px;
}

.checkout-form-area .nice-select {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
}

.checkout-form-area .nice-select .list {
  width: 100%;
  height: 180px;
  overflow-y: scroll;
}

.checkout-form-area .nice-select:after {
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  width: 6px;
  height: 6px;
  right: 16px;
}

.complte-checkout-btn .btn {
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Terms & conditions    CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.terms-condition-banner {
  padding: 98px 0px 35px 0px;
}

@media (min-width: 576px) {
  .terms-condition-banner {
    padding: 115px 0px 35px 0px;
  }
}

@media (min-width: 768px) {
  .terms-condition-banner {
    padding: 135px 0px 60px 0px;
  }
}

@media (min-width: 992px) {
  .terms-condition-banner {
    padding: 163px 0px 80px 0px;
  }
}

.terms-condition-banner h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
}

@media (min-width: 576px) {
  .terms-condition-banner h2 {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .terms-condition-banner h2 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .terms-condition-banner h2 {
    font-size: 60px;
  }
}

.terms-condition-area {
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .terms-condition-area {
    margin-bottom: 45px;
  }
}

@media (min-width: 992px) {
  .terms-condition-area {
    margin-bottom: 95px;
  }
}

.terms-license-area {
  margin-bottom: 35px;
  margin-top: 40px;
}

.terms-license-area span {
  font-weight: 700;
  font-size: 20px;
  margin-right: 10px;
}

.terms-license-area li {
  color: #0D152E;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: 40px;
  display: inline-flex;
}

.terms-condition-area h4 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-bottom: 30px;
}

.terms-condition-area p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 25px;
}

.terms-con-content-3 {
  margin-top: 40px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Coming Soon page    CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.coming-soon-page {
  padding-top: 40px;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .coming-soon-page {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .coming-soon-page {
    padding-top: 190px;
    padding-bottom: 225px;
  }
}

.coming-soon-content h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 46px;
  margin-bottom: 23px;
}

@media (min-width: 576px) {
  .coming-soon-content h2 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (min-width: 768px) {
  .coming-soon-content h2 {
    font-size: 48px;
    line-height: 60px;
  }
}

.coming-soon-content p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.countdown-item-area {
  width: 100%;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .countdown-item-area {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .countdown-item-area {
    margin-top: 70px;
  }
}

.countdown-item-area .coutn-item {
  width: 25%;
  margin-right: 25px;
}

@media (min-width: 768px) {
  .countdown-item-area .coutn-item {
    margin-right: 45px;
  }
}

.countdown-item-area h6 {
  color: #0D152E;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0;
}

.countdown-item-area h1 {
  color: #0D152E;
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
}

@media (min-width: 480px) {
  .countdown-item-area h1 {
    font-size: 70px;
  }
}

@media (min-width: 576px) {
  .countdown-item-area h1 {
    font-size: 90px;
  }
}

@media (min-width: 768px) {
  .countdown-item-area h1 {
    font-size: 120px;
    letter-spacing: -3.96px;
  }
}

.countdown-item-area #seconds {
  color: #416ff4;
}

@media (min-width: 768px) {
  .coming-soon-form-area {
    margin-top: 30px;
  }
}

.coming-soon-form-area label {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 25px;
}

.coming-soon-form-area .com-soon-form {
  width: 100%;
  margin-right: 15px;
}

@media (min-width: 576px) {
  .coming-soon-form-area .com-soon-form {
    width: 394px;
  }
}

.coming-soon-form-area .com-soon-form .form-control {
  height: 60px;
  border-radius: 6px;
  border: 1px solid #EAECED;
  background-color: #fff;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.coming-soon-form-area .com-soon-form .form-control::placeholder {
  color: #81838C;
}

.com-soon-form-btn .btn {
  height: 60px;
  border-radius: 6px;
  background-color: #416ff4;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .com-soon-form-btn .btn {
    margin-top: 0;
    min-width: 180px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Thank you page    CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.thank-you-page {
  padding-top: 108px;
  padding-bottom: 60px;
}

@media (min-width: 480px) {
  .thank-you-page {
    padding-top: 125px;
  }
}

@media (min-width: 576px) {
  .thank-you-page {
    padding-top: 130px;
  }
}

@media (min-width: 768px) {
  .thank-you-page {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .thank-you-page {
    padding-top: 245px;
    padding-bottom: 175px;
  }
}

.thankyou-content h2 {
  color: #0D152E;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2.97px;
  line-height: normal;
  margin-top: 30px;
}

@media (min-width: 576px) {
  .thankyou-content h2 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .thankyou-content h2 {
    font-size: 90px;
  }
}

.thankyou-content p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.back-to-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #416ff4;
  color: #416ff4;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 25px;
}

.back-to-btn .btn:hover {
  color: #416ff4 !important;
}

.back-to-btn a {
  text-decoration: none;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Portfolio main     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.portfolio-banner {
  margin-top: 70px;
}

@media (min-width: 480px) {
  .portfolio-banner {
    margin-top: 87px;
  }
}

@media (min-width: 576px) {
  .portfolio-banner {
    margin-top: 97px;
  }
}

@media (min-width: 992px) {
  .portfolio-banner {
    margin-top: 145px;
  }
}

@media (min-width: 1200px) {
  .portfolio-banner {
    margin-top: 100px;
  }
}

.portfolio-banner .portfolio-banner-content {
  padding: 40px 0px;
}

@media (min-width: 768px) {
  .portfolio-banner .portfolio-banner-content {
    padding: 60px 0px;
  }
}

.portfolio-banner .portfolio-banner-content h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
}

@media (min-width: 576px) {
  .portfolio-banner .portfolio-banner-content h2 {
    font-size: 60px;
  }
}

@media (min-width: 768px) {
  .portfolio-banner .portfolio-banner-content h2 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .portfolio-banner .portfolio-banner-content h2 {
    font-size: 60px;
  }
}

.portfolio-banner .portfolio-banner-content p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.portfolio-banner-content--2 {
  padding: 0 !important;
}

.portfolio-details-banner--2 {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .portfolio-details-banner--2 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .portfolio-details-banner--2 {
    padding-top: 120px;
  }
}

.portfolio-card-masonry {
  position: relative;
}

.portfolio-navbar {
  padding: 30px 0px;
}

@media (min-width: 768px) {
  .portfolio-navbar {
    padding: 50px 0px;
  }
}

.portfolio-navbar ul li a {
  color: #6f727b;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.63px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

.link-hover-light:hover {
  color: #495fef;
}

.isotope-nav li a.active {
  color: #495fef;
}

.portfolio-card-masonry:hover .text-block {
  bottom: 20px;
  opacity: 1;
}

.portfolio-card-masonry .text-block {
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
  opacity: 0;
  transition: .4s;
  padding: 30px 15px 15px 30px;
}

.nogap-btn-margin {
  margin-top: 45px;
}

.portfolio-card-hover .card-image {
  transition: .4s;
}

.portfolio-card-hover .card-image img {
  transition: .4s;
}

.portfolio-card-hover:hover .card-image {
  transform: scale(0.8) rotate(-16deg);
}

.portfolio-card-hover:hover img {
  border-radius: 20px;
  box-shadow: 0 32px 74px rgba(68, 77, 136, 0.2);
}

.link-hover-reset:hover {
  color: var(--color-text);
}

.link-hover-rotate {
  transition: .4s;
  display: inline-flex;
  will-change: transform;
  transform: rotate(0deg);
}

.link-hover-rotate:hover {
  transform: rotate(-10deg);
}

.isotope-item {
  transition: .4s;
}

.gr-bg-opacity {
  z-index: 1;
}

.gr-bg-opacity:before {
  position: absolute;
  content: "";
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: .9;
  border-radius: 8px;
}

.gr-pb-7,
.gr-py-7 {
  padding-bottom: 25px !important;
}

.portfolio-card-masonry span {
  color: #161c2d;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.63px;
  line-height: normal;
  opacity: 0.5;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.portfolio-card-masonry h3 {
  color: #161c2d;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.2px;
  line-height: 44px;
}

@media (min-width: 768px) {
  .portfolio-card-masonry h3 {
    font-size: 26px;
  }
}

.portfolio-card-masonry-1 a {
  text-decoration: none;
}

.content-area-grid-1 span {
  color: #161c2d;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.63px;
  line-height: normal;
  opacity: 0.5;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.content-area-grid-1 h3 {
  color: #161c2d;
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.2px;
  line-height: 44px;
}

.content-area-grid-1 {
  padding: 30px 0px 40px;
}

@media (min-width: 768px) {
  .content-area-grid-1 {
    padding: 47px 0px 68px;
  }
}

.portfolio-load-btn .btn {
  min-width: 225px;
  height: 60px;
  border-radius: 33px;
  border: 1px solid #030303;
  opacity: 0.3;
  color: #030303;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.63px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}

.portfolio-load-btn .btn:hover {
  color: #0D152E !important;
  opacity: 1;
}

.grid--no-gap .col-md-6 {
  padding: 0;
}

.grid--no-gap .col-sm-6 {
  padding: 0;
}

.grid--no-gap .col-lg-4 {
  padding: 0;
}

.grid--no-gap .col-lg-3 {
  padding: 0;
}

.grid--no-gap .col-12 {
  padding: 0;
}

.portfolio-details-banner {
  margin-top: 70px;
  padding-bottom: 60px;
}

@media (min-width: 480px) {
  .portfolio-details-banner {
    margin-top: 87px;
  }
}

@media (min-width: 576px) {
  .portfolio-details-banner {
    margin-top: 97px;
  }
}

@media (min-width: 768px) {
  .portfolio-details-banner {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .portfolio-details-banner {
    margin-top: 145px;
    padding-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .portfolio-details-banner {
    margin-top: 100px;
  }
}

.portfolio-details-banner .portfolio-banner-content {
  padding: 40px 0px;
}

@media (min-width: 768px) {
  .portfolio-details-banner .portfolio-banner-content {
    padding: 60px 0px;
  }
}

.portfolio-details-banner .portfolio-banner-content h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
}

@media (min-width: 576px) {
  .portfolio-details-banner .portfolio-banner-content h2 {
    font-size: 60px;
  }
}

@media (min-width: 768px) {
  .portfolio-details-banner .portfolio-banner-content h2 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .portfolio-details-banner .portfolio-banner-content h2 {
    font-size: 60px;
  }
}

.portfolio-details-banner .portfolio-banner-content p {
  color: #EAECED;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  opacity: 0.5;
}

.portfolio-details-banner-card {
  border-radius: 8px;
  background-color: #fff;
  padding: 30px 30px 30px 30px;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .portfolio-details-banner-card {
    padding: 50px 45px 50px 45px;
    margin-top: 35px;
  }
}

@media (min-width: 992px) {
  .portfolio-details-banner-card {
    margin-top: 0;
  }
}

.portfolio-details-banner-card .card-all-items {
  margin-bottom: -30px;
}

.portfolio-details-banner-card .card-item {
  margin-right: 15px;
  margin-bottom: 30px;
}

.portfolio-details-banner-card .card-item span {
  opacity: 0.5;
  color: #161c2d;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-right: 15px;
  min-width: 35%;
}

@media (min-width: 576px) {
  .portfolio-details-banner-card .card-item span {
    min-width: 25%;
  }
}

@media (min-width: 768px) {
  .portfolio-details-banner-card .card-item span {
    min-width: 20%;
  }
}

.portfolio-details-banner-card .card-item strong {
  color: #161c2d;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 17px;
}

.portfolio--details-section {
  padding-top: 60px;
}

@media (min-width: 768px) {
  .portfolio--details-section {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .portfolio--details-section {
    padding-top: 115px;
  }
}

.portfolio-single-items {
  margin-bottom: -25px;
}

.portfolio-single-items .col-lg-12 {
  margin-bottom: 25px;
}

.portfolio-single-items .col-lg-6 {
  margin-bottom: 25px;
}

.pre-next-project-link-area {
  padding: 50px 0px;
  margin-bottom: -20px;
}

.pre-next-project-link-area .project-m {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .pre-next-project-link-area {
    padding: 70px 0px;
  }
}

.pre-next-project-link-area .icon {
  margin-right: 15px;
}

.pre-next-project-link-area .icon i {
  color: #262626;
  font-size: 13px;
  width: 21px;
  height: 21px;
  line-height: 19px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid #262626;
}

.pre-next-project-link-area a {
  opacity: 0.5;
  color: #161c2d;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.63px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
}

.pre-next-project-link-area .next-project .icon {
  margin-left: 15px;
  margin-right: 0;
}

.pre-next-project-link-area .project-page-grid i {
  font-size: 20px;
}

.related-works-area {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .related-works-area {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .related-works-area {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

.related-works-area .related-works-title {
  margin-bottom: 38px;
}

@media (min-width: 768px) {
  .related-works-area .related-works-title {
    margin-bottom: 58px;
  }
}

@media (min-width: 992px) {
  .related-works-area .related-works-title {
    margin-bottom: 68px;
  }
}

.related-works-area .related-works-title h2 {
  color: #161c2d;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.2px;
  line-height: 48px;
}

.related-works-area-items {
  margin-bottom: -30px;
}

.related-works-area-items .col-xl-3 {
  margin-bottom: 30px;
}

.related-work-content {
  margin-top: 32px;
}

.related-work-content span {
  display: inline-block;
  color: #161c2d;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.63px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 13px;
}

.related-work-content span a {
  opacity: 0.5;
  color: #161c2d;
  text-decoration: none;
}

.related-work-content strong {
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 34px;
  display: inherit;
}

.related-work-content strong a {
  color: #161c2d;
  text-decoration: none;
}

.pt-90 {
  padding-top: 45px;
}

@media (min-width: 768px) {
  .pt-90 {
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .pt-90 {
    padding-top: 90px;
  }
}
