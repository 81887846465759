/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area {
    @include mobile-xs {
        padding-top: 45px;
    }

    @include tablet {
        padding-top: 65px;
    }

    @include desktops {
        padding-top: 115px;
    }

    & .feature--card {
        border: 0;

        @include mobile-xs {
            padding-top: 40px;
        }

        @include tablet {
            padding-top: 60px;
        }

        @include desktops {
            padding-top: 85px;
        }
    }

    & .card__icon {
        margin: auto;
        width: 70px;
        height: 70px;
        box-shadow: 0 22px 24px rgba(65, 111, 244, 0.2);
        border-radius: 9px;
        background-color: $primary;

        &__2 {
            box-shadow: 0 22px 24px rgba(43, 214, 123, 0.2);
            background-color: #2bd67b;
        }

        &__3 {
            box-shadow: 0 22px 24px rgba(247, 227, 109, 0.2);
            background-color: #f7e36d;
        }

        & i {
            color: $white;
            line-height: 70px;
            font-size: 25px;
        }

    }

    & .card-body {
        & .card__title {
            margin-bottom: 18px;
        }

        & h4 {
            margin-top: 20px;
            color: $haiti;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.66px;
            line-height: normal;
        }

        & p {
            color: #81838c;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.5px;
            line-height: 28px;
        }


    }

}

.feature-l2-try-btn {
    .btn {
        min-width: 180px;
        height: 60px;
        border-radius: 6px;
        background-color: $primary;
        color: #f4f7fa;
        font-size: 13px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 1.63px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
        margin-top: 55px;
    }
}

.feature-area-2 {
    @include mobile-xs {
        padding-top: 0px;
    }

    @include desktops {
        padding-top: 130px;
    }

    @include large-desktops {
        padding-top: 170px;
    }

    .section__heading {
        p {
            @include large-desktops {
                margin-right: 0px;
            }

            @include large-desktops-mid {
                margin-right: 0px;
            }

            @include extra-large-desktops {
                margin-right: 70px;
            }
        }
    }

    .feature-area-2-content {
        @include mobile-xs {
            padding-top: 30px;
        }

        @include tablet {
            padding-top: 40px;
        }

        @include desktops {
            padding-top: 0px;
        }

        .feature--item {
            margin-top: 50px;
            margin-bottom: -30px;

            .col-lg-7{
                margin-bottom: 30px;
            }
        }

        .widget {
            .icon__box {
                text-align: center;
                width: 60px;
                height: 60px;
                line-height: 60px;
                box-shadow: 8px 11px 26px rgba(145, 145, 145, 0.16);
                border-radius: 10px;


                svg {
                    width: 16px;
                }
            }

            .widget-body {
                margin-left: 27px;

                h6 {
                    color: $haiti;
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: -0.66px;
                    font-size: 20px;
                    line-height: 30px;
                }
            }

        }
    }

    &-img-group {
        position: relative;

        .image__1 {
            @include desktops {
                margin-left: 30px;
            }

            @include large-desktops {
                margin-left: 70px;
            }

            & img {
                box-shadow: -59px 3px 99px rgba(129, 129, 129, 0.16);
                border-radius: 10px;

                @include mobile-xs {
                    width: 100%;
                }

                @include desktops {
                    width: initial;
                }
            }
        }



    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area-3 {
    padding-top: 30px;
    padding-bottom: 35px;

    @include tablet {
        padding-bottom: 55px;
    }

    @include desktops {
        padding-bottom: 105px;
    }

    .feature-area-3-image-group {
        position: relative;

        .image-1 {
            img {
                box-shadow: 32px 51px 84px rgba(111, 118, 138, 0.16);
                border-radius: 30px;
            }
        }

        .image-2 {
            position: absolute;
            top: -5%;
            left: 0%;
            z-index: -1;

            @include mobile-xs {
                display: none;
            }

            @include mobile {
                display: block;
                left: 14%;
            }

            @include mobile-lg {
                left: 18%;
            }

            @include desktops {
                left: 13%;
            }

            @include large-desktops {
                left: 20%;
            }


            @include extra-large-desktops {
                left: 24%;
            }

        }

        .image-3 {
            position: absolute;
            top: 50%;
            left: 0%;

            @include mobile {
                left: 8%;
            }

            @include mobile-lg {
                left: 13%;
            }

            @include large-desktops {
                left: 7%;
            }

            @include large-desktops-mid {
                left: 4%;
            }

            @include extra-large-desktops {
                left: 13%;
            }

            img {
                box-shadow: -15px 24px 78px rgba(111, 118, 138, 0.16);
                border-radius: 5px;

                @include mobile-xs {
                    display: none;
                }

                @include mobile {
                    display: block;
                    transform: scale(.8);
                }

                @include mobile-lg {
                    transform: scale(1);
                }

                @include desktops {
                    transform: scale(.8);
                }

                @include large-desktops {
                    transform: scale(1);
                }
            }
        }

        .image-4 {
            position: absolute;

            @include mobile-xs {
                position: absolute;
                top: 60%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            @include mobile {
                top: 50%;
                right: 10%;
                left: inherit;
                transform: inherit
            }

            @include desktops {
                right: 4%;
            }

            @include large-desktops {
                right: 18%;
            }

            @include large-desktops-mid {
                right: 22%;
            }

            img {
                box-shadow: -15px 24px 78px rgba(111, 118, 138, 0.16);
                border-radius: 10px;

                @include mobile {
                    transform: scale(.8);
                }

                @include mobile-lg {
                    transform: scale(1);
                }

                @include desktops {
                    transform: scale(.8);
                }

                @include large-desktops {
                    transform: scale(1);
                }
            }
        }
    }

    .feature-area-3-content {
        padding-top: 35px;

        @include large-desktops {
            padding-top: 0px;
            margin-right: 75px;
        }

        @include extra-large-desktops {
            margin-right: 165px;
        }

        h2 {
            color: $haiti;
            font-size: 36px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -1.58px;
            line-height: 47px;

            @include mobile-lg {
                font-size: 48px;
                line-height: 60px;
            }
        }

        p {
            color: #81838C;
            font-size: 20px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.66px;
            line-height: 34px;
            margin-top: 25px
        }

        .feature-area-3-service {
            margin-top: 53px;

            ul {
                margin-bottom: -23px;

                li {
                    margin-bottom: 23px;
                }
            }

            .service-icon {
                border-radius: 10px;
                margin-right: 32px;

                i {
                    height: 60px;
                    width: 60px;
                    text-align: center;
                    font-size: 25px;
                    line-height: 60px;
                    transform: rotate(-45deg);
                }
            }

            .service-content {
                margin-top: -6px;

                h4 {
                    color: $haiti;
                    font-size: 20px;
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: -0.66px;
                    line-height: normal;
                }

                p {
                    color: $waterloo;
                    font-size: 16px;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: -0.53px;
                    line-height: 28px;
                    margin-top: 20px;
                }
            }
        }
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-3
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area-l3-1 {
    padding-top: 60px;

    @include tablet {
        padding-top: 80px;
    }

    @include desktops {
        padding-top: 130px;
    }

    .section__heading {
        i {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            color: $white;
            font-size: 20px;
            border-radius: 20px;
            background-color: $secondary;
            margin-bottom: 25px;
        }

    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-4 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area-l4 {

    .heading {
        h2 {
            color: $haiti;
            font-size: 34px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -1.12px;
            line-height: normal;
        }
    }
}

.feature-l4-widget-items {
    margin-top: 40px;

    @include tablet {
        margin-top: 50px;
    }

    @include desktops {
        margin-top: 70px;
    }

    margin-bottom: -40px;

    .col-lg-4 {
        margin-bottom: 40px;
    }
}

.feature-l4-widget {
    &__icon {
        & img {
            margin-right: 30px;
            width: 30px;

            @include tablet {
                width: initial;
            }
        }
    }

    &__content {
        h4 {
            color: $haiti;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.66px;
            line-height: 30px;
        }

        p {
            color: $waterloo;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: 28px;

            @include tablet {
                padding-right: 0px;
            }


            @include extra-large-desktops {
                padding-right: 26px;
            }
        }
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-4 Feature-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.video-feature-l4-items {
    margin-top: 50px;

    @include tablet {
        margin-top: 70px;
    }

    @include desktops {
        margin-top: 100px;
    }

    margin-bottom: -40px;

    .col-lg-4 {
        margin-bottom: 40px;
    }
}

.video-fea-l4-widget {
    &__icon {
        i {
            text-align: center;
            width: 60px;
            height: 60px;
            line-height: 60px;
            color: $white;
            font-size: 20px;
            border-radius: 50%;
            box-shadow: 7px 7px 14px rgba(65, 111, 244, 0.1);
            background-color: $primary;
            margin-right: 25px;
        }
    }

    &__content {
        h4 {
            color: $bunting_2;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.5px;
            line-height: normal;
            text-align: left;
        }

        p {
            color: $waterloo;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: 28px;
            margin-top: 30px;

            @include tablet {
                padding-right: 45px;
            }

            @include desktops {
                padding-right: 0px;
            }

            @include extra-large-desktops {
                padding-right: 47px;
            }
        }
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-4 Feature-3
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.doctor-list-area-l4 {
    position: relative;
    padding-bottom: 60px;

    @include tablet {
        padding-bottom: 80px;
    }

    @include desktops {
        padding-bottom: 130px;
    }

    &:after {
        position: absolute;
        content: "";
        background: $catskillwhite;
        height: 85%;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: -1;

        @include tablet {
            height: 80%;
        }

        @include desktops {
            height: 76%;
        }

        @include large-desktops {
            height: 67%;
        }
    }
}

.doctor-list-l4-item {
    margin-bottom: -75px;

    padding-top: 50px;

    @include tablet {
        padding-top: 70px;
    }

    @include desktops {
        padding-top: 100px;
    }

    .col-xxl-3 {
        margin-bottom: 75px;
    }

    .card-doctor-identity {
        border: 0;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background-color: $white;
        height: 100%;
        padding: 0px 15px 20px 15px;
        transition: all 0.4s ease-in-out;

        &:hover {
            box-shadow: 0 33px 77px rgba(0, 0, 0, 0.13);
        }
    }

    &__image {
        margin-top: -40px;
    }

    &__content {
        h4 {
            color: $haiti;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.66px;
            line-height: normal;
            margin-top: 30px;
        }

        p {
            color: $waterloo;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.5px;
            line-height: 24px;
            text-align: center;
            margin-top: 15px;
        }
    }

    &__list {
        ul {
            margin-top: 10px;

            li {
                color: $haiti;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: -0.46px;
                line-height: 22px;
                margin-bottom: 6px;

                span {
                    margin-right: 8px;
                }
            }
        }
    }

    &__button {
        margin-top: 20px;

        .btn--call-now {
            width: 108px;
            height: 40px;
            border-radius: 122px;
            background-color: $secondary;
            color: $white;
            font-size: 14px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.46px;
            line-height: normal;
        }

        .btn--availability {
            width: 108px;
            height: 40px;
            border-radius: 122px;
            border: 1px solid $bunting-aprx;
            color: $haiti;
            font-size: 14px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.46px;
            line-height: normal;

            &:hover{
                color: $haiti !important;
            }
        }

    }
}

.doc-list-explore-btn {
    margin-top: 50px;

    @include tablet {
        margin-top: 70px;
    }

    @include desktops {
        margin-top: 100px;
    }

    .btn {
        width: 228px;
        height: 60px;
        box-shadow: 15px 15px 30px rgba(65, 111, 244, 0.3);
        border-radius: 33px;
        background-color: $primary;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: normal;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-6 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l5-1 {
    padding-top: 45px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 65px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 130px;
    }

    .section__heading {
        h2 {
            color: $white;
        }

        p {
            color: $lynch;
        }
    }
}

.feature-l5-1-items {
    margin-bottom: -40px;
    margin-top: 80px;

    .col-lg-3:last-child:after {
        display: none;
    }

    .col-lg-3 {
        position: relative;
        margin-bottom: 40px;

        &:after {
            position: absolute;
            content: "";
            background: url(/image/landing-5/arrow-long.png) no-repeat;
            top: 50%;
            right: -58%;
            transform: translate(-50%, -50%);
            height: 35px;
            width: 107px;
            display: none;

            @include desktops {
                display: block;
                right: -68%;
            }

            @include large-desktops {
                right: -58%;
            }
        }
    }

    &__card {
        padding: 0px 22px 26px 22px;
        background: $haiti;
        border-radius: 15px;

        .number {
            width: 60px;
            height: 60px;
            line-height: 60px;
            background-color: #1d2e5e;
            display: inline-block;
            border-radius: 50%;
            margin-top: -22px;

            span {

                color: $primary;
                font-size: 24px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: -0.79px;
            }
        }



        .content {
            margin-top: 53px;

            h4 {
                color: $white;
                font-size: 22px;
                font-weight: 500;
                font-style: normal;
                letter-spacing: -0.73px;
                line-height: normal;
            }

            p {
                color: $lynch;
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: -0.53px;
                line-height: 28px;
                margin-top: 25px;
            }
        }

    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-6 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area-l6 {
    position: relative;
    padding-top: 45px;

    @include tablet {
        padding-top: 65px;
    }

    @include desktops {
        padding-top: 0px;
    }

    @include large-desktops {
        padding-top: 75px;
    }

    .section__heading-2 {


        @include large-desktops {
            p {
                padding: 0px 5px;
            }
        }

        @include large-desktops-mid {
            p {
                padding: 0px;
            }
        }
    }

    .feature-widget-items {
        margin-top: 40px;
        margin-bottom: -45px;

        @include tablet {
            margin-top: 50px;
        }

        @include desktops {
            margin-top: 75px;
        }
    }

    .feature-widget {
        height: 100%;
        margin-bottom: 45px;

        .feature-widget-icon {
            i {
                color: $primary;
                font-size: 24px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                line-height: 80px;
                box-shadow: 0 12px 48px rgba(146, 146, 146, 0.16);
                background-color: $white;
            }

            &__2 {
                i {
                    color: $secondary;
                }
            }

            &__3 {
                i {
                    color: $goldendream;
                }
            }

            &__4 {
                i {
                    color: $electricviolet;
                }
            }
        }

        .feature-widget-content {
            h4 {
                color: $haiti;
                font-size: 20px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: -0.66px;
                line-height: normal;
                margin-top: 30px;
            }

            p {

                color: #81838C;
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: -0.5px;
                line-height: 28px;

                @include mobile {
                    margin: 25px 35px 0px 35px;
                }

                @include mobile-lg {
                    margin: 25px 0px 0px 0px;
                }

                @include tablet {
                    margin: 25px 35px 0px 35px;
                }

                @include desktops {
                    margin: 25px 0px 0px 0px;
                }


                @include extra-large-desktops {
                    margin: 25px 26px 0px 26px;
                }
            }
        }
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-7 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l7-1 {
    //padding-bottom: 60px;

    @include tablet {
        //padding-bottom: 80px;
    }

    @include desktops {
        //padding-bottom: 130px;
    }
}

.feature-card-items-l7-1 {
    margin: 0px -15px -30px -15px;
    padding-top: 45px;

    @include tablet {
        padding-top: 60px;
    }

    @include desktops {
        margin: 0px;
        padding-top: 85px;
    }

    .feature-card {
        box-shadow: 10px 10px 40px rgba(111, 118, 138, 0.16);
        //border-radius: 10px;
        background-color: $rts-white;
        padding: 30px 35px 30px 35px;
        flex: 0 0 85%;
        margin: 0px 15px 30px 15px;

        @include mobile-lg {
            flex: 0 0 44%;
        }

        @include tablet {
            flex: 0 0 29%;
        }

        @include desktops {
            margin: 0px;
            flex: 0 0 18%;
        }

        h4 {
            color: $haiti;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.66px;
            line-height: normal;
        }

        img {
            //margin-top: 55px;
        }
    }
    .feature-card2 {
        box-shadow: 10px 10px 40px rgba(111, 118, 138, 0.16);
        //border-radius: 10px;
        background-color: rgba(111, 118, 138, 0.16);
        padding: 30px 35px 30px 35px;
        flex: 0 0 85%;
        margin: 0px 15px 30px 15px;

        @include mobile-lg {
            flex: 0 0 44%;
        }

        @include tablet {
            flex: 0 0 29%;
        }

        @include desktops {
            margin: 0px;
            flex: 0 0 18%;
        }

        h4 {
            color: $haiti;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.66px;
            line-height: normal;
        }

        img {
            //margin-top: 55px;
        }
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-8 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area-l8-1 {
    padding-top: 50px;
    padding-bottom: 50px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 130px;
    }

    &__total-item {
        margin-bottom: -30px;

        @include tablet {
            margin-bottom: -50px;
        }

        @include desktops {
            margin-bottom: -133px;
        }
    }

    &__heading {
        h2 {
            color: $haiti;
            font-size: 40px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -1.58px;
            line-height: 50px;

            margin-bottom: 40px;

            @include mobile-lg {
                font-size: 48px;
                line-height: 60px;
                margin-bottom: 50px;
            }

            @include tablet {
                padding-right: 60px;
                margin-bottom: 0px;
            }

            @include desktops {
                font-size: 42px;
                line-height: 55px;

            }

            @include large-desktops {
                font-size: 48px;
                line-height: 60px;
            }
        }
    }
}

.widget {
    &--feature-l8-1 {
        margin-bottom: 30px;

        @include tablet {
            margin-bottom: 50px;
        }

        @include desktops {
            margin-bottom: 133px;
        }

        &__icon {
            i {
                font-size: 21px;
                color: $white;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 50%;
                text-align: center;
                box-shadow: 7px 7px 14px rgba(65, 111, 244, 0.1);
                background-color: $primary;
                margin-right: 25px;

            }
        }

        &__head {
            h4 {
                color: $bunting_2;
                font-size: 20px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: -0.5px;
                line-height: normal;
            }
        }

        &__content {
            @include desktops {
                padding-right: 35px;
            }

            p {
                color: $waterloo;
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: -0.5px;
                line-height: 28px;
                margin-top: 27px;
            }
        }
    }


}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-8 Feature-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area-l8-2 {
    padding-top: 55px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 60px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 105px;
    }

    &__total-item {
        margin-bottom: -30px;

        @include tablet {
            margin-bottom: -50px;
        }

        @include desktops {
            margin-bottom: -133px;
        }
    }

    &__heading {
        h2 {
            color: $haiti;
            font-size: 40px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -1.58px;
            line-height: 50px;
            margin-bottom: 30px;


            @include tablet {
                font-size: 48px;
                line-height: 60px;
                margin-bottom: 0px;
            }

            @include desktops {
                padding-right: 60px;
                font-size: 40px;
                line-height: 50px;
                margin-top: -10px;
            }

            @include extra-large-desktops {
                font-size: 48px;
                line-height: 60px;
            }

        }
    }
}

.widget {
    &--feature-l8-2 {
        margin-bottom: 30px;

        @include tablet {
            margin-bottom: 50px;
        }

        @include desktops {
            margin-bottom: 133px;
        }

        &__icon {
            i {
                font-size: 15px;
                color: $white;
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 50%;
                text-align: center;
                box-shadow: 7px 7px 14px rgba(65, 111, 244, 0.1);
                background-color: $primary;
                margin-right: 25px;

            }
        }


        &__content {


            @include extra-large-desktops {
                padding-right: 35px;
            }

            h4 {
                color: $haiti;
                font-size: 20px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: -0.66px;
                line-height: normal;
            }

            p {
                color: $waterloo;
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: -0.5px;
                line-height: 28px;
                margin-top: 0px;
            }
        }
    }


}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-9 Feature-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-l9-items {
    margin-bottom: -30px;
    padding-top: 40px;

    @include tablet {
        padding-top: 55px;
    }

    @include desktops {
        padding-top: 77px;
    }

    .col-lg-4 {
        margin-bottom: 30px;
    }
}


.card--feature-l9 {
    background: #262626;
    border: 0;
    border-radius: 0px;
    border-top: 5px solid $rts-red;
    padding: 50px 30px;
    height: 100%;


    &__icon {
        & img {
            border-radius: 20px;
            box-shadow: 0 18px 35px rgba(194, 194, 194, 0.16);
            margin-bottom: 27px;
            height: 84px;
        }
    }

    &__heading {
        h4 {
            color: $haiti;
            font-size: 22px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.73px;
            line-height: normal;
        }
    }

    &__content {
        p {
            color: #182748;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: 28px;
            margin-top: 25px;
        }
    }

    &__btn {
        .btn {
            width: 180px;
            height: 60px;
            border-radius: 10px;
            background-color: $primary;
            color: $white;
            font-size: 16px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: normal;
            margin-top: 25px;
        }
    }
}

.card--feature-l9--2 {
    background: #262626;

    .btn {
        background: $secondary;
    }
}

.card--feature-l9--3 {
    background: #262626;

    .btn {
        background: $portica;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-9 Feature-2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.feature-area-l9-1 {
    padding-top: 55px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 75px;
        padding-bottom: 60px;
    }

    @include desktops {
        padding-top: 127px;
        padding-bottom: 110px;
    }

    &--items {
        margin-bottom: 0px;
    }
}

.widget {
    &--feature-l9-1 {
        margin-bottom: 30px;

        &__icon {
            i {
                font-size: 18px;
                color: $bunting-aprx;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 50%;
                text-align: center;
                box-shadow: 8px 11px 19px rgba(164, 160, 160, 0.27);
                background-color: $white;
                margin-right: 32px;

            }
        }


        &__content {


            @include extra-large-desktops {
                padding-right: 35px;
            }

            h4 {
                color: $haiti;
                font-size: 20px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: -0.66px;
                line-height: normal;
            }

            p {
                color: $waterloo;
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: -0.5px;
                line-height: 28px;
                margin-bottom: 0;
                margin-top: 25px;
            }
        }
    }
}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Feature Area L-9 Feature-3
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.feature-area-l9-2 {


    @include desktops {
        padding-bottom: 130px;
    }

    position: relative;

    &:after {
        position: absolute;
        content: "";
        background: $alabaster;
        height: 76%;
        width: 100%;
        top: 195px;
        left: 0;
        z-index: -1;
        display: none;

        @include desktops {
            display: block;
            top: 200px;
        }

        @include large-desktops {
            top: 185px;
        }

        @include extra-large-desktops {
            top: 195px;
        }

    }

    .section__heading-2 {
        h2 {
            margin-bottom: 30px;

            @include mobile-lg {
                margin-bottom: 40px;
            }
        }
    }

    .feature-items {
        margin-bottom: -30px;
    }
}


.card--feature-l9-2 {
    border-radius: 10px;
    border: 1px solid $mercury;
    background-color: $white;
    padding: 30px;
    transition: all 0.4s ease-in-out;
    margin-bottom: 30px;
    height: 100%;

    &:hover {
        box-shadow: 0 22px 44px rgba(149, 149, 149, 0.16);
    }

    &__content {
        padding-top: 28px;

        span {
            color: $primary;
            font-size: 14px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.46px;
            line-height: normal;
        }

        h4 {
            color: $haiti;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.66px;
            line-height: 30px;
            margin-top: 7px;
        }
    }
}

.widget--lecture {
    margin: 20px -30px 0px -30px;

    &-item {
        margin: 0px 30px;

        i {
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 11px;
            text-align: center;
            box-shadow: 1px 3px 10px rgba(94, 94, 94, 0.16);
            background-color: $white;
            color: $bunting-aprx;
            margin-right: 10px;
            border-radius: 50%;
        }

        span {
            color: $waterloo;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.46px;
            line-height: normal;
        }
    }
}

.explore-lessons-btn {
    .btn {
        width: 222px;
        height: 60px;
        border-radius: 30px;
        background-color: $primary;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: normal;
        margin-top: 20px;

        @include tablet {
            margin-top: 50px;

        }

        @include desktops {
            margin-top: 70px;

        }
    }
}

.l9-slider-arrow-1 {
    margin-bottom: 30px;

    @include mobile-lg {
        margin-bottom: 0px;
    }

    & i {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: $bunting-aprx;
        border: 1px solid $bunting-aprx;
        border-radius: 50%;
        margin: 0px 6px;
        cursor: pointer;
    }
}

.feature-slider-l9-1 {
    margin: 0px -15px;

    .single-item {
        margin: 0px 15px;
    }
}