/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Career video Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.video-icon {
    i {
        width: 82px;
        height: 82px;
        color: $white;
        background-color: $primary;
        border-radius: 50%;
        text-align: center;
        line-height: 82px;
        position: relative;
        z-index: 1;
    }
}

.career-video-area {
    padding: 150px 0px 150px 0px;

    @include tablet {
        padding: 250px 0px 250px 0px;
    }

    @include desktops {
        padding: 340px 0px 340px 0px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Career Feature Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.career-feature {
    padding-top: 45px;
    padding-bottom: 35px;

    @include tablet {
        padding-top: 65px;
        padding-bottom: 55px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 105px;
    }


    .section__heading {
        margin-bottom: 45px;

        @include tablet {
            margin-bottom: 65px;
        }

        @include desktops {
            margin-bottom: 85px;
        }

    }
}

.career-feature-card-items {
    margin-bottom: -30px;

    .col-lg-4 {
        margin-bottom: 30px;
    }
}

.career-feature-card {
    &__icon {
        svg {
            margin-right: 15px;

            @include tablet {
                margin-right: 35px;
            }
        }
    }

    &__content {
        h4 {
            color: $haiti;
            font-size: 22px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.73px;
            line-height: normal;
        }

        p {
            color: $waterloo;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: 28px;
            margin-top: 25px;
        }
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Career Testimonial Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.career-testimonial-area {
    padding-bottom: 60px;

    @include tablet {
        padding-bottom: 80px;
    }

    @include desktops {
        padding-bottom: 130px;
    }

    .career-testimonial-content {
        padding-top: 40px;

        @include tablet {
            padding-top: 50px;
        }

        @include desktops {
            padding-top: 0px;
        }

        @include large-desktops {
            padding-right: 76px;
        }

        .quote {
            margin-bottom: 50px;
        }

        h4 {
            color: $haiti;
            font-size: 34px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -1.12px;
            line-height: 50px;
        }

        h6 {
            color: $haiti;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.66px;
            line-height: normal;
            margin-top: 50px;
        }

        span {
            color: $waterloo;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: -0.53px;
            line-height: normal;
        }
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Career Job opening Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.career-job-opening-area {
    padding-top: 45px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 65px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 130px;
    }

    .section__heading {
        margin-bottom: 40px;

        @include tablet {
            margin-bottom: 60px;
        }

        @include desktops {
            margin-bottom: 80px;
        }
    }
}

.career-job-card-items {
    margin-bottom: -20px;
}

.career-job-card {
    transition: all 0.4s ease-in-out;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 35px 45px 27px 40px;

    &:hover {
        box-shadow: 0 30px 44px rgba(13, 21, 46, 0.09);

        .career-job-card__arrow {
            a {
                color: $primary;
            }
        }

    }

    &__content {
        h6 {
            color: $haiti;
            font-size: 22px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: -0.73px;
            line-height: normal;
        }


    }

    &__arrow {
        a {
            color: $black;
        }

        i {
            font-size: 20px;
            cursor: pointer;
        }
    }
}

.job-details {
    margin-top: 15px;
    margin-right: 20px;

    ul {
        margin: 0px -14px -20px -14px;

        li {
            display: inline-flex;
            margin: 0px 14px 20px 14px;
        }
    }

    span {
        margin-left: 7px;
    }
}

.career-job-opening-btn {
    margin-top: 50px;

    @include tablet {
        margin-top: 60px;
    }

    @include desktops {
        margin-top: 100px;
    }

    .btn {
        min-width: 180px;
        height: 60px;
        border-radius: 6px;
        background-color: $primary;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: normal;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Career Details Banner
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.career-details-banner {
    padding-top: 98px;
    padding-bottom: 45px;
    @include mobile {
        padding-top: 115px;
    }
    @include tablet {
        padding-top: 135px;
        padding-bottom: 60px;
    }

    @include desktops {
        padding-top: 213px;
        padding-bottom: 70px;
    }

    h2 {
        color: $haiti;
        font-size: 36px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -1.98px;
        line-height: 48px;

        @include mobile-lg {
            font-size: 40px;
            line-height: 52px;
        }

        @include tablet {
            font-size: 55px;
            line-height: 67px;
        }

        @include desktops {
            font-size: 60px;
            line-height: 72px;
        }
    }
}

.job-details__2 {
    margin-top: 35px;
}

.career-details-content-1 {
    margin-bottom: 60px;

    @include tablet {
        margin-bottom: 80px;
    }

    @include desktops {
        margin-bottom: 130px;
    }

    p {
        color: $haiti;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: 28px;
        margin-bottom: 25px;
    }

    h4 {
        color: $haiti;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.79px;
        line-height: normal;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.responsibilities-area {
    span {
        font-weight: 700;
        font-size: 20px;
        margin-right: 10px;
    }

    li {
        color: $haiti;
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.59px;
        line-height: 40px;
        display: inline-flex;
    }
}

.carrer-apply-btn {
    .btn {
        min-width: 180px;
        height: 60px;
        border-radius: 6px;
        background-color: $primary;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: normal;
        margin-top: 50px;
    }
}

.apply-contact-form-area {
    padding-top: 45px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 65px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 130px;
    }

    h2 {
        color: #fff;
        margin-bottom: 45px;

        @include tablet {
            margin-bottom: 60px;
        }

        @include desktops {
            margin-bottom: 75px;
        }
    }


}

.apply-contact-form {
    margin-bottom: -13px;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 22px 25px 30px 25px;

    @include mobile {
        padding: 32px 35px 40px 35px;
    }

    .form-control::placeholder {
        color: $waterloo;
        opacity: 1;
    }

    .form-group {
        margin-bottom: 13px;
    }

    .form-control {
        color: $waterloo;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: normal;
        height: 50px;
        border-radius: 5px;
        border: 1px solid $porcelain;
        background-color: $white;
        padding-left: 16px;
    }

    label {
        color: $haiti;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: normal;
        margin-bottom: 15px;
    }

    .nice-select {
        float: inherit;
        color: $haiti;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.53px;
        height: 50px;
        line-height: 50px;

        .list {
            width: 100%;
        }


    }

    textarea {
        height: 180px !important;
        padding-top: 15px;
    }

    .btn {
        width: 100%;
        height: 60px;
        border-radius: 6px;
        background-color: $primary;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: normal;
        margin-top: 25px;
    }
}